import { Box } from "@mui/material";
import { TextCus } from "components/TextCus";
import { useStoreBodyShape } from "hooks";
import React from "react";
import { mainColor } from "theme";
import { EModalBodyShape } from "types";

const CreateNowBody = () => {
	const { setShowModelCreate } = useStoreBodyShape();
	return (
		<Box
			width={"100%"}
			height={"56px"}
			padding={"0 15px"}
			display={"flex"}
			borderRadius={"12px"}
			sx={{ background: mainColor.bgLinear_2 }}
			alignItems={"center"}
			justifyContent={"space-between"}>
			<TextCus color={"#5B3E9B"} fontWeight={"600"} fontSize={"12px"}>
				Create Your Body Shape
			</TextCus>
			<Box
				onClick={() => {
					return;
					setShowModelCreate(EModalBodyShape.CREATE);
				}}
				bgcolor={"white"}
				width={"105px"}
				height={"23px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				borderRadius={"12px"}
				sx={{ cursor: "pointer" }}>
				<TextCus fontSize={"12px"} color={"#3B3899"} fontWeight={"500"}>
					Maintenance
				</TextCus>
			</Box>
		</Box>
	);
};

export default CreateNowBody;
