/** @format */

import * as yup from "yup";

const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const loginSchema = yup.object().shape({
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),

	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	checked: yup.boolean(),
});

const registerSchema = yup.object().shape({
	userName: yup.string().required("Name is required"),
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),
	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), ""], "Passwords must match")
		.required("Confirm Password is required"),
	acceptPrivacy: yup
		.boolean()
		.oneOf([true], "You must accept the privacy policy"),
	subscribeEmail: yup.boolean(),
});

const dynamicSchema = (isOtpStep: boolean) =>
	yup.object().shape({
		email: isOtpStep
			? yup.string().notRequired()
			: yup
					.string()
					.matches(regexEmail, "Invalid email format")
					.required("Email is required"),
		otp: isOtpStep
			? yup
					.string()
					.length(6, "OTP must be exactly 6 digits")
					.required("OTP is required")
			: yup.string().notRequired(),
	});

const resetPassSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(8, "New password must be at least 8 characters")
		.required("New password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), ""], "Passwords must match")
		.required("Confirm Password is required"),
});

const updatePassSchema = yup.object().shape({
	oldPassword: yup.string().required("Old password is required"),
	newPassword: yup
		.string()
		.min(8, "New password must be at least 8 characters")
		.required("New Password is required"),
});

const profileSchema = yup.object().shape({
	userName: yup.string().required("User name must be not empty"),
	avatar: yup.string().notRequired(),
	email: yup.string().notRequired(),
});

const otpSchema = yup.object().shape({
	otp: yup
		.string()
		.required("Otp is required")
		.length(6, "OTP must be exactly 6 characters"),
});

const customPlanSchema = yup.object().shape({
	company: yup.string().required("Company is required"),
	yourRole: yup.string().required("Role is required"),
	firstName: yup.string().required("First name is required"),
	message: yup.string().required("Message is required"),
	country: yup.string().required("Country is required"),
	phone: yup.string().required("Phone is required"),
	options: yup.array().min(1).required("Select your interests"),
	email: yup
		.string()
		.email("Invalid email format")
		.required("Email is required"),
});

export {
	dynamicSchema,
	loginSchema,
	otpSchema,
	profileSchema,
	registerSchema,
	resetPassSchema,
	updatePassSchema,
	customPlanSchema,
};
