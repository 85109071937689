import React, { memo } from "react";
import { Box } from "@mui/material";
import { kc2, kc22 } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import { fontSize } from "theme";

const BoxCredit = () => {
	const { user, mode } = useUser();
	return (
		<Box
			padding={"3px 10px"}
			height={"40px"}
			zIndex={99999}
			borderRadius={"12px"}
			display={"flex"}
			alignItems={"center"}
			gap={"10px"}
			bgcolor={"rgba(255, 230, 102, 0.3)"}>
			<img width={20} src={mode === "light" ? kc2 : kc22} alt="kc" />
			<TextCus
				color={mode === "light" ? "#3A2F00" : "#FFE666"}
				fontSize={fontSize.sm}
				fontWeight={"bold"}>
				{(user?.credits || 0) + ""}
			</TextCus>
		</Box>
	);
};

export default memo(BoxCredit);
