import { useMutation } from "@tanstack/react-query";
import { authService, userService } from "services";
import { EModelAuth, FormLogin, FormLoginSocial, FormRegister } from "types";
import { useKey } from "./useKey";
import { useUser } from "./useUser";
import { useLayout } from "./useLayout";
import SnackbarUtils from "utils/SnackbarUtils";
import { useTimer } from "./useTimer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

export const useAuthentication = () => {
	const { setKeySite, removeKeySite } = useKey();
	const { setUser, setIsLogged } = useUser();
	const { setOpenModalAuth } = useLayout();
	const { startTimer } = useTimer(60);
	const [searchParams] = useSearchParams();
	const referralCode = searchParams.get("_referralCode");
	const refSrc = searchParams.get("refSrc");
	const navigate = useNavigate();

	const activeReferral = useMutation({
		mutationFn: (formData: {
			referralCode: string;
			uid: string;
			refSrc: string;
		}) => userService.activeReferral(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				localStorage.removeItem("referralCode");
				localStorage.removeItem("refSrc");
				navigate(APP_ROOT.DASHBOARD);
				return;
			}
		},
		onError: (error) => {
			console.log("Error", error);
		},
	});

	const loginMutation = useMutation({
		mutationFn: (formData: FormLogin) => authService.login(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKeySite({ authToken: accessToken, refreshToken: refreshToken });
				const rs = await userService.getProfile();
				localStorage.setItem("showIntroBody", "true");

				if (rs?.data) {
					setOpenModalAuth(EModelAuth.DEFAULT);
					setUser(rs.data?.data);
					setIsLogged(true);
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Login failed");
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.warning("Login failed!");
		},
	});

	const loginSocialMutation = useMutation({
		mutationFn: (formData: FormLoginSocial) =>
			authService.loginGoogle(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKeySite({ authToken: accessToken, refreshToken: refreshToken });
				const rs = await userService.getProfile();
				if (rs?.data) {
					setOpenModalAuth(EModelAuth.DEFAULT);
					setUser(rs.data?.data);
					setIsLogged(true);
					localStorage.setItem("showIntroBody", "true");
					localStorage.getItem("referralCode") &&
						activeReferral.mutate({
							uid: rs.data?.data.id + "",
							referralCode: localStorage.getItem("referralCode") + "",
							refSrc: localStorage.getItem("refSrc") + "",
						});
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Login failed");
		},
		onError: () => {
			SnackbarUtils.warning("Login failed!");
		},
	});

	const registerMutation = useMutation({
		mutationFn: (formData: FormRegister) => authService.register(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Enter OTP is sent to your email");
				localStorage.setItem("email", data?.data?.email);
				localStorage.setItem("userId", data?.data?.id);
				referralCode && localStorage.setItem("referralCode", referralCode);
				refSrc && localStorage.setItem("refSrc", refSrc);
				setOpenModalAuth(EModelAuth.VERIFY);
				startTimer();
				return;
			}
			SnackbarUtils.warning(data?.message || "Register failed");
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.warning("Login failed!");
		},
	});

	const resetStore = () => {
		setIsLogged(false);
		removeKeySite();
		const myItem = localStorage.getItem("firstLogin");
		localStorage.clear();
		myItem && localStorage.setItem("firstLogin", "true");
		window.location.reload();
		return;
	};

	const logoutMutation = useMutation({
		mutationFn: () => userService.logout(),
		onSuccess: async ({ data }) => {
			if (data.statusCode === 200) {
				resetStore();
			}
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.success("Logout success!");
			resetStore();
		},
	});

	return {
		loginMutation,
		registerMutation,
		logoutMutation,
		loginSocialMutation,
		activeReferral,
	};
};
