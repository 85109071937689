import { useHotkeys } from "react-hotkeys-hook";

export const useBehaviorHandlers = ({
	undo,
	redo,
	zoomIn,
	zoomOut,
	copy,
	paste,
	deleteCur,
}: {
	undo: () => void;
	redo: () => void;
	copy?: () => void;
	paste?: () => void;
	zoomIn?: () => void;
	zoomOut?: () => void;
	deleteCur?: () => void;
}) => {
	useHotkeys("ctrl+z", undo);
	useHotkeys("ctrl+shift+z", redo);
	useHotkeys("ctrl+y", redo);
	zoomIn && useHotkeys("ctrl++", zoomIn);
	zoomOut && useHotkeys("ctrl+-", zoomOut);
	copy && useHotkeys("ctrl+c", copy);
	paste && useHotkeys("ctrl+v", paste);
	deleteCur && useHotkeys("delete", deleteCur);
	deleteCur && useHotkeys("backspace", deleteCur);
};
