import dayjs from "dayjs";
import { TLine } from "types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	selectedFileModel: File | string | null;
	selectedFileClothes: File | string | null;
	selectedGallery: File | string | null;
	selectedFileGallery: string | null;
	valueSize: string | null;
	valueCategory: string | null;
	denoiseSteps: number;
	name: string;
	fileBinary: File | string | null;

	currentStep: number;
	modelImage: string | null;
	imageClothes: string | null;
	isLoadingProcess: boolean;
	merchantId: null | string;
	typeModel: string | null;
	linesRetouch: (TLine | undefined)[];
};

type Actions = {
	setSelectedFileModel: (fileModel: File | string | null) => void;
	setModelImage: (image: string | null) => void;
	setSelectedFileClothes: (fileModel: File | string | null) => void;
	setImageClothes: (image: string | null) => void;
	setSelectedGallery: (img: File | string | null) => void;
	setSelectedFileGallery: (fileModel: string | null) => void;
	reset: () => void;
	setCurrentStep: (v: number) => void;
	setValueCategory: (v: string) => void;
	setValueSize: (v: string) => void;
	setDenoiseSteps: (v: number) => void;
	setIsLoadingProcess: (v: boolean) => void;
	setName: (v: string) => void;
	setMerchantId: (v: string) => void;
	setTypeModel: (v: string) => void;
	setFileBinary: (img: File | string | null) => void;
	setLinesRetouch: (v: (TLine | undefined)[]) => void;
	resetLinesRetouch: () => void;
};

const initialState: State = {
	selectedFileModel: null,
	selectedFileClothes: null,
	modelImage: null,
	imageClothes: null,
	selectedGallery: null,
	selectedFileGallery: null,
	currentStep: 0,
	// valueCategory: "UPPER",
	// valueSize: "full-length",
	valueCategory: null,
	valueSize: null,
	denoiseSteps: 30,
	isLoadingProcess: false,
	name: `StyleFit-${dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")}`,
	merchantId: null,
	typeModel: null,
	fileBinary: null,
	linesRetouch: [],
};

export const useOutfitStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setSelectedFileModel: (file) => set({ selectedFileModel: file }),
			setSelectedFileClothes: (file) => set({ selectedFileClothes: file }),
			setModelImage: (img) => set({ modelImage: img }),
			setImageClothes: (img) => set({ imageClothes: img }),
			setSelectedGallery: (img) => set({ selectedGallery: img }),
			setSelectedFileGallery: (file) => set({ selectedFileGallery: file }),
			setCurrentStep: (v) => set({ currentStep: v }),
			setValueCategory: (v) => set({ valueCategory: v }),
			setValueSize: (v) => set({ valueSize: v }),
			setDenoiseSteps: (v) => set({ denoiseSteps: v }),
			setIsLoadingProcess: (v) => set({ isLoadingProcess: v }),
			setName: (v) => set({ name: v }),
			setMerchantId: (v) => set({ merchantId: v }),
			setTypeModel: (v) => set({ typeModel: v }),
			setFileBinary: (v) => set({ fileBinary: v }),
			setLinesRetouch: (v) => set({ linesRetouch: v }),
			resetLinesRetouch: () => set({ fileBinary: null, linesRetouch: [] }),
			reset: () => {
				set(initialState);
			},
		}),
		{
			name: "OutfitStore",
		},
	),
);
