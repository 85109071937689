import { TParams, TResponse } from "./app";

export interface IModelBodyProps {
	setSelectedFile?: (file: File | null) => void;
	dataGallery?: TResponse<IGallery[]>;
	setParamsGallery: React.Dispatch<React.SetStateAction<TParams>>;
	paramsGallery: TParams;
	isLoadingGallery: boolean;
}

export interface IClothesProps {
	setSelectedFile?: (file: File | null) => void;
	valueCategory?: string;
	setValueCategory?: (value: string) => void;
	valueSize?: string;
	setValueSize?: (value: string) => void;
}

export interface IOutfitFinalsProps {
	result?: IFinalOutfitResult;
	isLoading?: boolean;
}

export interface IFinalOutfitResult {
	url?: string | null;
	status?: EnumStatusOutfit;
	totalStep?: number;
	currentStep?: number;
	message?: string | null;
	galleryId?: string | null;
	imgDownload?: string | null;
}

export interface IDataTracking {
	createdAt: string;
	id: string;
	image: string;
	status: string;
	updatedAt: string;
	video: string;
}

export interface IOutfitParams {
	fileClothes?: string | null;
	fileModel?: string | null;
	fileBinary?: string | null;
	modelBodyId?: string | null;
	galleryId?: string | null;
	clothesId?: string | null;
	name?: string;
}

export interface IFaceSwapParams {
	fileModel?: string | null;
	fileFace?: string | null;
	modelBodyId?: string | null;
	galleryId?: string | null;
}

export interface IModelType {
	createdAt?: string;
	id?: string;
	image?: string;
	isDeleted?: boolean;
	name?: string;
	typeModel?: string;
	updatedAt?: string;
	_id?: string;
	bmi?: number;
	height?: number;
	weight?: number;
	merchantId?: string;
	describe?: string;
}

export enum EnumStatusOutfit {
	STARTED = "STARTED",
	PROCESSING = "PROCESSING",
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export interface IGallery {
	description?: string; //"";
	id?: string; //"66ab56ec0ca15f8221b20203";
	image?: string; //"http://localhost:8000/swap/outfit/result.png";
	isDeleted?: boolean; //false;
	maskBody?: string; //"";
	maskLength?: string; //"";
	serviceName?: string; //"OUTFIT";
	createdAt?: string;
	name: string;
	weight?: number;
	height?: number;
	video?: string;
	imgDownload?: string;
}

export enum EDropType {
	BODY = "BODY",
	OUTFIT = "OUTFIT",
}
export interface IDetailGallery {
	clothesImage: string;
	createdAt: string;
	description: string;
	id: string;
	image: string;
	maskBody: string;
	maskLength: string;
	modelImage: string;
	type: string;
	uid: string;
	updatedAt: string;
	name: string;
	hairStyleImage?: string;
	hairColorImage?: string;
	genMeImage?: string;
	video?: string;
	videoAnimation?: string;
	imgDownload?: string;
}

export interface IModelParams {
	image?: string | null;
	name?: string | null;
	typeModel?: string | null;
}

export interface IDataRename {
	galleryId: string;
	name: string;
}

export interface IBodyShapeParams {
	name: string;
	typeModel: string;
	weight: number;
	height: number;
}

export interface IDataBodyShape {
	bmi: number;
	bodyShapeId: string;
	createdAt: string;
	height: number;
	id: string;
	image: string;
	name: string;
	typeModel: string;
	uid: string;
	weight: number;
	_id: string;
}

export enum TypeYourBody {
	YOUR_BODY = "YOUR_BODY",
	BODY_SHAPE = "BODY_SHAPE",
}
