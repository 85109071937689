import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useStyleHair, useStyleHairStore, useUser } from "hooks";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import { ButtonSeeMore, TextCus } from "components";
import { fontSize } from "theme";
import { IDataColorHair } from "types/styleHair";
import { ThemeOption } from "types";

export const PresetHair: React.FC = () => {
	const [describe, setDescribe] = useState(null);
	// ======================= HOOKS ==============================
	const {
		setSelectedFileHair,
		setImageHair,
		selectedFileHair,
		setCurrentStep,
	} = useStyleHairStore();

	const { useGetHairColor } = useStyleHair();
	const {
		categories: categories,
		isLoading,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useGetHairColor({
		page: 1,
		limit: 6,
		type: "HAIR_STYLE",
	});

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"3px"}
				justifyContent={"flex-start"}>
				<TextCus
					fontSize={fontSize.xs}
					fontWeight={"500"}
					color={theme.colorSchemes[mode]?.textTitle}>
					Hairstyle
				</TextCus>
				<TextCus
					fontSize={fontSize.xs}
					fontWeight={"400"}
					color={theme.colorSchemes[mode]?.textGray}>
					(Obligatory)
				</TextCus>
				<TextCus fontSize={fontSize.xs} fontWeight={"400"} color={"red"}>
					*
				</TextCus>
			</Box>
			<Box
				border={"1px solid #A3A3A3"}
				width={"100%"}
				height={"40px"}
				borderRadius={"12px"}
				padding={"0 15px"}
				color={theme.colorSchemes[mode]?.textMain}
				alignItems={"center"}
				display={"flex"}
				sx={{ outline: "none" }}>
				{describe ?? (
					<TextCus color={theme.colorSchemes[mode]?.textGray}>
						Describe your hairstyle here (Optional)
					</TextCus>
				)}
			</Box>
			<ListImage
				isLoading={isLoading}
				images={categories}
				selectedImage={selectedFileHair?.id as string}
				setSelectedImage={(it) => {
					setSelectedFileHair(it as IDataColorHair);
					setImageHair(it?.image as string);
					setDescribe(it?.describe);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
