import { Box } from "@mui/material";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import React from "react";
import { fontSize } from "theme/default";
import { EPricingPlan } from "types/payment";
import { DATA_BUTTON } from "utils";

interface IButtonToggleCus {
	typePlan: {
		value: number;
		name: EPricingPlan;
	};
	setTypePlan: React.Dispatch<
		React.SetStateAction<{
			value: number;
			name: EPricingPlan;
		}>
	>;
}

const ButtonToggleCus = ({ typePlan, setTypePlan }: IButtonToggleCus) => {
	const { mode } = useUser();
	return (
		<Box display={"flex"} alignItems={"center"} gap={"20px"}>
			<TextCus
				fontWeight={"600"}
				fontSize={fontSize.xs}
				color={mode === "dark" ? "#FFFFFF" : "#170F49"}>
				{DATA_BUTTON[0].name}
			</TextCus>
			<Box
				position={"relative"}
				width={"60px"}
				height={"33px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				sx={{ transition: "all 0.25s ease-in-out", cursor: "pointer" }}
				borderRadius={"20px"}
				onClick={() =>
					setTypePlan((pre) => {
						if (pre.value === DATA_BUTTON[0].value) {
							return DATA_BUTTON[1];
						}
						return DATA_BUTTON[0];
					})
				}
				bgcolor={"#9577D9"}>
				<Box
					width={"25px"}
					height={"25px"}
					textAlign={"center"}
					lineHeight={"37px"}
					position={"absolute"}
					borderRadius={"100px"}
					fontWeight={"bold"}
					bgcolor={"white"}
					top={"50%"}
					left={typePlan.name === EPricingPlan.MONTHLY ? "5px" : "50%"}
					sx={{
						transform: "translateY(-50%)",
						transition: "all 0.25s ease-in-out",
					}}
				/>
			</Box>
			<TextCus
				fontWeight={"600"}
				fontSize={fontSize.xs}
				color={mode === "dark" ? "#FFFFFF" : "#170F49"}>
				{DATA_BUTTON[1].name}
			</TextCus>
		</Box>
	);
};

export default ButtonToggleCus;
