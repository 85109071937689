import { Box, Checkbox, Divider, Grid, IconButton } from "@mui/material";
import { height, Icons, styleGenLoading, weight } from "assets";
import { ButtonCus, SliderRangeCus, TextCus } from "components";
import { useOutfitStore, useUser } from "hooks";
import useClickOutside from "hooks/useClickOutside";
import React, { useMemo, useRef, useState } from "react";
import { fontSize, mainColor } from "theme";
import InputSize from "./InputSize";
import { useTranslation } from "react-i18next";
import { renderStatusBodyShape } from "utils/helpers/data_helpers";
import { FormDataRecommend, IResRecommend } from "types/merchant";
import { useMutation } from "@tanstack/react-query";
import { merchantService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";
import Collections from "./Collections";
import BoxSlider from "./BoxSlider";
import BoxSize from "./BoxSize";
import BoxBMI from "./BoxBMI";

interface IRecommendSize {
	currentStep: number;
	setCurrentStep: (v: number) => void;
	clothesId: string;
	gallaryId: string;
	typeModel: string;
}

const RecommendSize = ({
	currentStep,
	setCurrentStep,
	clothesId,
	gallaryId,
	typeModel,
}: IRecommendSize) => {
	const { t } = useTranslation();
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const [isOpen, setIsOpen] = useState(false);
	const [optionHeight, setOptionHeight] = useState<"cm" | "feet">("cm");
	const { languages } = useUser();
	const [valueHeight, setValueHeight] = useState(145);
	const [valueWeight, setValueWeight] = useState(45);
	const { imageClothes } = useOutfitStore();
	const [checked, setChecked] = useState(true);
	const handleChange = (event) => {
		setChecked(event.target.checked);
	};
	const [valueChestSize, setValueChestSize] = useState(50);
	const [valueWaistSize, setValueWaistSize] = useState(50);
	const [valueHipSize, setValueHipSize] = useState(50);
	const [showResult, setShowResult] = useState<IResRecommend | null>(null);

	const recommendSizeMutation = useMutation({
		mutationFn: (formData: FormDataRecommend) =>
			merchantService.recommendSize(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				setShowResult(data.data);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Create failed!");
		},
	});

	const arrBMI = useMemo(
		() => [
			{
				title: "Under weight",
				desc: "Less than 18.5",
				bgcolor:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Under weight"
						? "#5ADBFC"
						: "rgba(90, 219, 252, 0.15)",
				bgActive: "rgba(90, 219, 252, 0.15)",
				isActive:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Under weight",
			},
			{
				title: "Normal weight",
				desc: "18.5 - 25",
				bgcolor:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Normal weight"
						? "#57EF66"
						: "rgba(87, 239, 102, 0.15)",
				bgActive: "rgba(87, 239, 102, 0.15)",
				isActive:
					renderStatusBodyShape(showResult?.bmi || 0)?.title ===
					"Normal weight",
			},
			{
				title: "Over weight",
				desc: "25 - 30",
				bgcolor:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Over weight"
						? "#EFBC06"
						: "rgba(239, 188, 6, 0.15)",
				bgActive: "rgba(239, 188, 6, 0.15)",
				isActive:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Over weight",
			},
			{
				title: "Obesity",
				desc: "Greater than 30",
				bgcolor:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Obesity"
						? "#CA382B"
						: "rgba(202, 56, 43, 0.15)",
				bgActive: "rgba(202, 56, 43, 0.15)",
				isActive:
					renderStatusBodyShape(showResult?.bmi || 0)?.title === "Obesity",
			},
		],
		[showResult?.bmi],
	);

	const handleFind = () => {
		recommendSizeMutation.mutate({
			height: valueHeight,
			weight: valueWeight,
			chestSize: valueChestSize,
			waistSize: valueWaistSize,
			hipSize: valueHipSize,
			clothesId,
			gallaryId,
			typeModel,
		});
	};

	const arrInput = useMemo(
		() => [
			{
				title: "Chest size",
				value: valueChestSize,
				action: (e) => setValueChestSize(+e.target.value),
				placeholder: "Enter your chest size",
			},
			{
				title: "Waist size",
				value: valueWaistSize,
				action: (e) => setValueWaistSize(+e.target.value),
				placeholder: "Enter your waist size",
			},
			{
				title: "Hip size",
				value: valueHipSize,
				action: (e) => setValueHipSize(+e.target.value),
				placeholder: "Enter your hip size",
			},
		],
		[valueChestSize, valueWaistSize, valueHipSize],
	);

	return (
		<Box
			zIndex={9999}
			sx={{
				opacity: currentStep === 4 ? 1 : 0,
				pointerEvents: currentStep === 4 ? "all" : "none",
			}}
			position={"absolute"}
			width={"100%"}
			height={"100%"}
			top={0}
			left={0}
			bgcolor={"rgba(0, 0, 0, 0.7)"}>
			<Grid container height={"calc(100vh - 68px)"} overflow={"hidden"}>
				{/* ============== SIDEBAR ================ */}
				<Grid
					item
					xs={4}
					md={3}
					xl={2}
					height={"100%"}
					sx={{
						margin: "0 auto",
						overflowY: "auto",
						overflowX: "hidden",
					}}
					padding={"12px 0 12px 20px"}
					bgcolor={"transparent"}>
					{!showResult ? (
						<Box
							display={"flex"}
							position={"relative"}
							overflow={"hidden"}
							justifyContent={"space-between"}
							flexDirection={"column"}
							bgcolor={mainColor.white}
							width={"100%"}
							borderRadius={"12px"}
							height={"100%"}>
							<Box padding={"15px"}>
								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}>
									<TextCus
										fontWeight={"600"}
										fontSize={"14px"}
										color={"#131313"}>
										FIND YOUR BEST FIT SIZE
									</TextCus>
									<IconButton onClick={() => setCurrentStep(3)}>
										<Icons.CloseDialog />
									</IconButton>
								</Box>
								<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
									Please enter your detail body measurements
								</TextCus>
							</Box>

							<Box sx={{ height: "calc(100vh - 255px)" }}>
								<Box
									display={"flex"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<Box
										height={"248px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										pb={"20px"}>
										<img
											src={imageClothes + ""}
											style={{
												display: "block",
												height: "100%",
												borderRadius: "12px",
											}}
											alt="img"
										/>
									</Box>
									<Box
										display={"flex"}
										flexDirection={"column"}
										alignItems={"center"}
										justifyContent={"center"}
										width={"100%"}
										gap={"10px"}
										bgcolor={"#EEEEEE"}
										padding={"25px 15px"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											width={"100%"}
											gap={"20px"}
											borderRadius={"12px"}
											justifyContent={"space-between"}>
											<Box
												display={"flex"}
												gap={"10px"}
												alignItems={"center"}
												justifyContent={"center"}>
												<img src={height} alt="Height" />{" "}
												<TextCus
													width={languages.value === "vi" ? "75px" : "40px"}
													fontWeight={"600"}
													fontSize={fontSize.xs}
													color={mainColor.mainBlack}>
													Height
												</TextCus>
											</Box>

											<Box
												maxWidth={"154px"}
												width={"calc(100% / 2)"}
												height={"33px"}
												display={"flex"}>
												<Box
													width={"77px"}
													borderRadius={"12px 0 0 12px"}
													component={"input"}
													padding={"5px"}
													textAlign={"center"}
													max={195}
													min={0}
													fontWeight={"600"}
													type="number"
													value={valueHeight + ""}
													onChange={(e) =>
														+e.target.value <= 195 &&
														setValueHeight(+e.target.value)
													}
													border={"1px solid #B2B2B2"}
												/>
												<Box
													width={"77px"}
													ref={dropdownRef}
													display={"flex"}
													alignItems={"center"}
													gap={"5px"}
													sx={{ cursor: "pointer" }}
													justifyContent={"center"}
													border={"1px solid #B2B2B2"}
													borderLeft={"none"}
													borderRadius={`0 12px ${isOpen ? "0px" : "12px"} 0`}
													bgcolor={"white"}
													color={mainColor.textMain}
													fontSize={fontSize.xs}
													onClick={() => setIsOpen(!isOpen)}
													position={"relative"}
													fontWeight={"400"}>
													{optionHeight}
													<Icons.ArrowDown />
													{isOpen && (
														<Box
															position={"absolute"}
															bgcolor={"white"}
															bottom={"-30px"}
															right={"-1px"}
															component={"div"}
															onClick={() =>
																setOptionHeight((pre) =>
																	pre === "cm" ? "feet" : "cm",
																)
															}
															width={"78px"}
															height={"30px"}
															display={"flex"}
															alignItems={"center"}
															border={"1px solid #B2B2B2"}
															justifyContent={"center"}
															borderRadius={"0 0 12px 0"}
															color={mainColor.textMain}
															fontSize={fontSize.xs}
															fontWeight={"400"}>
															{optionHeight === "cm" ? "feet" : "cm"}
														</Box>
													)}
												</Box>
											</Box>
										</Box>
										<SliderRangeCus
											setVSlider={(v) => setValueHeight(v)}
											valueDefault={valueHeight}
											maxValue={optionHeight === "feet" ? 6.4 : 195}
											minValue={optionHeight === "feet" ? 4.75 : 145}
										/>
										<Box
											mt={"10px"}
											display={"flex"}
											alignItems={"center"}
											width={"100%"}
											gap={"20px"}
											borderRadius={"12px"}
											justifyContent={"space-between"}>
											<Box
												display={"flex"}
												gap={"10px"}
												alignItems={"center"}
												justifyContent={"center"}>
												<img src={weight} alt="Height" />{" "}
												<TextCus
													width={languages.value === "vi" ? "75px" : "40px"}
													fontWeight={"600"}
													fontSize={fontSize.xs}
													color={mainColor.mainBlack}>
													Weight
												</TextCus>
											</Box>

											<Box
												maxWidth={"154px"}
												width={"calc(100% / 2)"}
												height={"33px"}
												display={"flex"}>
												<Box
													width={"77px"}
													borderRadius={"12px 0 0 12px"}
													component={"input"}
													padding={"5px"}
													textAlign={"center"}
													fontWeight={"600"}
													max={125}
													min={0}
													value={valueWeight + ""}
													onChange={(e) =>
														+e.target.value <= 125 &&
														setValueWeight(+e.target.value)
													}
													type="number"
													border={"1px solid #B2B2B2"}
												/>
												<Box
													width="77px"
													display={"flex"}
													alignItems={"center"}
													justifyContent={"center"}
													borderRadius={"0 12px 12px 0"}
													bgcolor={"white"}
													color={"#A3A3A3"}
													fontSize={fontSize.xs}
													fontWeight={"400"}>
													kg
												</Box>
											</Box>
										</Box>
										<SliderRangeCus
											setVSlider={(v) => setValueWeight(v)}
											valueDefault={valueWeight}
											maxValue={125}
											minValue={45}
										/>
									</Box>
									<Box
										padding={"25px 15px"}
										display={"flex"}
										flexDirection={"column"}
										gap={"25px"}>
										{arrInput.map((item) => (
											<Box key={item.title}>
												<TextCus
													mb={"10px"}
													fontSize={fontSize.xs}
													color={"#131313"}
													fontWeight={"400"}>
													{item.title}
												</TextCus>
												<InputSize
													value={item.value + ""}
													onChange={item.action}
													placeholder={item?.placeholder}
												/>
											</Box>
										))}

										<Box
											display={"flex"}
											gap={"10px"}
											alignItems={"center"}
											justifyContent={"flex-start"}
											width={"100%"}
											borderRadius={"12px"}>
											<Box display={"flex"} alignItems={"start"} ml={"-10px"}>
												<Checkbox
													sx={{
														color: "#5B3E9B",
														"&.Mui-checked": {
															color: "#5B3E9B",
														},
													}}
													checked={checked}
													onChange={handleChange}
													inputProps={{ "aria-label": "controlled" }}
												/>
												<Box component={"p"}>
													By clicking “Find Now”, you agree to let us use your
													data to recommend the best size. See our{" "}
													<a
														style={{ color: "#5B3E9B" }}
														target="blank"
														href="https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf">
														Terms & Conditions
													</a>{" "}
													and{" "}
													<a
														style={{ color: "#5B3E9B" }}
														href="https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN.pdf"
														target="blank">
														Privacy Policy
													</a>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box
								display={"flex"}
								alignItems={"center"}
								padding={"20px 15px"}
								height={"70px"}
								justifyContent={"center"}
								gap={"15px"}>
								<ButtonCus
									sx={{ width: "190px", padding: "0px !important", flex: 1 }}>
									<Box
										bgcolor={"white"}
										width={"97%"}
										borderRadius={"10px"}
										height={"90%"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											gap={"15px"}
											className="text_linear"
											fontSize={fontSize.xs}
											fontWeight={"600"}>
											{t("Reset")}
										</Box>
									</Box>
								</ButtonCus>
								<ButtonCus sx={{ width: "190px" }} onClick={handleFind}>
									<TextCus
										fontWeight={"600"}
										color={mainColor.white}
										fontSize={fontSize.xs}>
										Find Now
									</TextCus>
								</ButtonCus>
							</Box>
							{recommendSizeMutation.isPending && (
								<Box
									position={"absolute"}
									top={0}
									width={"100%"}
									height={"100%"}
									left={0}
									bgcolor={"rgba(255, 255, 255, 0.5)"}></Box>
							)}
						</Box>
					) : (
						<Box
							display={"flex"}
							position={"relative"}
							overflow={"hidden"}
							flexDirection={"column"}
							bgcolor={mainColor.white}
							width={"100%"}
							height={"100%"}
							borderRadius={"12px"}
							padding={"15px"}>
							<TextCus
								color={"#3E227C"}
								fontSize={fontSize.sm}
								textAlign={"center"}
								mb={"20px"}
								fontWeight={"700"}>
								YOUR BODY MEASUREMENT
							</TextCus>
							<Box sx={{ height: "calc(100vh - 160px)", overflowY: "auto" }}>
								<Box
									display={"flex"}
									flexDirection={"column"}
									gap={"15px"}
									sx={{ overflowY: "auto" }}>
									<Box
										width={"100%"}
										bgcolor={"#EEEEEE"}
										borderRadius={"4px"}
										padding={"0 10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										height={"30px"}>
										<TextCus color={"#131313"} fontSize={fontSize.xs}>
											Height
										</TextCus>
										<TextCus
											color={"#131313"}
											fontSize={fontSize.xs}
											fontWeight={"700"}>
											{showResult?.height + " cm"}
										</TextCus>
									</Box>
									<Box
										width={"100%"}
										bgcolor={"#EEEEEE"}
										borderRadius={"4px"}
										padding={"0 10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										height={"30px"}>
										<TextCus color={"#131313"} fontSize={fontSize.xs}>
											Weight
										</TextCus>
										<TextCus
											color={"#131313"}
											fontSize={fontSize.xs}
											fontWeight={"700"}>
											{showResult?.weight + " cm"}
										</TextCus>
									</Box>
									<Box
										width={"100%"}
										bgcolor={"#EEEEEE"}
										borderRadius={"4px"}
										padding={"0 10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										height={"30px"}>
										<TextCus color={"#131313"} fontSize={fontSize.xs}>
											Chest size
										</TextCus>
										<TextCus
											color={"#131313"}
											fontSize={fontSize.xs}
											fontWeight={"700"}>
											{showResult?.chestSize + " cm"}
										</TextCus>
									</Box>
									<Box
										width={"100%"}
										bgcolor={"#EEEEEE"}
										borderRadius={"4px"}
										padding={"0 10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										height={"30px"}>
										<TextCus color={"#131313"} fontSize={fontSize.xs}>
											Waist size
										</TextCus>
										<TextCus
											color={"#131313"}
											fontSize={fontSize.xs}
											fontWeight={"700"}>
											{showResult?.waistSize + " cm"}
										</TextCus>
									</Box>
									<Box
										width={"100%"}
										bgcolor={"#EEEEEE"}
										borderRadius={"4px"}
										padding={"0 10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										height={"30px"}>
										<TextCus color={"#131313"} fontSize={fontSize.xs}>
											Hip size
										</TextCus>
										<TextCus
											color={"#131313"}
											fontSize={fontSize.xs}
											fontWeight={"700"}>
											{showResult?.hipSize + " cm"}
										</TextCus>
									</Box>
									<TextCus
										color={"#070113"}
										fontSize={fontSize.xs}
										textAlign={"center"}
										mb={"15px"}
										mt={"40px"}
										fontWeight={"600"}>
										Your BMI
									</TextCus>
									<Box
										height={"400px"}
										display={"flex"}
										alignContent={"center"}
										justifyContent={"flex-start"}
										gap={"20px"}
										flexDirection={"column"}>
										{arrBMI.map((item) => {
											return (
												<BoxBMI
													key={item.title}
													bgActive={item.bgActive}
													bgcolor={item.bgcolor}
													desc={item.desc}
													title={item.title}
												/>
											);
										})}
									</Box>
								</Box>
							</Box>
						</Box>
					)}
				</Grid>
				{/* ============== END SIDEBAR ================ */}

				{/* ============== MAIN CONTENT ================ */}
				<Grid
					item
					xs={currentStep === 3 ? 10 : 8}
					md={9}
					xl={10}
					height={"100%"}
					position={"relative"}
					padding={"12px 20px 12px 8px"}
					sx={{
						margin: "0 auto",
						overflowY: "auto",
						overflowX: "hidden",
					}}>
					{recommendSizeMutation.isPending && (
						<Box
							bgcolor={"transparent"}
							width={"100%"}
							borderRadius={"12px"}
							height={"100%"}>
							<Box
								width={"100%"}
								height={"100%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<img
									src={styleGenLoading}
									width={"60px"}
									height={"60px"}
									alt="loading"
								/>
							</Box>
						</Box>
					)}
					{showResult && (
						<IconButton
							sx={{ position: "absolute", top: "20px", right: "30px" }}
							onClick={() => setCurrentStep(2)}>
							<Icons.Close />
						</IconButton>
					)}
					{showResult && (
						<Box
							bgcolor={"white"}
							width={"100%"}
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							borderRadius={"12px"}
							padding={"24px 15px"}
							height={"100%"}
							sx={{
								margin: "0 auto",
								overflowY: "auto",
								overflowX: "hidden",
							}}>
							<Box
								display={"flex"}
								alignItems={"flex-start"}
								gap={"60px"}
								width={"100%"}
								justifyContent={"center"}>
								<BoxSlider showResult={showResult} />

								<Box
									display={"flex"}
									width={"50%"}
									justifyContent={"flex-start"}
									flexDirection={"column"}>
									<Box display={"flex"} flexDirection={"column"}>
										<TextCus
											fontWeight={"600"}
											fontSize={"14px"}
											color={"#131313"}>
											OVERSIZED FAUX LEATHER BIKER JACKET
										</TextCus>
										<TextCus fontSize={"12px"} color={"#A3A3A3"}>
											Black | 4341/773
										</TextCus>
									</Box>
									<Box
										display={"flex"}
										alignItems={"center"}
										gap={"15px"}
										mt={"50px"}
										justifyContent={"flex-start"}>
										{showResult?.listSize.map((item) => (
											<BoxSize key={item} item={item} size={showResult.size} />
										))}
									</Box>

									<ButtonCus
										sx={{
											flex: 1,
											mt: "40px",
											width: "100%",
											maxWidth: "300px",
											padding: "1px !important",
										}}
										onClick={() => window.open(showResult?.link, "_blank")}>
										<Box
											width={"100%"}
											borderRadius={"10px"}
											display={"flex"}
											height={"44px"}
											alignItems={"center"}
											padding={"0 10px"}
											justifyContent={"space-between"}>
											<Box />
											<TextCus
												color={"white"}
												fontSize={fontSize.xs}
												fontWeight={"600"}>
												Buy Now
											</TextCus>
											<Icons.NextDialog />
										</Box>
									</ButtonCus>
								</Box>
							</Box>
							<Box width={"100%"} mt={"50px"}>
								<Divider sx={{ mb: "40px" }} />
								<Collections />
							</Box>
						</Box>
					)}
				</Grid>
				{/* ============== END MAIN CONTENT ================ */}
			</Grid>
		</Box>
	);
};

export default RecommendSize;
