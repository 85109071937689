/** @format */
import React, { memo, useCallback, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { TabCus, TextCus } from "components";
import { fontSize } from "theme";
import BoxUpload from "./BoxUpload";
import { PresetModel } from "./PresetModel";
import { arrTab } from "utils";
import { Gallery } from "./Gallery";
import { useAnimateStore, useUser } from "hooks";
import { YourModel } from "./YourModel";
import CreateNowBody from "components/Common/CreateNowBody";
import ListVideo from "./ListVideo";
import { ThemeOption } from "types";

const Sidebar = () => {
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		currentStep,
	} = useAnimateStore();
	const [itemTab, setItemTab] = useState("UPLOAD");
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const renderSidebar = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus
								indexActive={itemTab === "UPLOAD" ? 0 : 1}
								arrTab={arrTab}
								setValueTab={(v) => setItemTab(v)}
							/>
						</Box>
						<Box padding={"10px 15px"}>
							<CreateNowBody />
						</Box>
						<Box sx={{ height: "calc(100vh - 305px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box padding={"15px"} height={"100%"}>
									<BoxUpload
										onFileSelect={(file: File) => {
											setSelectedFileGallery(null);
											setSelectedFileModel(file);
											setModelImage(URL.createObjectURL(file));
										}}
										onClear={() => setModelImage("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<YourModel />
									<PresetModel />
									<Gallery />
								</Box>
							)}
						</Box>
					</Box>
				);
			default:
				return (
					<Box flex={1}>
						<Box
							sx={{ height: "calc(100vh - 185px)", overflowY: "auto" }}
							padding={"15px"}>
							<ListVideo />
						</Box>
					</Box>
				);
		}
	}, [currentStep, itemTab]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				gap={"2px"}
				justifyContent={"center"}
				height={"98px"}>
				<TextCus
					fontSize={fontSize.sm}
					color={theme.colorSchemes[mode]?.textTitle}
					fontWeight={"700"}>
					{currentStep === 0 ? "YOUR STATION" : "CHOSEN POSE"}
				</TextCus>
				<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
					{currentStep === 0
						? `Upload your photos or choose one model in “Use Model” tab`
						: `Choose one pose`}
				</TextCus>
			</Box>
			{renderSidebar()}
		</Box>
	);
};

export default memo(Sidebar);
