/** @format */

import React, { useEffect } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { Header, Sidebar } from "pages/admin/dashboard/components";
import {
	AuthCus,
	ForgotPass,
	Login,
	Register,
	Verify,
} from "components/AuthCus";
import { useLayout, useStoreBodyShape, useUser } from "hooks";
import { EModelAuth, ThemeOption } from "types";
import ResetPass from "components/AuthCus/ResetPass";
import { ModalIntroBodyShape } from "components/ModalGlobal";
import { useLocation } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
	const { openModalAuth, setOpenModalAuth, isConfirmTerm } = useLayout();
	const { showModalSuggest, setShowModalSuggest } = useStoreBodyShape();
	const { isLogged, mode } = useUser();
	const location = useLocation();
	const theme = useTheme() as ThemeOption;
	useEffect(() => {
		if (location.pathname === APP_ROOT.REGISTER && !isLogged) {
			setOpenModalAuth(EModelAuth.REGISTER);
		}
	}, [location.pathname]);

	return (
		<Box minHeight={"100vh"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}>
				<Box
					width={"100%"}
					height={"100%"}
					minHeight="calc(100vh - 76px)"
					component={"main"}>
					<Grid container height={"calc(100vh - 68px)"} overflow={"hidden"}>
						{/* ============== SIDEBAR ================ */}
						<Grid
							item
							xs={12}
							md={3}
							lg={2}
							borderRight={`1px solid ${theme.colorSchemes[mode]?.bgHover}`}
							bgcolor={theme.colorSchemes[mode]?.bgMain}
							sx={{
								paddingX: {
									xs: "10px",
									xl: "40px",
								},
								paddingY: { xs: "0px", md: "20px" },
								flex: 1,
							}}>
							<Sidebar />
						</Grid>
						{/* ============== END SIDEBAR ================ */}

						{/* ============== MAIN CONTENT ================ */}
						<Grid
							item
							xs={12}
							md={9}
							lg={10}
							paddingBottom={"20px"}
							height={"100%"}
							bgcolor={theme.colorSchemes[mode]?.bgMain}
							sx={{
								overflowY: "auto",
								overflowX: "hidden",
							}}>
							{children}
						</Grid>
						{/* ============== END MAIN CONTENT ================ */}
					</Grid>
				</Box>
			</Box>

			{/* ============== Modal Login ================= */}
			{openModalAuth === EModelAuth.LOGIN && !isLogged && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.LOGIN}>
					<Login />
				</AuthCus>
			)}
			{/* ============== Modal Register =============== */}
			{openModalAuth === EModelAuth.REGISTER && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.REGISTER}>
					<Register />
				</AuthCus>
			)}
			{openModalAuth === EModelAuth.VERIFY && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.VERIFY}>
					<Verify />
				</AuthCus>
			)}
			{openModalAuth === EModelAuth.FORGOT && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.FORGOT}>
					<ForgotPass />
				</AuthCus>
			)}
			{openModalAuth === EModelAuth.RESET_PASS && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={true}>
					<ResetPass />
				</AuthCus>
			)}
			{showModalSuggest &&
				isLogged &&
				!isConfirmTerm &&
				localStorage.getItem("showIntroBody") && (
					<ModalIntroBodyShape
						open={showModalSuggest}
						onClose={() => {
							setShowModalSuggest(false);
							localStorage.removeItem("showIntroBody");
						}}
					/>
				)}
		</Box>
	);
};
