/** @format */

export { default as NavIcons } from "./NavIcons";
export * from "./images";
export * from "./Icons";
export * from "./flag";
export * from "./IconsTool";
export * from "./videos";

export const MODELS = {
	META_MULTI: require("./model/meta_multi_onnx.onnx"),
	META_ONNX: require("./model/meta_onnx.onnx"),
	ONNX: require("./model/onnx.onnx"),
};
