import { Close } from "@mui/icons-material";
import { Box, Dialog, Fade, IconButton } from "@mui/material";
import { TextCus } from "components/TextCus";
import dayjs from "dayjs";
import React from "react";

interface IDialogVideo {
	open: boolean;
	handleClose: () => void;
	urlVideo: string;
	title: string;
	date?: string;
	maxWidth?: string;
}

const DialogVideo = ({
	open,
	handleClose,
	urlVideo,
	title,
	date,
	maxWidth,
}: IDialogVideo) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			sx={{
				zIndex: 9999,
				".MuiDialog-paper": {
					margin: "0px !important",
					borderRadius: "12px",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
					maxWidth: maxWidth ?? "562px",
				},
				".MuiModal-backdrop": {
					backgroundColor: "rgba(0, 0, 0, 0.7)",
				},
			}}>
			<Fade in={open} timeout={500}>
				<Box
					sx={{
						position: "relative",
						padding: 0,
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						background: "rgba(0, 0, 0, 0.7)",
						borderRadius: "12px",
						overflow: "hidden",
						display: "flex",
						alignItems: "center",
					}}>
					<Box
						sx={{
							width: "100%",
							height: "100px",
							background:
								"linear-gradient(to top, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.6))",
							position: "absolute",
							top: 0,
							zIndex: 1,
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "space-between",
							padding: "15px",
						}}>
						<Box
							sx={{ color: "#fff", display: "flex", flexDirection: "column" }}>
							<TextCus fontSize={"14px"} fontWeight={"600"} color={"white"}>
								{title}
							</TextCus>
							<TextCus fontSize={"12px"} fontWeight={"500"} color={"#CCCCCC"}>
								{dayjs(date).format("hh:mm:ss YY-MM-DD")}
							</TextCus>
						</Box>
						<IconButton
							onClick={handleClose}
							sx={{
								color: "#fff",
								zIndex: 1,
								padding: 0,
							}}>
							<Close />
						</IconButton>
					</Box>
					<video
						src={urlVideo}
						controls
						autoPlay
						style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }}
					/>
				</Box>
			</Fade>
		</Dialog>
	);
};

export default DialogVideo;
