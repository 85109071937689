/** @format */

import { Box, useTheme } from "@mui/material";
import {
	hair_1,
	hair_2,
	me_1,
	me_2,
	fit_1,
	fit_2,
	animateImg,
	animateVideo,
	bgBoxStart,
} from "assets";
import React, { memo, useCallback } from "react";
import BoxStart from "./BoxStart";
import { APP_ROOT } from "utils/routes";
import { TextCus } from "components";
import { useUser } from "hooks";
import { ThemeOption } from "types";

const arrListFeature = [
	{
		image: fit_1,
		img: fit_2,
		title: "STYLE FIT",
		desc: "Your AI Virtual Outfit Try On",
		link: APP_ROOT.STYLE_FIT,
	},
	{
		image: me_1,
		img: me_2,
		title: "GEN ME",
		desc: "Your Miniature AI Version",
		link: APP_ROOT.GEN_ME,
	},
	{
		image: hair_1,
		img: hair_2,
		title: "STYLE HAIR",
		desc: "Your AI Hairstyle Changer",
		link: APP_ROOT.STYLE_HAIR,
	},
	{
		image: animateImg,
		img: animateVideo,
		title: "ANIMATE ANYONE",
		desc: "Make You In Motion",
		link: APP_ROOT.ANIMATE,
	},
];

const Banner = () => {
	const renderBoxStart = useCallback(() => {
		return arrListFeature.map((item) => {
			return <BoxStart key={item.title} item={item} />;
		});
	}, []);
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			gap={"24px"}
			className="wrap-content"
			width={"100%"}
			justifyContent={"center"}
			height={"400px"}
			sx={{
				position: "relative",
				backgroundImage: `url(${mode === "dark" ? bgBoxStart : ""})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
			}}>
			<TextCus
				fontSize={"18px"}
				fontWeight={"700"}
				color={theme.colorSchemes[mode]?.textTitle}>
				Let&apos;s Start Your AI Fashion Journey!
			</TextCus>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				width={"100%"}
				gap={"25px"}>
				{renderBoxStart()}
			</Box>
		</Box>
	);
};

export default memo(Banner);
