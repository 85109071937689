import { TParams, TResponse } from "types";
import axiosInstance from "./axios";
import { END_POINT } from "utils";
import { FormDataRecommend, ICategory, ICloth } from "types/merchant";
import axiosClient from "./axios";
const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);
export const merchantService = {
	getCategories: async (
		merchantId: string,
	): Promise<TResponse<ICategory[]>> => {
		const url = END_POINT.CATEGORY.GET_CATEGORY_MERCHANT + merchantId;
		const data = await axiosInstance.get(url);
		return data?.data;
	},
	getClothesByType: async (
		params: TParams,
		endpoint = END_POINT.MERCHANT.GET_CLOTHES_BY_TYPE,
	): Promise<TResponse<ICloth[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data?.data;
	},
	getClothesByParams: async (
		params: TParams,
		endpoint = END_POINT.MERCHANT.GET_CLOTHES_BY_PARAMS,
	): Promise<TResponse<ICloth[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data?.data;
	},

	recommendSize: async (formData: FormDataRecommend) => {
		return await postRequest(END_POINT.MERCHANT.RECOMMEND_SIZE, formData);
	},
};
