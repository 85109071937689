/** @format */

import React from "react";
import { Box } from "@mui/material";
import { fontSize } from "theme/default";
import { TextCus } from "components";

interface IItemMenu {
	isMgTop?: boolean;
	title: string;
	icon: React.ReactNode;
	onAction: () => void;
	color?: string;
}

const ItemMenu = ({
	onAction,
	icon,
	title,
	isMgTop = false,
	color,
}: IItemMenu) => {
	return (
		<Box
			marginTop={isMgTop ? "10px" : "0px"}
			component={"div"}
			onClick={onAction}
			paddingY={"5px"}
			sx={{
				cursor: "pointer",
				transition: "all 0.25s ease-in-out",
				":hover": {
					background: "rgba(149, 119, 217, 0.2)",
				},
			}}
			borderRadius={"10px"}
			display={"flex"}
			padding={"14px"}
			alignItems={"center"}
			gap={"15px"}>
			{icon}
			<TextCus fontSize={fontSize.sm} color={color}>
				{title}
			</TextCus>
		</Box>
	);
};

export default ItemMenu;
