/** @format */

import React from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { ButtonCus } from "components/ButtonCus";
import Carousel from "react-material-ui-carousel";
import { img_1, img_2, img_3 } from "assets";
import { useStoreBodyShape } from "hooks";
import { EModalBodyShape } from "types";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
}

const items = [
	{
		img: img_1,
		description: "Adjust the measurements to your actual height and weight",
	},
	{
		img: img_2,
		description: "Visualize your AI body version result in just one click",
	},
	{
		img: img_3,
		description: "Use your body shape with different features in StyleGen",
	},
];

const ModalIntroBodyShape = ({ open, onClose }: IModalProfile) => {
	const { setShowModelCreate } = useStoreBodyShape();

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					paddingX={"23px"}
					paddingY={"10px"}
					sx={{
						maxWidth: "500px",
						maxHeight: "588px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					borderRadius={"12px"}>
					<Box
						mb={"10px"}
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Create Your Body Shape
						</TextCus>
						<IconButton onClick={onClose}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box
						display={"flex"}
						flex={1}
						flexDirection={"column"}
						justifyContent={"space-between"}
						width={"100%"}>
						<Box flex={1} height={"338px"} width={"100%"}>
							<Carousel
								navButtonsAlwaysInvisible={true}
								indicatorIconButtonProps={{
									style: {
										overflow: "hidden",
										backgroundColor: "#ffffff",
										width: "10px",
										height: "10px",
										borderRadius: "50px",
										fill: "#ffffff",
										color: "#EEEEEE",
										transition: "all 0.25s ease-in-out",
										margin: "2px",
									},
								}}
								activeIndicatorIconButtonProps={{
									style: {
										overflow: "hidden",
										backgroundColor: "#b9b8fd",
										width: "40px",
										height: "10px",
										borderRadius: "12px",
										fill: "#b9b8fd",
										color: "#b9b8fd",
										transition: "all 0.25s ease-in-out",
										margin: "2px",
									},
								}}
								indicatorContainerProps={{
									style: {
										textAlign: "center",
										position: "absolute",
										bottom: 40,
										zIndex: 9,
									},
								}}>
								{items.map((item, i) => (
									<Item key={i} description={item.description} img={item.img} />
								))}
							</Carousel>
						</Box>
						<Box display={"flex"} width={"100%"} flexDirection={"column"}>
							<ButtonCus
								title="Maintenance"
								onClick={() => {
									return;
									setShowModelCreate(EModalBodyShape.CREATE);
									onClose();
								}}
							/>
							<ButtonCus
								sx={{
									background: "white !important",
									boxShadow: "none !important",
								}}
								onClick={() => {
									onClose();
								}}>
								<TextCus color={"#3B3899"} fontSize={"12px"} fontWeight={"500"}>
									Remind me later
								</TextCus>
							</ButtonCus>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalIntroBodyShape;

const Item = ({ description, img }: { img: string; description: string }) => {
	return (
		<Box>
			<img
				style={{
					height: "322px",
					borderRadius: "12px",
					objectFit: "contain",
				}}
				src={img}
				alt="img-suggest"
			/>
			<TextCus
				mt={"40px"}
				textAlign={"center"}
				fontSize={fontSize.xs}
				color={mainColor.textMain}
				fontWeight={"600"}>
				{description}
			</TextCus>
		</Box>
	);
};
