import { Box, Collapse, List, ListItemButton } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fontSize } from "theme";
import { ICategory } from "types/merchant";
import { APP_ROOT } from "utils/routes";

interface IItemMenu {
	category: ICategory;
	isActive: boolean;
	mode?: "light" | "dark";
}

const ItemMenu = ({ category, isActive, mode }: IItemMenu) => {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(isActive ? true : false);
	const [searchParams] = useSearchParams();
	const categoryId = searchParams.get("categoryId");
	const merchantId = searchParams.get("merchantId");
	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Box mt={"15px"}>
			<Box
				display={"flex"}
				onClick={handleClick}
				height={"40px"}
				sx={{
					cursor: "pointer",
					borderRadius: "12px",
					padding: "0 12px",
					transition: "all 0.15s ease-in-out",
					bgcolor: isActive
						? mode === "dark"
							? "rgba(149, 119, 217, 0.2)"
							: "#E8E7FE"
						: "transparent",
					":hover": {
						bgcolor: mode === "dark" ? "rgba(149, 119, 217, 0.2)" : "#E8E7FE",
					},
				}}
				alignItems={"center"}
				justifyContent={"space-between"}>
				<TextCus
					fontWeight={isActive ? "600" : "400"}
					fontSize={fontSize.xs}
					sx={{ textTransform: "capitalize" }}
					color={
						isActive
							? mode === "dark"
								? "#ffffff"
								: "#3B3899"
							: mode === "dark"
							? "#ffffff"
							: "#303030"
					}>
					{category?.name}
				</TextCus>
				{!open ? <Icons.IconDropdown /> : <Icons.IconDropUp />}
			</Box>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{category.categoryChild.map((item) => (
						<ListItemButton
							onClick={() =>
								navigate(
									APP_ROOT.SHOP +
										`?typeModel=${category?.name}&merchantId=${merchantId}&categoryId=${item?.categoryId}`,
								)
							}
							key={item.categoryId}
							sx={{
								pl: 4,
								gap: "15px",
								borderRadius: "12px",
								mt: "10px",
								background:
									isActive && categoryId === item.categoryId
										? mode === "dark"
											? "rgba(149, 119, 217, 0.2)"
											: "#E8E7FE"
										: "",
							}}>
							{Icons.PlusCate(
								isActive && categoryId === item.categoryId
									? mode === "dark"
										? "#ffffff"
										: "#3B3899"
									: mode === "dark"
									? "#ffffff"
									: "#303030",
							)}
							<TextCus
								color={
									isActive && categoryId === item.categoryId
										? mode === "dark"
											? "#ffffff"
											: "#3B3899"
										: mode === "dark"
										? "#ffffff"
										: "#303030"
								}
								fontSize="14px"
								fontWeight={
									isActive && categoryId === item.categoryId ? "600" : "400"
								}>
								{item.name}
							</TextCus>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</Box>
	);
};

export default ItemMenu;
