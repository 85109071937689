import React, { useState } from "react";
import { Box } from "@mui/material";
import { useGenMeStore } from "hooks";
import { TParams, TypeServiceName } from "types";
import { EDropType, IModelType } from "types/outfit";
import ListImage from "./ListImage";
import { ButtonSeeMore } from "components";
import { usePresetModel } from "hooks/usePresetModel";
import Heading from "pages/admin/stylefit/components/Heading";

export const PresetModel: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetPresetModel } = usePresetModel();

	// ======================== STATE ========================
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 3,
		type: TypeServiceName.GEN_ME,
	});

	const {
		presetModel,
		isLoading: isLoadingFemale,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useGetPresetModel(paramsModels);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Preset Model" />
			<ListImage
				isLoading={isLoadingFemale}
				images={presetModel as IModelType[] | undefined}
				selectedImage={selectedFileModel as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(it.id as string);
					setModelImage(it.image as string);
					setSelectedFileGallery(null);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
