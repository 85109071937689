/** @format */

import React, { useState } from "react";
import { Box, Checkbox, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { ButtonCus } from "components/ButtonCus";
import { useMutation } from "@tanstack/react-query";
import { userService } from "services";
import { useProfile, useUser } from "hooks";
import { privacyPolicyUrls, termsAndConditionsUrls } from "utils";

interface IModalConfirmPolicy {
	open: boolean;
	onClose: () => void;
}

const htmlContent = `
<p style="text-align:justify;">
By visiting and using <strong>stylegen.io</strong> and <strong>app.stylegen.io</strong> (hereafter referred to as the "website”, “web application”), you acknowledge and agree to be governed by these Terms and Conditions, including our Disclaimer and Privacy Policy, which are posted on the website and incorporated herein by reference.&nbsp;<br>
<br>
The term "you" refers to anyone who uses, accesses, or sees our website. <strong>StyleGen</strong> ("company", "I", "we" or "us") retains the right, at its sole discretion, to amend or modify these terms and conditions at any time without notice, and by using the website, you accept those changes. It is your duty to regularly check the website for updates.&nbsp;
</p>
<p style="text-align:justify;">
These Terms and Conditions apply to both registered and unregistered users. You accept that by clicking "Sign Up" or otherwise registering, downloading, accessing, or using the Services, you and <strong>StyleGen</strong> are entering into a legally binding agreement for your use of the Services. You acknowledge having read, understood, and agreed to be bound by these Terms and Conditions.
</p>
<p style="text-align:justify;">
<strong>Your continued use of the website following the posting of any modifications to our Terms and Conditions implies acceptance of those changes and updates. You must not access or use the website if you do not agree to be bound by these Terms and Conditions.&nbsp;</strong><br>
<br>
The information and material on this website are meant for people above the age of 18. Children, as defined in our Privacy Policy, are not permitted to use this website.&nbsp;<br>
<br>
&nbsp;
</p>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>1. PRIVACY POLICY</strong></span>
</p>

<p style="text-align:justify;">
We are committed to protecting the privacy of your personal information. Your approval of our Privacy Policy is specifically stated in these Terms and Conditions. Please see our Privacy Policy for more details.
</p>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>2. DISCLAIMER</strong></span>
</p>

<p style="text-align:justify;">
Your approval of our Disclaimer is expressly stated in these Terms &amp; Conditions.&nbsp;
</p>

<p style="text-align:justify;">
<strong>3. ACCOUNT SIGNUP</strong>
</p>
<p style="text-align:justify;">
Certain material and services that we may provide or that you may desire to access may require you to first register with us and create an account ("User Account"). To create a User Account, you may be asked to supply <strong>StyleGen</strong> with some personal information, such as your first and last name, e-mail address, and payment processing account information. You commit to provide correct information and to update it quickly if it changes. <strong>StyleGen</strong> maintains the right to take legal action against anybody who misrepresents personal information or is otherwise untruthful about their identity, as well as to suspend or deactivate User Accounts that are registered with false or incomplete information.&nbsp;<br>
&nbsp;
</p>
<p style="text-align:justify;">
You are entirely responsible for maintaining the security of your User Account, password, and limiting access to your computer. If you allow others to use your account's login credentials, you agree to these Terms of Service on behalf of any other users who use the Services using your User Account or password, and you are responsible for all actions that occur under your User Account or password. If you discover any illegal use of your information or account information for the Services, you agree to tell us promptly at <strong>support@stylegen.io</strong>.
</p>
<p style="text-align:justify;">
Unless expressly permitted in writing by <strong>StyleGen</strong>, you may not sell, rent, lease, share, distribute, or grant access to your account to anyone else, including, but not limited to, charging anyone for administrative access to your account, or otherwise permitting someone else to use your account and the Services, and making your account available to third parties on a software-as-a-service, hosted services, time-sharing, service bureau, or similar basis. <strong>StyleGen</strong> maintains all possible legal rights and remedies to prohibit illegal use of the Services, including but not limited to technological obstacles, IP mapping, and, in severe situations, directly notifying your Internet Service Provider (ISP) about such illegal usage.&nbsp;
</p>

<p style="text-align:justify;">
You Accept THAT YOU HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN YOUR USER ACCOUNT, AND THAT ALL RIGHTS IN AND TO THE USER ACCOUNTS ARE AND WILL BE FOREVER OWNED BY AND INURE TO THE BENEFIT OF <strong>STYLEGEN</strong>.
</p>
<p style="text-align:justify;">
&nbsp;
</p>
<p style="text-align:justify;">
<strong>4. PRODUCT/SERVICE PACKAGES</strong>
</p>
<p style="text-align:justify;">
You understand that while you may have free access to the Services under the "free tier," in order to view certain portions of our Services and gain access to certain elements and features of the Services, you must purchase one of our Services plans (each a "Plan"), as updated from time to time at <strong>StyleGen</strong>'s sole discretion. Our Plans, including price and billing conditions, are detailed here, and they include multiple subscription paid levels with specific capabilities such as unlocking extra processing time, storage space, project output resolution, other generated output quantities and timing for support.&nbsp;
</p>

<p style="text-align:justify;">
Enterprise clients may be committed to the terms of a separate enterprise agreement with <strong>StyleGen</strong>, which may differ from those outlined in these Terms and Conditions.
</p>

<p style="text-align:justify;">
You may cancel your Plan at any time. You can cancel your Plan by navigating to the Settings tab on your User Account, choosing the "Cancel Subscription" option, and completing the termination survey. We shall then contact you to cancel your selected membership level and/or end your User Account. Your User Account will continue to have Plan access until your Plan's billing cycle is complete. Once done, your User Account will be demoted. All plan costs are nonrefundable. It is your duty to remove all User Content from your User Account before termination.&nbsp;
</p>
<p style="text-align:justify;">
&nbsp;
</p>
<p style="text-align:justify;">
Plan payments do not include any relevant sales taxes that may be owed in connection with the Services; nevertheless, if such Services are subject to a sales tax, we may collect any sales taxes in addition to the Plan payments.&nbsp;<br>
<br>
We may engage third-party providers to facilitate payment and fulfillment of Plan purchases. By providing your payment information, you agree that we may share it with these third-party providers in accordance with our Privacy Policy.
</p>
<p style="text-align:justify;">
If you sign up for a free trial or other offer that includes access to paid Services, you must utilize such Services during the trial period. You must discontinue using any paid Services before the end of the trial period to avoid being charged for such Service in line with our usual pricing connected with that Plan.<br>
&nbsp;
</p>
<p style="text-align:justify;">
<strong>5. SERVICE LICENSE</strong>
</p>
<p style="text-align:justify;">
Unless otherwise expressly stated in writing by <strong>StyleGen</strong>, by agreeing to these Terms of Service, you are granted a limited, worldwide, non-assignable, non-exclusive, and non-sublicensable license (i.e., a personal and limited right) to access and use the Services solely to upload, create, generate, and edit media content that interacts with the Service for your personal or internal business use. <strong>StyleGen</strong> maintains all rights not expressly granted in these Terms and Conditions, and you may not use the Services or any portion of them for any purpose other than those specifically authorized by the Agreement.&nbsp;<br>
<br>
<strong>6. </strong><span style="color:#000000;"><strong>MANDATORY ARBITRATION AND GOVERNING LAW&nbsp;</strong></span>
</p>
<p style="text-align:justify;">
You expressly waive any legal claims, now or in the future, arising from or associated with the website and our products/services. If there is a disagreement, claim, or controversy arising from or connected to your use of this website, the terms and conditions will be construed in accordance with the rules and regulations of Vietnam and the United States.&nbsp;
</p>

<p style="text-align:justify;">
You agree to consent to and submit to the jurisdiction of Vietnam and federal courts, regardless of conflict of law rules or the parties' location at the time a dispute occurs.&nbsp;
</p>

<p style="text-align:justify;">
You agree to address any disputes or claims first through mandatory arbitration in Vietnam, and you will cover the full cost of arbitration as permitted by law. Your good faith participation in arbitration is required before pursuing any other legal or equitable remedies, such as lawsuit or other legal procedures. You further agree that if a legal claim is filed after the requisite arbitration, the successful party is entitled to reasonable attorney's fees and other costs associated with the action.&nbsp;
</p>

<p style="text-align:justify;">
<span style="color:#000000;"><strong>7. INTELLECTUAL PROPERTY&nbsp;</strong></span>
</p>

<p style="text-align:justify;">
All content on this website, including but not limited to text, posts, logos, marks, graphics, files, materials, services, products, videos, audio, applications, computer code, designs, downloads, and all other information (collectively referred to as "Content"), is owned by us and is protected by copyright, trademark, and other intellectual property, as well as unfair competition laws, with the exception of any content from others that we are legally permitted to use. You are granted a limited, revocable license to print or download Content from the website for personal, non-commercial, non-transferable, informational, and educational purposes only, provided that it does not violate any copyright, trademark, intellectual property, or proprietary rights.
</p>

<p style="text-align:justify;">
You undertake not to copy, duplicate, steal, edit, publish, display, distribute, reproduce, store, transmit, post, create derivative works, reverse engineer, sell, rent, or license any element of the Content to anyone without our express written permission. You agree to follow copyright, trademark, and intellectual property laws and will be fully accountable for any violations of these terms and conditions.&nbsp;
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>8. USER CONTENT AND LAWFUL USE OF THE WEBSITE&nbsp;</strong></span>
</p>
<br>
<p style="text-align:justify;">
For any Content or information that you upload, display, post, transmit, send, email, or submit to us on the website or any of our social media sites, you warrant that you own it or have express permission from the owner of those intellectual property rights to use and distribute it to us.&nbsp;
</p>
<br>
<p style="text-align:justify;">
You grant us and/or our officers, employees, successors, shareholders, joint venture partners, or anyone else who works with us a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to identify you, publish, post, reformat, copy, distribute, display, edit, and reproduce any content you provide on our website and social media sites for any purpose. You will be fully responsible for any damages resulting from the infringement of copyrights, trademarks, or other intellectual rights in any Content or information you supply to us.
</p>
<br>
<p style="text-align:justify;">
You agree not to upload, display, post, transmit, distribute, send, email, or submit to us on the website or any of our social media sites any information or anything that is&nbsp;<br>
<br>
(a) violates or infringes on others' rights,&nbsp;
</p>
<p style="text-align:justify;">
(b) is defamatory, abusive, profane, hateful, vulgar, obscene, libelous, pornographic, or threatening,&nbsp;
</p>
<p style="text-align:justify;">
(c) encourages or advocates conduct that would constitute a criminal offense, giving rise to civil liability or otherwise violate any law,&nbsp;
</p>
<p style="text-align:justify;">
(d) distributes harmful information, including but not limited to spyware, computer viruses, malicious computer software, or any other harmful information that is actionable.&nbsp;
</p>
<p style="text-align:justify;">
(e) Unauthorized access to the website, as well as sending unsolicited or unauthorized material or disrupting its operation, are prohibited. You agree to use the website solely for legitimate purposes and to be accountable for any damages resulting from a violation of any provision of these Terms and Conditions.&nbsp;
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>9. THIRD-PARTY LINKS</strong></span>
</p>
<p style="text-align:justify;">
The website may provide connections to third-party websites or resources for your convenience. We may act as an affiliate for some of these third-party websites, promoting or advertising their products or services on the site; but, we do not own or operate these websites. When you click on a third-party link and leave this website, you are no longer subject to our terms and conditions.
</p>
<br>
<p style="text-align:justify;">
You acknowledge that we are not responsible or liable for the accuracy, content, or information contained on these third-party websites. You bear all risks associated with accessing these third-party websites or resources, and any transactions you enter into with these third-party websites are solely between you and the third party. We will not be liable for any damages caused by your use of any third-party websites or resources.&nbsp;<br>
<br>
<br>
&nbsp;
</p>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>10. USE OF OUR PAID AND FREE PRODUCTS/SERVICES</strong></span>
</p>
<br>
<p style="text-align:justify;">
We may provide you with free trial products/services as well as paid products/services and other associated materials (collectively, "products") through this website. All of our products and/or services, as well as all content, are protected by copyright under US and international laws. You are granted a limited, revocable license to print or download Content from our products/services for personal, informative, and educational purposes only, provided that it does not violate any copyright, trademark, intellectual property, or proprietary rights. Copying or storing our content for purposes other than personal use is strictly prohibited without our prior written permission.&nbsp;
</p>
<br>
<p style="text-align:justify;">
You understand and agree that you have no right to distribute, modify, sell, edit, copy, reproduce, create derivative works from, reverse engineer, enhance, or otherwise exploit our products. You may not sell or redistribute any of our products, whether free or paid, without our express written approval. You agree to follow copyright, trademark, and intellectual property laws and will be fully accountable for any violations of these terms and conditions.
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>11. TERMINATION</strong></span>
</p>
<br>
<p style="text-align:justify;">
We reserve the right, at our sole discretion, to deny, remove, restrict your access to, revoke, and terminate your use of our website, including any or all Content published by you or us, at any time and without notice.&nbsp;
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>12. NO REFUNDS&nbsp;</strong></span>
</p>
<br>
<p style="text-align:justify;">
Any purchases of products and/or services through this website are final. There will be no reimbursements issued. We sincerely believe in giving rather than getting, and each of our goods and services is developed with this core idea in mind. Prices are purposefully maintained low in comparison to market value in order to provide you with the tools and technology you require at an accessible cost.&nbsp;
</p>
<br>
<p style="text-align:justify;">
<span style="color:#222222;"><strong>13. NO WARRANTIES&nbsp;</strong></span>
</p>
<p style="text-align:justify;">
<span style="color:#222222;">&nbsp;</span>
</p>
<p style="text-align:justify;">
All content, information, products, and/or services on the website are provided "as is" and "as available" without any representations or warranties of any kind, including warranties of merchantability or fitness for any purpose, express or implied to the fullest extent permitted by law. Company makes no representations or warranties about the content, information, materials, products, or services provided on this website. Company makes no warranty that the website will perform or operate to meet your needs, or that the information provided here will be complete, current, or error-free.
</p>
<br>
<p style="text-align:justify;">
To the fullest extent permitted by law, the company disclaims any warranties, implied and express, for any purpose.
</p>
<br>
<p style="text-align:justify;">
<span style="color:#222222;"><strong>14. LIMITATION OF LIABILITY</strong></span>
</p>
<br>
<p style="text-align:justify;">
You agree that we and/or our officers, employees, successors, shareholders, joint venture partners, or anyone else who works with us will not be liable for any direct, indirect, incidental, consequential, equitable, special, punitive, exemplary, or any other damages resulting from your use of this website, including but not limited to all of the content, information, products, services, and graphics presented here.&nbsp;<br>
<br>
You expressly agree that your use of the website is at your own risk, and that you are solely responsible for the accuracy of the personal and any information you submit, the consequence of your activities, personal and business results, and all other uses of the website.&nbsp;
</p>
<p style="text-align:justify;">
You also expressly agree that we and/or our officers, employees, successors, shareholders, joint venture partners, or anyone else working with us shall not be liable to you for any damages resulting from: 1) any errors or omissions on the website, delay or denial of any products or services, failure of performance of any kind, interruption in the operation and your use of the website, website attacks including computer viruses, hacking of information, and any other system failure.&nbsp;
</p>
<br>
<p style="text-align:justify;">
This limitation of liability applies regardless of whether the liability arises from negligence, breach of contract, tort, or any other legal theory of liability. You agree that we make no stated or implicit claims about the content offered here, and that no specific results are promised to you here.&nbsp;
</p>
<p style="text-align:justify;">
<span style="color:#222222;">&nbsp;</span>
</p>

<p style="text-align:justify;">
<span style="color:#222222;"><strong>15. INDEMNIFICATION</strong></span>
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;">You agree to indemnify and hold the Company </span><span style="color:#222222;">and/or its officers, employees, successors, shareholders, joint venture partners or anyone else working with us</span><span style="color:#000000;"> harmless from all losses, claims, damages, demands, actions, suits, proceedings or judgments, including costs, expenses and reasonable attorneys' fees ("Liabilities") assessed against or otherwise incurred by you arising, in whole or in part, from: (a) actions or omissions, whether done negligently or otherwise, by you, your agents, directors, officers, employees or representatives; (b) all your actions and use of the website including purchasing products and services; (c) violation of any laws</span><span style="color:#676768;">, </span><span style="color:#000000;">rules, regulations or ordinances by you; or (d) violation of any terms and conditions of this website by you or anyone related to you; e) infringement by you or any other user of your account of any intellectual property or other rights of anyone. The Company will notify you promptly of any such claims or liability and reserves the right to defend such claim, liability or damage at your expense. You shall fully cooperate and provide assistance to us if requested, without any cost, to defend any such claims.&nbsp;</span>
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>16. ENTIRE AGREEMENT</strong></span>
</p>
<br>
<p style="text-align:justify;">
These Terms &amp; Conditions, along with our Privacy Policy and Disclaimer, form the whole agreement between you and us about this website. It replaces any previous or contemporaneous conversations, discussions, negotiations, or proposals we may have had with you, whether electronic, oral, or written.&nbsp;<br>
<br>
A printed version of this entire agreement, including the Privacy Policy and Disclaimer, as well as any notice given in electronic form, will be admissible in judicial or administrative proceedings relating to this website to the same extent and with the same effect as other business contracts and documents kept and maintained in printed form.&nbsp;
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>17. SEVERABILITY</strong></span>
</p>
<br>
<p style="text-align:justify;">
If a court, regulatory body, or other public or private tribunal of competent jurisdiction finds any term in these Terms and Conditions to be unlawful or unenforceable, that provision is presumed to be omitted from this Agreement. The remainder of this Agreement remains in full force and effect, and is amended only to the amount required to give such force and effect to the remaining sections.
</p>
<br>
<p style="text-align:justify;">
<span style="color:#000000;"><strong>18. CONTACT</strong></span>
</p>
<br>
<p style="text-align:justify;">
If you have any questions, please email us at <strong>support@stylegen.io</strong>.
</p>
<br> `;

const ModalConfirmPolicy = ({ open, onClose }: IModalConfirmPolicy) => {
	const [checked, setChecked] = useState(false);
	const { getProfile } = useProfile();
	const { languages } = useUser();

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const { mutate: confirmTerm, isPending } = useMutation({
		mutationFn: () => userService.confirmTerm(true),
		onSuccess: ({ data }) => {
			if (data.statusCode === 200) {
				getProfile();
				onClose();
			}
		},
	});

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					sx={{
						maxWidth: "680px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"649px"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Terms and Conditions
						</TextCus>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box
						padding={"20px"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"20px"}
						width={"100%"}
						height={"calc(100% - 50px)"}>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							height={"calc(100% - 100px)"}
							width={"100%"}
							sx={{ overflowY: "scroll" }}
							gap={"15px"}
							justifyContent={"center"}>
							<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
						</Box>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"flex-start"}
							width={"100%"}
							height={"100px"}>
							<Box
								display={"flex"}
								gap={"10px"}
								alignItems={"center"}
								justifyContent={"flex-start"}
								width={"100%"}
								borderRadius={"12px"}>
								<Box display={"flex"} alignItems={"start"} ml={"-10px"}>
									<Checkbox
										sx={{
											color: "#5B3E9B",
											"&.Mui-checked": {
												color: "#5B3E9B",
											},
										}}
										checked={checked}
										onChange={handleChange}
										inputProps={{ "aria-label": "controlled" }}
									/>
									<Box component={"p"}>
										By signing in to StyleGen, you agree to our{" "}
										<a
											style={{ color: "#5B3E9B" }}
											target="blank"
											href={
												termsAndConditionsUrls[languages.value] ||
												termsAndConditionsUrls.en
											}>
											Terms & Conditions
										</a>{" "}
										and{" "}
										<a
											style={{ color: "#5B3E9B" }}
											href={
												privacyPolicyUrls[languages.value] ||
												privacyPolicyUrls.en
											}
											target="blank">
											Privacy Policy
										</a>
									</Box>
								</Box>
							</Box>
							<Box
								width={"100%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<ButtonCus
									sx={{ marginBottom: "10px" }}
									isLoading={isPending}
									disabled={!checked}
									title="Confirm"
									onClick={() => confirmTerm()}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalConfirmPolicy;
