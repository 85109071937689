import { Box, useTheme } from "@mui/material";
import { styleGenLoading } from "assets";
import React from "react";
import { LoadingBar, TextCus } from "components";
import { useUser } from "hooks";
import { ThemeOption } from "types";

const BoxLoading = ({
	isStart,
	valueProcess,
	isLoadingHistory,
}: {
	isStart?: boolean;
	valueProcess: number;
	isLoadingHistory?: boolean;
}) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<>
			<Box>
				<img
					src={styleGenLoading}
					width={"50px"}
					height={"50px"}
					alt="loading"
				/>
			</Box>
			{!isLoadingHistory && (
				<>
					{isStart ? (
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							flexDirection={"column"}>
							<TextCus
								fontWeight={"600"}
								fontSize={"14px"}
								color={theme.colorSchemes[mode]?.textTitle}>
								Please wait
							</TextCus>
							<TextCus
								fontWeight={"500"}
								fontSize={"13px"}
								color={theme.colorSchemes[mode]?.textTitle}>
								Your progress will start soon!
							</TextCus>
						</Box>
					) : (
						<TextCus
							fontWeight={"600"}
							fontSize={"14px"}
							color={theme.colorSchemes[mode]?.textTitle}>
							Generating your image, completing in 1 minute
						</TextCus>
					)}
					<Box width={"200px"} maxWidth={"200px"}>
						<LoadingBar value={valueProcess} />
					</Box>
				</>
			)}
		</>
	);
};

export default BoxLoading;
