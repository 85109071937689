import { Box, Skeleton, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { TabCus } from "components/TabCus";
import { arrTab, ThemeOption, TParams, TypeServiceName } from "types";
import { useModel, useUser } from "hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icons, illustration } from "assets";
import { ButtonCus, ButtonSeeMore, TextCus } from "components";
import { fontSize } from "theme";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { IDataRename } from "types/outfit";
import { BoxTemplate } from "pages/admin/dashboard/components";
import { Banner } from "pages/models/components";
import SnackbarUtils from "utils/SnackbarUtils";

const YourGenerations = () => {
	const navigate = useNavigate();
	const { useGetGalleryList, deleteGalleryMutation, renameGalleryMutation } =
		useModel();
	const [paramsGallery, setParamsGallery] = useState<TParams>({
		page: 1,
		limit: 8,
		type: TypeServiceName.OUTFIT,
	});

	const {
		gallery: dataGallery,
		refetch,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useGetGalleryList(paramsGallery);
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<Box
					width={"100%"}
					display={"flex"}
					gap={"10px"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}>
					{[...Array(4)].map((_, index) => {
						return (
							<Box
								display={"flex"}
								height={"350px"}
								width="calc((100% - 30px) / 4)"
								gap={"10px"}
								flexDirection={"column"}
								key={index}>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "200px",
										width: "100%",
									}}
								/>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "20px",
										width: "200px",
									}}
								/>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "20px",
										width: "100px",
									}}
								/>
							</Box>
						);
					})}
				</Box>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"328px"}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}
					flexDirection={"column"}>
					<LazyLoadImage src={illustration} alt="empty" />
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						flexDirection={"column"}>
						<TextCus color={theme.colorSchemes[mode]?.textMain}>
							Start by uploading a photo
						</TextCus>
						<TextCus color={theme.colorSchemes[mode]?.textGray}>
							Your generations will be shown here
						</TextCus>
					</Box>
					<ButtonCus
						sx={{ gap: "20px" }}
						onClick={() => {
							if (paramsGallery.type === TypeServiceName.HAIR_SWAP) return;
							if (paramsGallery.type === TypeServiceName.ANIMATE)
								return SnackbarUtils.warning("Feature under maintenance.");
							if (paramsGallery.type === TypeServiceName.OUTFIT)
								return navigate(APP_ROOT.STYLE_FIT);
							if (paramsGallery.type === TypeServiceName.GEN_ME)
								return navigate(APP_ROOT.GEN_ME);
						}}>
						<TextCus fontWeight={"600"} fontSize={fontSize.xs}>
							{paramsGallery.type === TypeServiceName.HAIR_SWAP
								? "Coming soon"
								: `Let's start`}
						</TextCus>
						{Icons.Start()}
					</ButtonCus>
				</Box>
			);
		}
	}, [isLoading, paramsGallery.type]);

	const handleDelete = useCallback((id) => {
		deleteGalleryMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	const handleRename = useCallback((data: IDataRename) => {
		renameGalleryMutation.mutate(data, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	const renderItem = useCallback(() => {
		return dataGallery?.map((item) => {
			return (
				<BoxTemplate
					item={item}
					key={item.id}
					onDelete={handleDelete}
					handleRename={handleRename}
					numberItem={4}
					typeTab={paramsGallery.type}
				/>
			);
		});
	}, [dataGallery, paramsGallery.type]);

	return (
		<Box>
			<Banner title="Your Generation" />
			<Box
				maxWidth={"1156px"}
				margin={"0 auto"}
				padding={"0px 15px"}
				width={"100%"}
				sx={{
					mt: {
						xs: "50px",
					},
				}}>
				<Box my={"40px"}>
					<TabCus
						setValueTab={(v) => setParamsGallery({ ...paramsGallery, type: v })}
						arrTab={arrTab}
					/>
				</Box>
				{!dataGallery?.length || isLoading ? (
					renderDataLoading()
				) : (
					<>
						<Box
							mt={"20px"}
							display={"flex"}
							justifyContent={"space-start"}
							alignItems={"center"}
							gap={"10px"}
							rowGap={"20px"}
							flexWrap={"wrap"}>
							{renderItem()}
						</Box>
						{hasNextPage && (
							<ButtonSeeMore
								fetchNextPage={fetchNextPage}
								isFetchingNextPage={isFetchingNextPage}
							/>
						)}
					</>
				)}
			</Box>
		</Box>
	);
};

export default YourGenerations;
