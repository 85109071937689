import React from "react";
import { Box } from "@mui/material";
import { TextCus } from "components";
import { useLoadImage } from "hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ICloth } from "types/merchant";

interface ItemProduct {
	handleChooseModel: (v: any) => void;
	item: ICloth;
	color: string;
}

const ItemProduct = ({ handleChooseModel, item, color }: ItemProduct) => {
	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();

	return (
		<Box
			component={"div"}
			width={"calc((100% - 80px) / 6)"}
			borderRadius={"12px"}
			onClick={() => handleChooseModel(item)}
			display={"flex"}
			alignItems={"start"}
			justifyContent={"center"}
			flexDirection={"column"}
			gap={"10px"}
			sx={{
				transition: "all 0.35s ease-in-out",
				cursor: "pointer",
				":hover": {
					".overlay_product": {
						top: 0,
						opacity: 1,
					},
				},
			}}>
			<Box
				width={"100%"}
				borderRadius={"12px"}
				position={"relative"}
				sx={{
					transition: "all 0.35s ease-in-out",
					overflow: "hidden",
					cursor: "pointer",
					bgcolor: "white",
					border: "1px solid #EEEEEE",
				}}>
				<LazyLoadImage
					className="img_model"
					style={{
						display: "block",
						objectFit: "cover",
						width: "100%",
						transition: "all 0.35s ease-in-out",
						borderRadius: "12px",
						aspectRatio: "169 / 215",
					}}
					src={error ? img404 : loading ? imgLoad : `${item.image}`}
					onLoad={handleLoad}
					onError={handleError}
					alt={error ? "Error" : "model"}
				/>
				<Box
					className="overlay_product"
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					position={"absolute"}
					sx={{ opacity: 0, transition: "all 0.25s ease-in-out" }}
					top={20}
					left={0}
					bgcolor={"rgba(0, 0, 0, 0.3)"}
					width={"100%"}
					height={"100%"}>
					<Box
						sx={{ backdropFilter: "blur(20px)" }}
						width={"50%"}
						height={"30px"}
						bgcolor={"rgba(255, 255, 255, 0.5)"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						borderRadius={"12px"}>
						<TextCus color={"#ffffff"} fontSize={"12px"} fontWeight={"500"}>
							Try on
						</TextCus>
					</Box>
				</Box>
			</Box>
			<Box
				fontSize={"12px"}
				className="res_text"
				fontWeight={"400"}
				color={color}
				width={"100%"}>
				{item?.name}
			</Box>
		</Box>
	);
};

export default ItemProduct;
