import { Box, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TitleSection from "./TitleSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	useLoadImage,
	useModel,
	useOutfitStore,
	useStoreBodyShape,
	useUser,
} from "hooks";
import { ThemeOption, TParams } from "types";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { IModelType, TypeYourBody } from "types/outfit";
import { fontSize } from "theme";
import { KEY_CONTEXT } from "utils";

const YourModels = () => {
	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();
	const navigate = useNavigate();
	const { useGetYourModels } = useModel();
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 6,
		type: TypeYourBody.BODY_SHAPE,
	});
	const { setShowModalSuggest } = useStoreBodyShape();

	const { models: dataModel, isLoading } = useGetYourModels(paramsModels);
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileModel(item._id as string);
		setModelImage(item.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	useEffect(() => {
		if (dataModel?.length > 0) {
			setShowModalSuggest(false);
			localStorage.setItem(KEY_CONTEXT.SUGGEST_BODY, "true");
		} else if (dataModel?.length === 0 && !isLoading) {
			setShowModalSuggest(true);
		}
	}, [isLoading]);
	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();
	if (dataModel?.length === 0) return <></>;
	return (
		<Box className="wrap-content" width={"100%"} mt={"70px"}>
			<TitleSection
				title="Your Body Shape"
				action={() => navigate(APP_ROOT.BODY_SHAPES)}
			/>
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"flex-start"}
				alignItems={"center"}
				gap={"15px"}>
				{dataModel?.slice(0, 5).map((item, index) => {
					return (
						<Box
							component={"div"}
							width={"calc((100% - 60px) / 5)"}
							borderRadius={"12px"}
							onClick={() => handleChooseModel(item)}
							display={"flex"}
							alignItems={"start"}
							justifyContent={"center"}
							flexDirection={"column"}
							gap={"10px"}
							sx={{
								transition: "all 0.35s ease-in-out",
								cursor: "pointer",
							}}
							key={`${item?.id || "" + index}`}>
							<Box
								width={"100%"}
								height={"249px"}
								borderRadius={"12px"}
								sx={{
									transition: "all 0.35s ease-in-out",
									overflow: "hidden",
									cursor: "pointer",
									":hover": {
										".img_model": {
											transform: "scale(1.2)",
										},
									},
								}}>
								<LazyLoadImage
									className="img_model"
									style={{
										display: "block",
										objectFit: "cover",
										width: "100%",
										transition: "all 0.35s ease-in-out",
										borderRadius: "12px",
									}}
									src={error ? img404 : loading ? imgLoad : `${item?.image}`}
									onLoad={handleLoad}
									onError={handleError}
									alt={error ? "Error" : "model"}
								/>
							</Box>
							<Box
								fontSize={fontSize.xs}
								fontWeight={"600"}
								color={theme.colorSchemes[mode]?.textTitle}
								textAlign={"center"}
								width={"100%"}>
								{(item?.height || 0) / 100 + "m"} - {item?.weight + "kg"}
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default YourModels;
