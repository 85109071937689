import React, { useState } from "react";
import { Box } from "@mui/material";
import { useModel, useAnimateStore } from "hooks";
import { TParams, TypeServiceName } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { ButtonSeeMore, SelectCus } from "components";
import { arrGallery } from "utils";

export const Gallery: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileGallery,
		setSelectedFileGallery,
		setCurrentStep,
	} = useAnimateStore();
	const { useGetGalleryList } = useModel();

	// ======================== STATE ========================
	const [paramsGallery, setParamsGallery] = useState<TParams>({
		page: 1,
		limit: 3,
		type: TypeServiceName.GEN_ME,
	});
	const {
		gallery: dataGallery,
		isLoading: isLoadingGallery,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useGetGalleryList(paramsGallery);

	const handleChange = (e) => {
		setParamsGallery({ ...paramsGallery, type: e.target.value });
	};

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Gallery" />
			<SelectCus
				width="120px"
				onChange={handleChange}
				options={arrGallery}
				value={paramsGallery?.type + ""}
			/>
			<ListImage
				isLoading={isLoadingGallery}
				images={dataGallery}
				selectedImage={selectedFileGallery as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(null);
					setModelImage(it.image as string);
					setSelectedFileGallery(it.id as string);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
