import { Box, useTheme } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import { useUser } from "hooks";
import React from "react";
import { fontSize } from "theme";
import { ThemeOption } from "types";

interface TTitleSection {
	title: string;
	action?: () => void;
}

const TitleSection = ({ title, action }: TTitleSection) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			width={"100%"}
			alignItems={"center"}>
			<TextCus
				color={theme.colorSchemes[mode]?.textTitle}
				fontSize={fontSize.sm}
				fontWeight={700}>
				{title}
			</TextCus>
			{action && (
				<Box
					component="div"
					sx={{ cursor: "pointer" }}
					onClick={action}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}>
					<TextCus
						fontSize={"12px"}
						fontWeight={"500"}
						color={theme.colorSchemes[mode]?.textGray}>
						See all
					</TextCus>
					{Icons.Start(theme.colorSchemes[mode]?.textGray)}
				</Box>
			)}
		</Box>
	);
};

export default TitleSection;
