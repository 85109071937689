import { Box, useTheme } from "@mui/material";
import { face, insta, logoFt, pinter, tiktok, x, youtu } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import { Header } from "pages/admin/dashboard/components";
import React from "react";
import { ThemeOption } from "types";

interface HelpCenterLayoutProps {
	children: React.ReactNode;
}

const LINK_SOCIAL = [
	{ logo: insta, link: "https://www.instagram.com/stylegen.aifashion" },
	{ logo: face, link: "https://www.facebook.com/stylegenaifashion" },
	{ logo: youtu, link: "https://www.youtube.com/@stylegenaifashion" },
	{ logo: pinter, link: "https://www.pinterest.com/stylegenai" },
	{ logo: tiktok, link: "https://www.tiktok.com/@stylegen.aifashion" },
	{ logo: x, link: "https://x.com/stylegen_ai" },
];

export const HelpCenterLayout: React.FC<HelpCenterLayoutProps> = ({
	children,
}) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;
	return (
		<Box
			minHeight={"100vh"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"space-between"}
			flexDirection={"column"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				bgcolor={theme.colorSchemes[mode]?.bgMain}
				sx={{
					overflowY: "auto",
				}}
				alignItems={"center"}
				justifyContent={"center"}>
				<Box
					width={"100%"}
					minHeight={"50vh"}
					height="calc(100vh - 156px)"
					component={"main"}>
					{children}
				</Box>
			</Box>
			<Box
				borderTop={`1px solid ${theme.colorSchemes[mode].bgHover}`}
				sx={{
					paddingX: {
						xs: "15px",
						lg: "40px",
					},
				}}
				height={"88px"}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				bgcolor={theme.colorSchemes[mode]?.bgMain}>
				<img src={logoFt} alt="Logo" />
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href="https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN.pdf">
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"500"}>
						Privacy Policy
					</TextCus>
				</Box>
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href="https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf">
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"500"}>
						Terms and Conditions
					</TextCus>
				</Box>
				<Box
					display={"flex"}
					gap={"24px"}
					alignItems={"center"}
					justifyContent={"center"}>
					{LINK_SOCIAL.map((item, index) => {
						return (
							<Box key={index} component={"a"} target="blank" href={item.link}>
								<img src={item.logo} alt="logo" />
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
};

export default HelpCenterLayout;
