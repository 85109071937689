/** @format */

import React from "react";

import { Box, Grid, useTheme } from "@mui/material";
import { Header, Sidebar } from "pages/shop/components";
import { ThemeOption } from "types";
import { useUser } from "hooks";

interface ShopLayoutProps {
	children: React.ReactNode;
}

export const ShopLayout: React.FC<ShopLayoutProps> = ({ children }) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<Box minHeight={"100vh"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				alignItems={"center"}
				position={"relative"}
				justifyContent={"center"}>
				<Box
					width={"100%"}
					height={"100%"}
					minHeight="calc(100vh - 76px)"
					component={"main"}>
					<Grid container height={"calc(100vh - 68px)"} overflow={"hidden"}>
						{/* ============== SIDEBAR ================ */}
						<Grid
							item
							xs={4}
							md={3}
							xl={2}
							borderRight={`1px solid ${theme.colorSchemes[mode]?.bgHover}`}
							bgcolor={theme.colorSchemes[mode]?.bgMain}
							sx={{
								flex: 1,
							}}>
							<Sidebar />
						</Grid>
						{/* ============== END SIDEBAR ================ */}

						{/* ============== MAIN CONTENT ================ */}
						<Grid
							item
							xs={8}
							md={9}
							xl={10}
							height={"100%"}
							bgcolor={theme.colorSchemes[mode]?.bgProcess}
							sx={{
								margin: "auto",
								overflowY: "auto",
								overflowX: "hidden",
							}}>
							{children}
						</Grid>
						{/* ============== END MAIN CONTENT ================ */}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};
