import { useQuery } from "@tanstack/react-query";
import { TextCus } from "components";
import React, { useState } from "react";
import { paymentService } from "services";
import { fontSize } from "theme";
import { ThemeOption, TParams } from "types";
import { QueryKeys } from "utils";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
	Chip,
	CircularProgress,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { Icons, kc } from "assets";
import { useUser } from "hooks";
import { useTranslation } from "react-i18next";

const Transaction = () => {
	const { t } = useTranslation();
	const { user } = useUser();
	const [params, setParams] = useState<TParams>({
		page: 1,
		limit: 5,
	});

	const { data, isLoading } = useQuery({
		queryKey: [QueryKeys.TRANSACTION, params.page, params.typeModel],
		queryFn: () => paymentService.getTransactions({ ...params }),
	});

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			paddingBottom={"100px"}
			sx={{
				gap: {
					xs: "50px",
					xl: "100px",
				},
			}}>
			<Box
				marginTop={"100px"}
				width={"100%"}
				maxWidth={"668px"}
				border={"1px solid #6DDBFE"}
				px={"20px"}
				borderRadius={"12px"}
				height={"60px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}>
				<TextCus
					fontWeight={"600"}
					fontSize={"16px"}
					color={theme.colorSchemes[mode]?.textMain}>
					Your Credits
				</TextCus>
				<Box display={"flex"} alignItems={"center"} gap={"10px"}>
					<img width={20} src={kc} alt="kc" />
					<TextCus color={"#5271FF"} fontSize={fontSize.sm} fontWeight={"bold"}>
						{(user?.credits || 0) + ""}
					</TextCus>
				</Box>
			</Box>
			<Paper
				sx={{ width: "90%", background: theme.colorSchemes[mode]?.bgModal }}>
				{isLoading ? (
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						height={"100px"}>
						<CircularProgress />
					</Box>
				) : (
					<TableContainer sx={{ position: "relative" }}>
						<Table>
							<TableHead>
								<TableRow
									sx={{
										color: "#7340D8 !important",
									}}>
									{[
										"No",
										"Invoice ID",
										"Items",
										"Date",
										"Amount ($)",
										"Payment Method",
										"Status",
									].map((item, index) => (
										<TableCell
											key={index}
											sx={{
												color: "#7340D8 !important",
												fontSize: "14px",
												fontWeight: "600",
												fontFamily: "Montserrat !important",
											}}>
											{t(item)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							{data?.data && data?.data?.length > 0 ? (
								<TableBody>
									{data?.data.map((row, index) => (
										<TableRow key={index}>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{index + 1}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{row.invoice}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{row.plan}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{dayjs(row.startDate).format("HH:mm, YYYY/MM/DD")}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{row.amount}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												{row.paymentMethod}
											</TableCell>
											<TableCell
												sx={{
													color: theme.colorSchemes[mode]?.textMain,
													fontFamily: "Montserrat !important",
												}}>
												<Chip
													label={t("Success")}
													color={"success"}
													sx={{
														borderRadius: "10px",
														fontFamily: "Montserrat !important",
													}}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										height: "100px",
										textAlign: "center",
									}}>
									<TextCus
										fontSize={fontSize.sm}
										sx={{
											position: "absolute",
											left: "50%",
											bottom: "10%",
											transform: "translateX(-50%)",
										}}>
										No transactions
									</TextCus>
								</Box>
							)}
						</Table>
					</TableContainer>
				)}

				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={2}>
					<Button
						sx={{
							gap: "10px",
							fontSize: "12px",
							color: `${theme.colorSchemes[mode]?.textMain} !important`,
						}}
						disabled={params.page === 1}
						onClick={() => setParams({ ...params, page: params.page - 1 })}>
						{Icons.Prev()} {t("Prev")}
					</Button>
					<Button
						sx={{
							gap: "10px",
							fontSize: "12px",
							color: `${theme.colorSchemes[mode]?.textMain} !important`,
						}}
						disabled={params.page >= (data?.pagination?.totalPage || 1)}
						onClick={() => setParams({ ...params, page: params.page - 1 })}>
						{t("Next")} {Icons.Next()}
					</Button>
				</Box>
			</Paper>
		</Box>
	);
};

export default Transaction;
