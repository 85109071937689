/** @format */

import React, { useCallback, useEffect, useRef } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { BoxCredit, TextCus } from "components";
import { Icons } from "assets";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { useStyleHairStore, useUser } from "hooks";
import dayjs from "dayjs";
import { ThemeOption } from "types";

const Header = () => {
	const navigate = useNavigate();

	const refInput = useRef<HTMLInputElement>(null);
	const handleGalleryTitleChange = useCallback((e) => {
		setName(e.target.value);
	}, []);
	const { name, setName, reset, currentStep, isLoadingProcess } =
		useStyleHairStore();

	const handleGalleryTitleBlur = useCallback(() => {
		setName(name);
	}, [name]);

	const selectAllInlineText = useCallback((e) => {
		e.target.focus();
		e.target.select();
	}, []);

	useEffect(() => {
		setName(`StyleHair-${dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")}`);
	}, []);

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"68px"}
			borderBottom={`1px solid ${theme.colorSchemes[mode]?.bgHover}`}
			bgcolor={theme.colorSchemes[mode]?.bgMain}>
			<Grid
				container
				sx={{
					height: "100%",
					paddingX: {
						xs: "15px",
						xl: "40px",
					},
				}}
				width={"100%"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					gap={"20px"}
					justifyContent={"start"}>
					<Box
						component={"div"}
						onClick={() => {
							!isLoadingProcess && reset();
							navigate("/");
						}}
						sx={{
							cursor: "pointer",
							display: "flex",
							alignItems: "center",
							gap: "20px",
							textDecoration: "none",
						}}>
						<Box
							width={"36px"}
							height={"36px"}
							borderRadius={"12px"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							bgcolor={mainColor.bgButton}>
							{Icons.Back(theme.colorSchemes[mode]?.textTitle)}
						</Box>
						<TextCus
							color={theme.colorSchemes[mode]?.textTitle}
							sx={{
								fontSize: {
									xs: fontSize.sm,
									md: fontSize.sm,
								},
							}}
							fontWeight={"700"}>
							STYLE HAIR
						</TextCus>
					</Box>
					<Box
						display={"flex"}
						alignContent={"center"}
						gap={"20px"}
						position={"relative"}
						justifyContent={"center"}>
						<Box height={"36px"} width={"2px"} bgcolor={"#A3A3A3"} />
						<Box
							width={"180px"}
							component={"input"}
							color={theme.colorSchemes[mode]?.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}
							fontFamily={"Montserrat"}
							ref={refInput}
							disabled={currentStep === 3}
							sx={{
								outline: "none",
								fontWeight: "inherit",
								backgroundColor: theme.colorSchemes[mode]?.bgHover,
								border: "none",
								borderRadius: "12px",
								padding: "0 35px 0 15px",
								cursor: "pointer",
								transition: "all 0.25s ease-in-out",
								height: "36px",
								"&:hover": {
									cursor: "text",
								},
								"&:focus": {
									width: "300px",
								},
							}}
							type="text"
							value={name}
							spellCheck="false"
							onClick={selectAllInlineText}
							onChange={handleGalleryTitleChange}
							onBlur={handleGalleryTitleBlur}
							onKeyDown={(
								e: React.KeyboardEvent<HTMLInputElement> &
									React.FocusEvent<HTMLInputElement>,
							) => e.key === "Enter" && e.target.blur()}
							onMouseDown={(e: React.MouseEvent<HTMLInputElement>) =>
								e.preventDefault()
							}
						/>
						{currentStep !== 3 && (
							<IconButton
								sx={{ position: "absolute", right: 0 }}
								onClick={() => refInput.current?.click()}>
								<Icons.EditName />
							</IconButton>
						)}
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					alignItems={"center"}
					component={"div"}
					onClick={() => {
						navigate(APP_ROOT.HISTORY_STYLE_HAIR);
						!isLoadingProcess && reset();
					}}
					justifyContent={"flex-end"}>
					<BoxCredit />
					<Box
						component={"button"}
						sx={{
							cursor: "pointer",
							outline: "none",
							border: "none",
							transition: "all 0.25s easy-in",
							":hover": {
								opacity: 0.8,
							},
						}}
						bgcolor={mainColor.bgButton}
						padding={"10px 20px"}
						borderRadius={"12px"}>
						<TextCus
							color={theme.colorSchemes[mode]?.textTitle}
							fontSize={"12px"}
							fontFamily={"Montserrat"}
							fontWeight={"500"}>
							Your History
						</TextCus>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Header;
