import { Box, CircularProgress, useTheme } from "@mui/material";
import { Icons } from "assets";
import { DownloadDropdown, TextCus } from "components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { mainColor } from "theme";
import BoxLoading from "./BoxLoading";
import { IFinalOutfitResult } from "types/outfit";
import useClickOutside from "hooks/useClickOutside";
import { useDownloadImage, useAnimateStore, useUser } from "hooks";
import { ThemeOption } from "types";

interface ProcessResultProps {
	initialImage?: string;
	isLoading?: boolean;
	valueProcess?: number;
	isStart?: boolean;
	handleSwitchItem?: () => void;
	processing?: IFinalOutfitResult | null | string;
	isLoadingHistory?: boolean;
	handleNavigateStyleFit?: () => void;
	handleNavigateStyleHair?: () => void;
	galleryId: string;
	packageName: string;
}
const BoxResult = ({
	initialImage,
	isLoading,
	valueProcess,
	isStart,
	isLoadingHistory,
	galleryId,
	packageName,
}: ProcessResultProps) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const { user, mode } = useUser();
	const theme = useTheme() as ThemeOption;
	const { name } = useAnimateStore();
	const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();

	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const [isLoadingVideo, setIsLoadingVideo] = useState(true);
	const handleLoadStart = useCallback(() => {
		setIsLoadingVideo(true);
	}, []);
	const handleCanPlay = useCallback(() => {
		setIsLoadingVideo(false);
	}, []);
	const handlePlaying = useCallback(() => {
		setIsLoadingVideo(false);
	}, []);
	const handleWaiting = useCallback(() => {
		setIsLoadingVideo(true);
	}, []);

	return (
		<Box
			bgcolor={theme.colorSchemes[mode]?.bgPhoto}
			width={"100%"}
			sx={{
				height: { xs: "100%", md: "95%" },
			}}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
			padding={"15px"}
			position={"relative"}
			borderRadius={"12px"}>
			<Box
				bgcolor={theme.colorSchemes[mode]?.bgPhoto}
				width={"100%"}
				zIndex={9999}
				height={"100%"}
				position={"absolute"}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				padding={"15px"}
				borderRadius={"12px"}>
				<TextCus
					fontWeight={"600"}
					fontSize={"14px"}
					marginBottom={"20px"}
					color={theme.colorSchemes[mode]?.textTitle}>
					Animate Anyone
				</TextCus>

				<Box
					height="calc(100% - 100px)"
					width={"100%"}
					flex={1}
					borderRadius={"12px"}
					bgcolor={theme.colorSchemes[mode]?.bgProcess}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					position={"relative"}
					gap={"30px"}>
					{previewUrl ? (
						<Box
							flex={1}
							display={"flex"}
							alignItems={"center"}
							flexDirection={"column"}
							justifyContent={"space-around"}
							maxWidth={"100%"}
							height={"100%"}>
							<Box
								margin={"0 auto"}
								width={"100%"}
								overflow={"hidden"}
								height={"calc(100% - 50px)"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<Box
									key={previewUrl}
									borderRadius={"12px"}
									sx={{ opacity: isPreviewVisible ? 1 : 0, objectFit: "cover" }}
									width={"100%"}
									height={"100%"}
									component={"video"}
									autoPlay={true}
									controls={false}
									onLoadStart={handleLoadStart}
									onCanPlay={handleCanPlay}
									onPlaying={handlePlaying}
									onWaiting={handleWaiting}
									loop
									muted>
									<source src={previewUrl} type="video/mp4" />
								</Box>
							</Box>
							{isLoadingVideo && (
								<Box
									borderRadius={"12px"}
									bgcolor={"rgba(0, 0, 0, 0.7)"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									position={"absolute"}
									top={"50%"}
									left={"50%"}
									sx={{ transform: "translate(-50%,-50%)" }}
									width={"100%"}
									height={"100%"}>
									<CircularProgress size={25} />
								</Box>
							)}

							{isLoading && (
								<Box
									position={"absolute"}
									top={0}
									left={0}
									zIndex={9}
									gap={"15px"}
									borderRadius={"12px"}
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
									width={"100%"}
									height={"100%"}
									bgcolor={mode === "light" ? "#e9e0e085" : "#1c191985"}>
									<BoxLoading
										valueProcess={valueProcess || 0}
										isStart={isStart}
										isLoadingHistory={isLoadingHistory}
									/>
								</Box>
							)}

							{user?.plan !== "FREE" ? (
								<Box
									position={"absolute"}
									top={"10px"}
									right={"10px"}
									onClick={() =>
										handleDownLoadImg(
											previewUrl,
											`${name}.mp4`,
											galleryId,
											packageName,
										)
									}
									bgcolor={mainColor.white}
									height={"36px"}
									width={"36px"}
									borderRadius={"10px"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									sx={{
										cursor: "pointer",
									}}>
									{isLoadingDlImg ? (
										<CircularProgress
											sx={{
												height: "30px !important",
												width: "30px !important",
											}}
										/>
									) : (
										<Icons.Download />
									)}
								</Box>
							) : (
								<DownloadDropdown
									dropdownRef={dropdownRef}
									imgDownload={previewUrl + ""}
									isOpen={isOpen}
									name={`${name}.mp4`}
									setIsOpen={setIsOpen}
									galleryId={galleryId}
									packageName={packageName}
								/>
							)}
						</Box>
					) : (
						<Box
							width={"100%"}
							flex={1}
							borderRadius={"12px"}
							bgcolor={theme.colorSchemes[mode]?.bgProcess}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							gap={"30px"}>
							{!isLoading ? (
								<>
									{Icons.Sparkers_2()}
									<TextCus
										fontWeight={"600"}
										fontSize={"14px"}
										color={theme.colorSchemes[mode]?.textTitle}>
										Your result will be shown here
									</TextCus>
								</>
							) : (
								<BoxLoading
									valueProcess={valueProcess || 0}
									isLoadingHistory={isLoadingHistory}
									isStart={isStart}
								/>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default BoxResult;
