import { Box, Skeleton } from "@mui/material";
import { useModel } from "hooks";
import TitleSection from "pages/admin/dashboard/components/TitleSection";
import React, { useCallback, useState } from "react";
import { TParams } from "types";
import ImageItem from "./ImageItem";
import {
	ButtonCus,
	ButtonSeeMore,
	ModalRequirements,
	ModalShowYourModel,
	ModalUpload,
	TextCus,
} from "components";
import { IModelType } from "types/outfit";
import { Upload } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { illustration } from "assets";
import { mainColor } from "theme";
import { arrNotShould, arrShould, KEY_CONTEXT } from "utils";

const YourModels = () => {
	const [open, setOpen] = useState(false);
	const [openDetail, setOpenDetail] = useState(false);
	const { useGetYourModels, deleteModelMutation } = useModel();
	const [dataResult, setDataResult] = useState<IModelType | null>(null);
	const [openModalRequire, setOpenModalRequire] = useState(false);

	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 12,
		type: "YOUR_BODY",
	});
	const {
		models: dataModel,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		refetch,
	} = useGetYourModels(paramsModels);

	const handleDelete = useCallback((id) => {
		deleteModelMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	// const handleChooseModel = useCallback((item: IModelType) => {
	// 	setSelectedFileModel(item.id as string);
	// 	setModelImage(item.image as string);
	// 	setSelectedFileGallery(null);
	// 	setCurrentStep(1);
	// 	navigate(APP_ROOT.STYLE_FIT);
	// }, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<Box
					width={"100%"}
					display={"flex"}
					gap={"10px"}
					mt={"20px"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}>
					{[...Array(6)].map((_, index) => {
						return (
							<Box
								display={"flex"}
								height={"198px"}
								width="calc((100% - 50px) / 6)"
								flexDirection={"column"}
								key={index}>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "12px",
										height: "198px",
										width: "100%",
									}}
								/>
							</Box>
						);
					})}
				</Box>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"200px"}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}
					flexDirection={"column"}>
					<LazyLoadImage src={illustration} alt="empty" />
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						flexDirection={"column"}>
						<TextCus color={mainColor.textMain}>
							Start by creating your own body shape
						</TextCus>
					</Box>
				</Box>
			);
		}
	}, [isLoading]);

	const renderItems = useCallback(() => {
		return dataModel.map((item) => (
			<Box width={"calc(100% / 6)"} key={item.id}>
				<ImageItem
					item={item}
					selectedImage=""
					actionDelete={handleDelete}
					handleClick={() => {
						setDataResult(item as IModelType);
						setOpenDetail(true);
					}}
					isLoading={deleteModelMutation.isPending}
				/>
			</Box>
		));
	}, [dataModel]);

	return (
		<Box maxWidth={"1156px"} margin={"0 auto"} width={"100%"} mt={"50px"}>
			<Box display={"flex"} alignItems={"center"}>
				<TitleSection title="Your Model" />
				<ButtonCus
					sx={{ gap: "5px" }}
					onClick={() => {
						localStorage.getItem(
							KEY_CONTEXT.MODAL_REQUIRE + 1 + location.pathname,
						)
							? setOpen(true)
							: setOpenModalRequire(true);
					}}>
					<Upload />
					<TextCus fontSize={"14px"} width={"60px"} fontWeight={"600"}>
						Upload
					</TextCus>
				</ButtonCus>
			</Box>

			{!dataModel?.length || isLoading ? (
				renderDataLoading()
			) : (
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{ transition: "all 0.25s ease-in-out" }}
					flexWrap={"wrap"}
					rowGap={"30px"}
					mt={"20px"}>
					{renderItems()}
				</Box>
			)}
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
			{open && (
				<ModalUpload
					open={open}
					onClose={() => setOpen(false)}
					handleRefetch={refetch}
				/>
			)}
			{openDetail && (
				<ModalShowYourModel
					onClose={() => setOpenDetail(false)}
					dataResult={dataResult}
					open={openDetail}
				/>
			)}
			{openModalRequire && (
				<ModalRequirements
					currentStep={1}
					open={openModalRequire}
					onClose={() => setOpenModalRequire(false)}
					handleClick={() => {
						setOpen(true);
						setOpenModalRequire(false);
					}}
					arrContentShould={arrShould}
					arrContentNotShould={arrNotShould}
				/>
			)}
		</Box>
	);
};

export default YourModels;
