/** @format */

import { Box, Checkbox, useTheme } from "@mui/material";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import React, { useCallback, useState } from "react";
import styles from "./styles";
import { InputOutLine } from "components/InputCus";
import { Icons, logo, logoDark } from "assets";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EModelAuth, FormRegister, ThemeOption } from "types";
import { registerSchema } from "utils";
import { useAuthentication, useLayout, useUser } from "hooks";
import SnackbarUtils from "utils/SnackbarUtils";

const Register = () => {
	const { setOpenModalAuth } = useLayout();
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormRegister>({
		resolver: yupResolver(registerSchema) as any,
		mode: "onChange",
	});

	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const [checked, setChecked] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [showCpw, setShowCpw] = useState(false);
	const navigateSignIn = useCallback(() => {
		setOpenModalAuth(EModelAuth.LOGIN);
	}, []);

	const { registerMutation } = useAuthentication();

	const handleClick = (data: FormRegister) => {
		if (!checked)
			return SnackbarUtils.warning(
				"Please confirm Privacy Policy and  Terms & Conditions!",
			);
		registerMutation.mutate(data);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowPcw = () => setShowCpw(!showCpw);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	return (
		<Box sx={styles.boxMain} width={"100%"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				width={"90%"}
				px={"15px"}
				flexDirection={"column"}
				mb={"20px"}>
				<img src={mode === "dark" ? logoDark : logo} width={"100px"} />
			</Box>

			<Box
				flex={0.5}
				width={"90%"}
				px={"20px"}
				component="form"
				onSubmit={handleSubmit(handleClick)}>
				<Box>
					<TextCus
						fontSize={"20px"}
						mb={2}
						fontWeight={"700"}
						color={theme.colorSchemes[mode]?.textMain}>
						Create an account
					</TextCus>
				</Box>

				<Box mt={1}>
					<Controller
						name="userName"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Name"
								value={field.value}
								setValue={field.onChange}
								error={!!errors.userName}
								errorMessage={errors?.userName?.message}
								fullWidth
							/>
						)}
					/>
				</Box>

				<Box mt={1}>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Email"
								value={field.value}
								setValue={field.onChange}
								error={!!errors.email}
								errorMessage={errors?.email?.message}
								fullWidth
							/>
						)}
					/>
				</Box>

				<Box mt={1}>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Password"
								type={showPassword ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.password}
								errorMessage={errors.password?.message}
								fullWidth
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
				</Box>

				<Box mt={1}>
					<Controller
						name="confirmPassword"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Confirm Password"
								type={showCpw ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.confirmPassword}
								errorMessage={errors.confirmPassword?.message}
								fullWidth
								icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPcw}
							/>
						)}
					/>
				</Box>
				<Box mt={1} display={"flex"} alignItems={"start"} ml={"-10px"}>
					<Checkbox
						sx={{
							color: "#FF75F3",
							"&.Mui-checked": {
								color: "#FF75F3",
							},
						}}
						checked={checked}
						onChange={handleChange}
						inputProps={{ "aria-label": "controlled" }}
					/>
					<Box component={"p"} color={theme.colorSchemes[mode]?.textMain}>
						By signing in to StyleGen, you agree to our{" "}
						<a
							style={{ color: "#FF75F3" }}
							target="blank"
							href="https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf">
							Terms & Conditions
						</a>{" "}
						and{" "}
						<a
							style={{ color: "#FF75F3" }}
							href="https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN.pdf"
							target="blank">
							Privacy Policy
						</a>
					</Box>
				</Box>

				<Box
					mt={3}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}>
					<ButtonCus
						title="Register"
						LabelProps={{ variant: "bodyBold" }}
						useI18n
						sx={{ width: "100%" }}
						variant={"text"}
						type="submit"
						isLoading={registerMutation.isPending}
					/>
				</Box>
				<Box display={"flex"} gap={"5px"} mt={"30px"} justifyContent={"center"}>
					<TextCus color={theme.colorSchemes[mode]?.textMain}>
						Already have an account?
					</TextCus>{" "}
					<Box
						component={"div"}
						onClick={navigateSignIn}
						sx={{ cursor: "pointer" }}
						className="text_linear"
						fontWeight={"bold"}>
						Sign in
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Register;
