/** @format */

import { ForgotForm, FormUpdatePassword } from "types";
import { END_POINT } from "utils";
import axiosClient from "./axios";

const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);
const getRequest = (url: string, conf: any = null) =>
	axiosClient.get(url, conf && conf);

export const userService = {
	getProfile: async () => {
		return await getRequest(END_POINT.USER.GET_PROFILE);
	},
	updateProfile: async (params) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		return await postRequest(END_POINT.USER.UPDATE_PROFILE, params, config);
	},
	forgotPwdGetOtpViaMail: async (formData: ForgotForm) => {
		return await postRequest(END_POINT.OTP.SEND_OTP, formData);
	},

	getOtpViaMail: async (email: string, type: string) => {
		return await postRequest(END_POINT.OTP.SEND_OTP, {
			email,
			type,
		});
	},

	updatePassword: async (dataForm: FormUpdatePassword) => {
		return await postRequest(END_POINT.USER.UPDATE_PASS, dataForm);
	},

	confirmTerm: async (isAgree: boolean) => {
		return await postRequest(END_POINT.USER.CONFIRM_TERM, { isAgree });
	},

	logout: async () => {
		return await postRequest(END_POINT.USER.LOGOUT, {});
	},
	getBlobImg: async (
		urlImg: string,
		galleryId: string,
		packageName: string,
	) => {
		return await getRequest(
			END_POINT.USER.GET_BLOB_IMG +
				`?path=${urlImg}&galleryId=${galleryId}&packageName=${packageName}`,
			{
				responseType: "arraybuffer",
			},
		);
	},
	trackingProcess: async (galleryId: string) => {
		return await getRequest(
			END_POINT.GALLERY.TRACKING_GALLERY + `?id=${galleryId}`,
		);
	},
	activeReferral: async (formData: {
		referralCode: string;
		uid: string;
		refSrc: string;
	}) => {
		return await postRequest(END_POINT.USER.ACTIVE_REFERRAL, formData);
	},
	getNotification: async (type: string) => {
		return await getRequest(END_POINT.USER.NOTIFICATION + `?type=${type}`);
	},
	trackingPlan: async (formData: {
		type: "CHECKOUT_PLAN" | "CANCEL_PLAN";
		packageName: string;
		quantity: string;
	}) => {
		return await postRequest(END_POINT.USER.TRACKING_PLAN, formData);
	},
};
