import { Box, IconButton } from "@mui/material";
import { Icons } from "assets";
import { ButtonCus, TextCus } from "components";
import React from "react";
import { mainColor } from "theme";

interface IBoxDialog {
	currentStep: number;
	setCurrentStep: (v: number) => void;
}

const BoxDialog = ({ currentStep, setCurrentStep }: IBoxDialog) => {
	return (
		<Box
			sx={{
				transition: "all 0.3s ease-in-out",
				opacity: currentStep === 3 ? 1 : 0,
			}}
			right={currentStep === 3 ? "20px" : "-100px"}
			borderRadius={"12px"}
			bottom={"20px"}
			overflow={"hidden"}
			width={"330px"}
			zIndex={9999}
			position={"absolute"}
			bgcolor={"white"}>
			<Box
				display={"flex"}
				padding={"15px"}
				sx={{
					transition: "all 0.3s ease-in-out",
					background: mainColor.bgLinear_3,
					opacity: currentStep === 3 ? 1 : 0,
				}}
				flexDirection={"column"}
				alignItems={"start"}
				gap={"20px"}
				justifyContent={"space-between"}>
				<Box
					display={"flex"}
					alignItems={"center"}
					width={"100%"}
					justifyContent={"space-between"}>
					<TextCus color={"#5553BA"} fontSize={"14px"} fontWeight={"600"}>
						Find your best-fit size
					</TextCus>
					<IconButton>
						<Icons.CloseDialog />
					</IconButton>
				</Box>
				<TextCus color={"#303030"} fontSize={"12px"} fontWeight={"400"}>
					Please enter your body measurements, we’ll recommend the perfect size
					for this outfit to you
				</TextCus>
				<ButtonCus
					sx={{
						flex: 1,
						mt: "10px",
						width: "100%",
						height: "33px !important",
						padding: "1px !important",
					}}
					onClick={() => setCurrentStep(4)}>
					<Box
						bgcolor={"white"}
						width={"100%"}
						borderRadius={"10px"}
						height={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"0 10px"}
						justifyContent={"space-between"}>
						<Box />
						<Box
							display={"flex"}
							alignItems={"center"}
							gap={"15px"}
							height={"33px"}
							className="text_linear"
							fontSize={"12px"}
							fontWeight={"600"}>
							Find now
						</Box>
						<Icons.NextDialog />
					</Box>
				</ButtonCus>
			</Box>
		</Box>
	);
};

export default BoxDialog;
