import dayjs from "dayjs";
import { TLine } from "types";
import { IDataColorHair } from "types/styleHair";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	selectedFileModel: File | string | null;
	selectedFileHair: IDataColorHair | null;
	selectedGallery: File | string | null;
	selectedFileGallery: string | null;
	valueSize: string;
	name: string;
	colorHair: IDataColorHair | null;

	currentStep: number;
	modelImage: string | null;
	imageHair: string | null;
	isLoadingProcess: boolean;
	linesRetouchHair: (TLine | undefined)[];
	modelImageMask: string | null;
	openModalPainting: boolean;
	binaryMask: File | null;
};

type Actions = {
	setSelectedFileModel: (fileModel: File | string | null) => void;
	setModelImage: (image: string | null) => void;
	setSelectedFileHair: (fileModel: IDataColorHair | null) => void;
	setImageHair: (image: string | null) => void;
	setSelectedGallery: (img: File | string | null) => void;
	setSelectedFileGallery: (fileModel: string | null) => void;
	reset: () => void;
	setCurrentStep: (v: number) => void;
	setValueSize: (v: string) => void;
	setIsLoadingProcess: (v: boolean) => void;
	setName: (v: string) => void;
	setColorHair: (v: IDataColorHair | null) => void;
	setLinesRetouchHair: (v: (TLine | undefined)[]) => void;
	setModelImageMask: (image: string | null) => void;
	setBinaryMask: (img: File | null) => void;
	resetMask: () => void;
	setOpenModalPainting: (v: boolean) => void;
};

const initialState: State = {
	selectedFileModel: null,
	selectedFileHair: null,
	modelImage: null,
	imageHair: null,
	selectedGallery: null,
	selectedFileGallery: null,
	currentStep: 0,
	valueSize: "full-length",
	isLoadingProcess: false,
	name: `StyleHair-${dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")}`,
	colorHair: null,
	linesRetouchHair: [],
	modelImageMask: null,
	openModalPainting: false,
	binaryMask: null,
};

export const useStyleHairStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setSelectedFileModel: (file) => set({ selectedFileModel: file }),
			setSelectedFileHair: (file) => set({ selectedFileHair: file }),
			setModelImage: (img) => set({ modelImage: img }),
			setImageHair: (img) => set({ imageHair: img }),
			setSelectedGallery: (img) => set({ selectedGallery: img }),
			setSelectedFileGallery: (file) => set({ selectedFileGallery: file }),
			setCurrentStep: (v) => set({ currentStep: v }),
			setValueSize: (v) => set({ valueSize: v }),
			setIsLoadingProcess: (v) => set({ isLoadingProcess: v }),
			setName: (v) => set({ name: v }),
			setColorHair: (v) => set({ colorHair: v }),
			setLinesRetouchHair: (v) => set({ linesRetouchHair: v }),
			setModelImageMask: (img) => set({ modelImageMask: img }),
			setBinaryMask: (img) => set({ binaryMask: img }),
			resetMask: () => set({ linesRetouchHair: [], modelImageMask: null }),
			setOpenModalPainting: (v) => set({ openModalPainting: v }),
			reset: () => {
				set(initialState);
			},
		}),
		{
			name: "StyleHairStore",
		},
	),
);
