/** @format */

import { createTheme } from "@mui/material";

const overrides = {
	typography: {
		fontFamily: `"Noto Sans", "Helvetica", "Arial", sans-serif`,
		h1: {
			fontSize: "48px",
		},
		h2: {
			fontSize: "32px",
		},
		h3: {
			fontSize: "26px",
		},
		h4: {
			fontSize: "24px",
		},
		h5: {
			fontSize: "20px",
		},
		h6: {
			fontSize: "18px",
		},
		small: {
			fontSize: "10px",
		},
		subtitle1: {
			fontSize: "14px",
			fontWeight: "600",
		},
		subtitle2: {
			fontSize: "16px",
			fontWeight: "600",
		},
		smallBold: {
			fontSize: "10px",
			fontWeight: "700",
		},
		smallSemiBold: {
			fontSize: "10px",
			fontWeight: "600",
		},
		body1: {
			fontSize: "12px",
		},
		bodyBold: {
			fontSize: "12px",
			fontWeight: "700",
		},
		bodySmallBold: {
			fontSize: "12px",
			fontWeight: "600",
		},
	},

	colorSchemes: {
		light: {
			bgMain: "#FFFFFF",
			textMain: "#131313",
			textGray: "#A3A3A3",
			textTitle: "#3E227C",
			borderGray: "#FFFFFF",
			colorIcon: "#3E227C",
			bgHover: "#EEEEEE",
			bgModal: "#FFFFFF",
			bgProcess: "#F2F2F7",
			bgPhoto: "#FFFFFF",
			bgPhotoActive: "#FFFFFF",

			// ============
			primary: "#F2F2F7",
			secondary: "#3E227C",
			warning: "#FFCC00",
			success: "#3CD4A0",
			info: "#9013FE",
			error: "#750606",
			grey: "#F5F5F5",
			mainBlack: "#000000",
			white: "#FFFFFF",
			// textGray: "#666666",
			borderColor: "#ADADAD",
			bgButton: "rgba(149, 119, 217, 0.2)",
			textSecondary: "#5553BA",
			bgLinear: "linear-gradient(90deg, #6ddbfe, #fe55f4)",
			bgLinear_2:
				"linear-gradient(90deg, rgba(109, 219, 254, 0.7),rgba(254, 85, 244, 0.7))",
			bgLinear_3:
				"linear-gradient(90deg, rgba(109, 219, 254, 0.2),rgba(254, 85, 244, 0.2))",
		},
		dark: {
			bgMain: "#131313",
			textMain: "#FFFFFF",
			textGray: "#A3A3A3",
			textTitle: "#FFFFFF",
			borderGray: "#666666",
			colorIcon: "#A2A0FC",
			bgHover: "#303030",
			bgModal: "rgba(48, 48, 48, 0.9)",
			bgProcess: "#131313",
			bgPhoto: "#000000",
			bgPhotoActive: "#303030",

			// ============

			primary: "#F2F2F7",
			secondary: "#3E227C",
			warning: "#FFCC00",
			success: "#3CD4A0",
			info: "#9013FE",
			error: "#750606",
			grey: "#F5F5F5",
			mainBlack: "#000000",
			white: "#FFFFFF",
			// textGray: "#666666",
			borderColor: "#ADADAD",
			bgButton: "rgba(149, 119, 217, 0.2)",
			textSecondary: "#5553BA",
			bgLinear: "linear-gradient(90deg, #6ddbfe, #fe55f4)",
			bgLinear_2:
				"linear-gradient(90deg, rgba(109, 219, 254, 0.7),rgba(254, 85, 244, 0.7))",
			bgLinear_3:
				"linear-gradient(90deg, rgba(109, 219, 254, 0.2),rgba(254, 85, 244, 0.2))",
		},
	},
};

const theme = createTheme({ ...overrides });

export default theme;
