/** @format */
import React, { useCallback, useState } from "react";
import { Box, Divider, Grid, Popover, useTheme } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize } from "theme/default";
import { Link, useNavigate } from "react-router-dom";
import { logo, logoDark } from "assets/images";
import { Icons } from "assets";
import {
	AvatarCus,
	BoxCredit,
	BoxTrial,
	ButtonCus,
	ModalProfile,
	ModalUpdatePassword,
	TextCus,
} from "components";
import ItemMenu from "./ItemMenu";
import { useAuth } from "contexts";
import {
	useAuthentication,
	useLayout,
	useUser,
	useWindowDimensions,
} from "hooks";
import { EModelAuth, ThemeOption } from "types";
import { arrMenu, LANGUAGES } from "utils";
import { useTranslation } from "react-i18next";
import { APP_ROOT } from "utils/routes";
import i18next from "i18next";

const Header = () => {
	const { isLogged } = useAuth();
	const { setOpenModalAuth } = useLayout();
	const { user, mode, setMode } = useUser();
	const { logoutMutation } = useAuthentication();
	const navigate = useNavigate();
	const { width } = useWindowDimensions();

	// =========== OPEN MODAL INFO =============
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { t } = useTranslation();

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const [openProfile, setOpenProfile] = useState(false);
	const [openUpdatePass, setOpenUpdatePass] = useState(false);
	const theme = useTheme() as ThemeOption;

	const handleToggleMode = useCallback(() => {
		const newMode = mode === "light" ? "dark" : "light";
		setMode(newMode);
		localStorage.setItem("theme", newMode);
	}, [mode]);

	const [isOpen, setIsOpen] = useState(false);
	const { setLanguages, languages } = useUser();

	const changeLanguage = useCallback((item) => {
		i18next.changeLanguage(item.value);
		setLanguages(item);
		setIsOpen(false);
	}, []);

	const renderLanguages = useCallback(() => {
		return LANGUAGES.map((language) => {
			const isActive = languages.value === language.value;
			return (
				<Box
					height={"44px"}
					width={"210px"}
					padding={"15px"}
					key={language.name}
					bgcolor={isActive ? "rgba(149, 119, 217, 0.2)" : "transparent"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					borderRadius={"12px"}
					sx={{ cursor: "pointer" }}
					onClick={() => changeLanguage(language)}>
					<Box display={"flex"} alignItems={"center"} gap={"10px"}>
						<img src={language.img} alt={language.name} />
						<TextCus
							color={theme.colorSchemes[mode]?.textMain}
							fontSize={"13px"}
							fontWeight={"400"}>
							{language.name}
						</TextCus>
					</Box>
					{isActive && Icons.Check(theme.colorSchemes[mode]?.textMain)}
				</Box>
			);
		});
	}, [languages.value, mode]);

	return (
		<Box
			display={location.pathname === APP_ROOT.MAINTAIN ? "none" : "flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"68px"}
			width={"100%"}
			borderBottom={`1px solid ${theme.colorSchemes[mode]?.bgHover}`}
			bgcolor={theme.colorSchemes[mode]?.bgMain}>
			<Grid
				container
				sx={{
					height: "100%",
					paddingX: {
						xs: "15px",
						lg: "40px",
					},
				}}
				width={"100%"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"start"}>
					<Box>
						<Link to={"/"}>
							<LazyLoadImage
								width={80}
								src={mode === "light" ? logo : logoDark}
								alt="logo"
								effect="blur"
							/>
						</Link>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					sx={{
						gap: {
							xs: "10px",
							md: "20px",
						},
					}}
					alignItems={"center"}
					justifyContent={"flex-end"}>
					{isLogged ? (
						<>
							{user && user?.daysRemaining > 0 ? (
								<BoxTrial />
							) : (
								<ButtonCus
									title="Upgrade plan"
									onClick={() => {
										navigate(APP_ROOT.PLAN);
									}}
								/>
							)}
							<BoxCredit />
							<Box
								component={"button"}
								display={"flex"}
								alignItems={"center"}
								bgcolor={"transparent"}
								border={"none"}
								sx={{ cursor: "pointer", gap: { xs: "10px", md: "20px" } }}
								onClick={handleClick}
								aria-describedby={id}
								aria-label="profile">
								<AvatarCus />
							</Box>
						</>
					) : (
						<>
							<ButtonCus
								sx={{ padding: "0px !important" }}
								onClick={() => setOpenModalAuth(EModelAuth.LOGIN)}>
								<Box
									bgcolor={"white"}
									width={"97%"}
									borderRadius={"10px"}
									height={"90%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}>
									<Box
										className="text_linear"
										fontSize={fontSize.xs}
										fontWeight={"600"}>
										{t("Login")}
									</Box>
								</Box>
							</ButtonCus>
							<ButtonCus
								onClick={() => setOpenModalAuth(EModelAuth.REGISTER)}
								title="Sign up"
							/>
						</>
					)}

					{/* ========== POPUP MENU ============ */}
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						sx={{
							top: "10px",
							".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
								borderRadius: "12px !important",
								bgcolor: theme.colorSchemes[mode]?.bgModal,
								backdropFilter: "blur(15px)",
								border: `1px solid ${theme.colorSchemes[mode]?.borderGray}`,
								overflow: "visible",
							},
						}}>
						<Box
							width={"252px"}
							paddingX={"15px"}
							paddingBottom={"10px"}
							paddingTop={"20px"}
							display={"flex"}
							flexDirection={"column"}
							gap={"10px"}
							borderRadius={"12px !important"}>
							<Box alignItems={"center"} gap={"10px"} display={"flex"}>
								<AvatarCus />
								<Box display={"flex"} flexDirection={"column"}>
									<TextCus
										fontSize={fontSize.sm}
										color={theme.colorSchemes[mode]?.textMain}
										className="res_text"
										fontWeight={"600"}>
										{user?.userName}
									</TextCus>
									<TextCus
										fontSize={fontSize.xs}
										color={theme.colorSchemes[mode]?.textGray}
										width={"70%"}
										fontWeight={"400"}
										className="res_text">
										{user?.email}
									</TextCus>
								</Box>
							</Box>
							<Divider sx={{ bgcolor: theme.colorSchemes[mode]?.bgHover }} />
							<ItemMenu
								icon={Icons.User(theme.colorSchemes[mode]?.textMain)}
								color={theme.colorSchemes[mode]?.textMain}
								title={"Your profile"}
								onAction={() => {
									handleClose();
									setOpenProfile(true);
								}}
							/>
							{width < 900 &&
								arrMenu.map((item) => (
									<ItemMenu
										key={item.text}
										icon={item.icon(theme.colorSchemes[mode]?.colorIcon)}
										title={item.text}
										onAction={() => {
											handleClose();
											if (!isLogged) return setOpenModalAuth(EModelAuth.LOGIN);
											navigate(item.link);
										}}
									/>
								))}
							<Divider sx={{ bgcolor: theme.colorSchemes[mode]?.bgHover }} />

							<Box
								component={"div"}
								onClick={handleToggleMode}
								sx={{
									cursor: "pointer",
									transition: "all 0.25s ease-in-out",
									":hover": {
										background: "rgba(149, 119, 217, 0.2)",
									},
								}}
								borderRadius={"10px"}
								display={"flex"}
								padding={"14px"}
								alignItems={"center"}
								justifyContent={"space-between"}
								gap={"15px"}>
								<Box display={"flex"} alignItems={"center"} gap={"15px"}>
									{Icons.Moon(theme.colorSchemes[mode]?.textMain)}
									<TextCus
										fontSize={fontSize.sm}
										color={theme.colorSchemes[mode]?.textMain}>
										Dark mode
									</TextCus>
								</Box>
								<Box
									position={"relative"}
									width={"46px"}
									height={"24px"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
									sx={{
										transition: "all 0.25s ease-in-out",
										cursor: "pointer",
									}}
									borderRadius={"20px"}
									bgcolor={mode === "dark" ? "#FF75F3" : "#B2B2B2"}>
									<Box
										width={"18px"}
										height={"18px"}
										textAlign={"center"}
										lineHeight={"37px"}
										position={"absolute"}
										borderRadius={"100px"}
										fontWeight={"bold"}
										bgcolor={"white"}
										top={"50%"}
										left={mode === "dark" ? "53%" : "5px"}
										sx={{
											transform: "translateY(-50%)",
											transition: "all 0.25s ease-in-out",
										}}
									/>
								</Box>
							</Box>

							<Box
								marginTop={"5px"}
								component={"div"}
								sx={{
									cursor: "pointer",
									transition: "all 0.25s ease-in-out",
									":hover": {
										background: "rgba(149, 119, 217, 0.2)",
									},
								}}
								borderRadius={"10px"}
								onMouseEnter={() => setIsOpen(true)}
								onMouseLeave={() => setIsOpen(false)}
								display={"flex"}
								padding={"14px"}
								alignItems={"center"}
								justifyContent={"space-between"}
								position={"relative"}
								gap={"15px"}>
								<Box display={"flex"} alignItems={"center"} gap={"15px"}>
									{Icons.Earth(theme.colorSchemes[mode]?.textMain)}
									<TextCus
										fontSize={fontSize.sm}
										color={theme.colorSchemes[mode]?.textMain}>
										Language
									</TextCus>
								</Box>
								<Box position={"relative"}>
									<img src={languages.img} alt="" />
								</Box>
								{/* ========== Modal language ========== */}
								<Box
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									position={"absolute"}
									zIndex={999}
									bgcolor={theme.colorSchemes[mode]?.bgModal}
									padding={"10px"}
									borderRadius={"12px"}
									boxShadow={"0 0 20px 0 rgba(115, 64, 216, 0.3)"}
									top={"20px"}
									left={"-250px"}
									sx={{
										opacity: isOpen ? 1 : 0,
										transition: "all 0.25s ease-in-out",
										backdropFilter: "blur(15px)",
									}}
									justifyContent={"center"}>
									{renderLanguages()}
								</Box>
							</Box>

							<ItemMenu
								icon={Icons.HelpCenter(theme.colorSchemes[mode]?.textMain)}
								color={theme.colorSchemes[mode]?.textMain}
								title={"Help center"}
								onAction={() => navigate(APP_ROOT.HELP_CENTER)}
							/>
							<Divider />
							<ItemMenu
								color={theme.colorSchemes[mode]?.textMain}
								icon={Icons.Logout(theme.colorSchemes[mode]?.textMain)}
								title={"Logout"}
								onAction={() => {
									logoutMutation.mutate();
									handleClose();
								}}
							/>
						</Box>
					</Popover>
				</Grid>
			</Grid>

			{openProfile && (
				<ModalProfile
					open={openProfile}
					onClose={() => setOpenProfile(false)}
					onOpenUpdatePass={() => setOpenUpdatePass(true)}
				/>
			)}
			{openUpdatePass && (
				<ModalUpdatePassword
					open={openUpdatePass}
					onClose={() => setOpenUpdatePass(false)}
				/>
			)}
		</Box>
	);
};

export default Header;
