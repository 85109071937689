import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import {
	Box,
	Checkbox,
	Fade,
	FormControlLabel,
	IconButton,
	Modal,
	useTheme,
} from "@mui/material";
import { TextCus } from "components/TextCus";
import { defaultColorTheme, fontSize } from "theme";
import { paymentService } from "services";
import { TFormCustom } from "types/payment";
import { useMutation } from "@tanstack/react-query";
import SnackbarUtils from "utils/SnackbarUtils";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customPlanSchema } from "utils";
import { InputOutLine } from "components/InputCus";
import { ButtonCus } from "components/ButtonCus";
import { useUser } from "hooks";
import { ThemeOption } from "types";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
}

const options = [
	"Style Fit",
	"Style Hair",
	"Gen Me",
	"Custom body shape",
	"Training custom dataset",
	"Integrate API",
];

const ModalSubmitInfo = ({ open, onClose }: IModalProfile) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<TFormCustom>({
		resolver: yupResolver(customPlanSchema) as any,
		defaultValues: {
			company: "",
			email: "",
			yourRole: "",
			firstName: "",
			lastName: "",
			message: "",
			country: "",
			phone: "",
		},
	});

	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const requestContactMutation = useMutation({
		mutationFn: (form: TFormCustom) => paymentService.requestContact(form),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Thank you!. We will respond to you!");
				onClose();
				return;
			}
			SnackbarUtils.warning(data?.message || "Request failed");
		},
		onError: () => {
			SnackbarUtils.warning("Request failed!");
		},
	});

	const onSubmit: SubmitHandler<TFormCustom> = (data) => {
		requestContactMutation.mutate(data);
		reset();
	};

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-center"}
					gap={"20px"}
					bgcolor={theme.colorSchemes[mode]?.bgMain}
					flexDirection={"column"}
					paddingX={"30px"}
					paddingY={"20px"}
					sx={{
						maxWidth: "766px",
						maxHeight: "80vh",
						height: "800px",
						overflowY: "auto",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus
							fontWeight={"700"}
							color={theme.colorSchemes[mode]?.textMain}
							fontSize={fontSize.sm}>
							Submit your information
						</TextCus>
						<IconButton onClick={onClose}>
							<CloseOutlined
								sx={{ color: theme.colorSchemes[mode]?.textMain }}
							/>
						</IconButton>
					</Box>
					<Box
						px={"15px"}
						component="form"
						width={"90%"}
						onSubmit={handleSubmit(onSubmit)}>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							gap={"10px"}
							width={"100%"}
							justifyContent={"space-between"}>
							<Box flex={1}>
								<Controller
									name="firstName"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="First name"
											value={field.value + ""}
											setValue={field.onChange}
											error={!!errors.firstName}
											errorMessage={errors?.firstName?.message}
											fullWidth
										/>
									)}
								/>
							</Box>
							<Box flex={1}>
								<Controller
									name="lastName"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="Last name"
											value={field.value + ""}
											setValue={field.onChange}
											error={!!errors.lastName}
											errorMessage={errors?.lastName?.message}
											fullWidth
										/>
									)}
								/>
							</Box>
						</Box>

						<Box mt={1}>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<InputOutLine
										type="text"
										label="Email"
										{...field}
										value={field.value}
										setValue={field.onChange}
										fullWidth
										error={!!errors.email}
										errorMessage={errors?.email?.message}
									/>
								)}
							/>
						</Box>
						<Box mt={1}>
							<Controller
								name="country"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Country"
										value={field.value + ""}
										setValue={field.onChange}
										error={!!errors.country}
										errorMessage={errors?.country?.message}
										fullWidth
									/>
								)}
							/>
						</Box>

						<Box
							mt={1}
							display={"flex"}
							alignItems={"flex-start"}
							gap={"10px"}
							width={"100%"}
							justifyContent={"space-between"}>
							<Box flex={1}>
								<Controller
									name="company"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="Company name"
											value={field.value + ""}
											setValue={field.onChange}
											error={!!errors.company}
											errorMessage={errors?.company?.message}
											fullWidth
										/>
									)}
								/>
							</Box>

							<Box flex={1}>
								<Controller
									name="phone"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="Phone number"
											value={field.value + ""}
											setValue={field.onChange}
											error={!!errors.phone}
											errorMessage={errors?.phone?.message}
											fullWidth
										/>
									)}
								/>
							</Box>
						</Box>

						<Box mt={1}>
							<Controller
								name="yourRole"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Role"
										value={field.value + ""}
										setValue={field.onChange}
										error={!!errors.yourRole}
										errorMessage={errors?.yourRole?.message}
										fullWidth
									/>
								)}
							/>
						</Box>

						<Box mt={1}>
							<TextCus
								fontSize={"13px"}
								fontWeight={"600"}
								color={theme.colorSchemes[mode]?.textMain}>
								What is the solution you are interested in?
							</TextCus>
							<Box display={"flex"} flexDirection={"column"}>
								{options.map((option, index) => (
									<Controller
										key={index}
										name="options"
										control={control}
										render={({ field: { value, onChange } }) => (
											<FormControlLabel
												sx={{ color: theme.colorSchemes[mode]?.textMain }}
												control={
													<Checkbox
														sx={{
															color: theme.colorSchemes[mode]?.textMain,
														}}
														checked={value?.includes(option)}
														onChange={(e) => {
															const newValues = e.target.checked
																? [...(value || ""), option]
																: value?.filter((v) => v !== option);
															onChange(newValues);
														}}
													/>
												}
												label={option}
											/>
										)}
									/>
								))}
							</Box>
							{errors?.options?.message && (
								<TextCus
									fontSize={"12px"}
									fontWeight={"400"}
									color={defaultColorTheme.warning}>
									{errors?.options?.message}
								</TextCus>
							)}
						</Box>

						<Box mt={1}>
							<TextCus
								fontSize={"13px"}
								fontWeight={"600"}
								color={theme.colorSchemes[mode]?.textMain}>
								Message
							</TextCus>
							<Controller
								name="message"
								control={control}
								render={({ field }) => (
									<Box
										mt={1}
										sx={{
											border: "1px solid rgb(204, 204, 204)",
											borderRadius: "12px",
											padding: "10px",
											fontFamily: "Montserrat",
										}}
										width={"100%"}
										rows={5}
										value={field.value}
										color={theme.colorSchemes[mode]?.textMain}
										bgcolor={theme.colorSchemes[mode]?.bgMain}
										fontSize={"12px"}
										placeholder="What would you like to know?"
										onChange={field.onChange}
										name="message"
										component={"textarea"}></Box>
								)}
							/>
							{errors?.message?.message && (
								<TextCus
									fontSize={"12px"}
									fontWeight={"400"}
									color={defaultColorTheme.warning}>
									{errors?.message?.message}
								</TextCus>
							)}
						</Box>

						<Box
							mt={3}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}>
							<ButtonCus
								title="Submit"
								LabelProps={{ variant: "bodyBold" }}
								useI18n
								sx={{ width: "100%" }}
								variant={"text"}
								type="submit"
								isLoading={requestContactMutation.isPending}
							/>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalSubmitInfo;
