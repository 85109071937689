import { Box, Skeleton, useTheme } from "@mui/material";
import { Icons } from "assets";
import { ButtonSeeMore, TextCus } from "components";
import { Banner } from "pages/models/components";
import React, { useCallback } from "react";
import { ItemProduct } from "./components";
import { fontSize, mainColor } from "theme";
import { useMerchant, useOutfitStore, useUser } from "hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { ICloth } from "types/merchant";
import { RuleFolder } from "@mui/icons-material";
import { ThemeOption } from "types";

const Shop = () => {
	const navigate = useNavigate();
	const { useGetClothByParams } = useMerchant();
	const [searchParams] = useSearchParams();
	const categoryId = searchParams.get("categoryId");
	const merchantId = searchParams.get("merchantId");
	const typeModel = searchParams.get("typeModel");
	const { clothes, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetClothByParams({
			page: 1,
			limit: 12,
			merchantId: merchantId + "",
			categoryId: categoryId + "",
			typeModel: typeModel + "",
		});

	const {
		setSelectedFileClothes,
		setImageClothes,
		setCurrentStep,
		setMerchantId,
		setTypeModel,
	} = useOutfitStore();

	const handleChooseItems = useCallback((item: ICloth) => {
		setSelectedFileClothes(item.id as string);
		setImageClothes(item.image as string);
		setCurrentStep(0);
		setMerchantId(item.merchantId);
		setTypeModel(item.typeModel);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);
	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<>
					<Box
						width={"100%"}
						gap={"16px"}
						rowGap={"30px"}
						display={"flex"}
						flexWrap={"wrap"}
						alignItems={"center"}>
						{[...Array(12)].map((_, index) => {
							return (
								<Skeleton
									key={index}
									variant="rectangular"
									sx={{
										transform: "unset",
										width: "calc((100% - 80px) / 6)",
										borderRadius: "12px",
										aspectRatio: "169 / 215",
										height: "auto",
									}}
								/>
							);
						})}
					</Box>
				</>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"190px"}
					component={"div"}
					textAlign={"center"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					color={"white"}>
					<RuleFolder
						style={{
							width: "60px",
							height: "60px",
							color: "#ccc",
						}}
					/>
					<TextCus color={mainColor.textGray} fontStyle={"italic"}>
						This item currently has no data.
					</TextCus>
				</Box>
			);
		}
	}, [isLoading]);

	return (
		<Box bgcolor={theme.colorSchemes[mode]?.bgProcess}>
			<Banner title="Zara's Collection" />
			<Box
				padding={"24px 15px"}
				width={"100%"}
				maxWidth={"1135px"}
				margin={"24px auto"}
				sx={{
					mt: {
						xs: "50px",
					},
				}}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<Box display={"flex"} alignItems={"center"} gap={"4px"}>
						<TextCus
							fontSize={fontSize.xs}
							color={theme.colorSchemes[mode]?.textTitle}>
							Woman /
						</TextCus>
						<TextCus
							fontSize={fontSize.xs}
							color={theme.colorSchemes[mode]?.textTitle}
							fontWeight={"700"}>
							Best Sellers
						</TextCus>
					</Box>
					<Box
						bgcolor={"#EEEEEE"}
						height={"32px"}
						display={"flex"}
						padding={"0 15px"}
						alignItems={"center"}
						gap={"10px"}
						borderRadius={"8px"}>
						<Icons.Find />
						<Box
							component={"input"}
							bgcolor={"inherit"}
							placeholder="Search"
							border={"none"}
							sx={{ outline: "none", fontSize: "12px", color: "#666666" }}
						/>
					</Box>
				</Box>
				<Box
					mt={"20px"}
					width={"100%"}
					gap={"16px"}
					rowGap={"30px"}
					display={"flex"}
					flexWrap={"wrap"}
					alignItems={"center"}>
					{!clothes?.length || isLoading ? (
						renderDataLoading()
					) : (
						<>
							{clothes.map((item) => {
								return (
									<ItemProduct
										key={item._id}
										item={item}
										handleChooseModel={handleChooseItems}
										color={theme.colorSchemes[mode]?.textTitle}
									/>
								);
							})}
						</>
					)}
				</Box>
				{hasNextPage && (
					<ButtonSeeMore
						fetchNextPage={fetchNextPage}
						isFetchingNextPage={isFetchingNextPage}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Shop;
