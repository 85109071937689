import { useCallback, useEffect, useState } from "react";
import { useLayout } from "./useLayout";
import { useUser } from "./useUser";
import { useAITools } from "./useAITools";
import { EnumStatusOutfit, IFinalOutfitResult } from "types/outfit";
import { IUser } from "types";
import { useSocket } from "contexts";
import { useStoreBodyShape } from "./useStoreBodyShape";
import { useProfile } from "./useProfile";
import { useTrackingProcess } from "./useTrackingProcess";
import { feetToCm } from "components/ModalGlobal/ModalCreateBodyShape";

export const useGenerateBodyShape = (cb: () => void) => {
	const { setIsConfirmTerm } = useLayout();
	const { setUser, user } = useUser();
	const { generateBodyShape } = useAITools();
	const { getProfile } = useProfile();
	const { socket } = useSocket();
	const [openModalFail, setOpenModalFail] = useState(false);
	const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
	const [processing, setProcessing] = useState<IFinalOutfitResult | null>(null);
	const { setIsTracking, dataTracking } = useTrackingProcess(
		processing?.galleryId as string,
	);
	const { formData, isLoadingProcess, setIsLoadingProcess, resetFormData } =
		useStoreBodyShape();

	const handelGenerate = useCallback(async () => {
		if (!user?.isAgree) return setIsConfirmTerm(true);
		if (user?.credits === 0) return setOpenModalNotCredit(true);
		setUser({ ...user, credits: (user?.credits || 0) - 1 } as IUser);
		setProcessing({
			...processing,
			currentStep: 0,
			totalStep: 0,
			status: EnumStatusOutfit.STARTED,
		});
		setIsLoadingProcess(true);

		const formDt = new FormData();
		formDt.append("fileModel", formData.fileModel as File);
		formDt.append("fileGenMe", formData.fileGenMe as File);
		formDt.append("typeModel", formData.typeModel);
		formDt.append("weight", formData.weight + "");
		formDt.append(
			"height",
			(formData.height < 7 ? feetToCm(formData.height) : formData.height) + "",
		);
		formDt.append("headSize", formData.headSize + "");
		formDt.append("neckLength", formData.neckLength + "");
		formDt.append("shoulders", formData.shoulders + "");
		formDt.append("arms", formData.arms + "");
		formDt.append("waistSize", formData.waistSize + "");
		formDt.append("hipSize", formData.hipSize + "");
		formDt.append("chestSize", formData.chestSize + "");
		formDt.append("legLength", formData.legLength + "");
		formDt.append("legWidth", formData.legWidth + "");
		formDt.append(
			"bmi",
			+(
				formData.weight /
				(((formData.height < 7 ? feetToCm(formData.height) : formData.height) /
					100) *
					((formData.height < 7 ? feetToCm(formData.height) : formData.height) /
						100))
			).toFixed(1) + "",
		);

		generateBodyShape(formDt as any, {
			onSuccess: async (res) => {
				if (res?.data?.statusCode === 400) {
					setIsLoadingProcess(false);
					setProcessing({
						...processing,
						status: EnumStatusOutfit.FAILURE,
					});
					if (res.data?.message === "BALANCE_NOT_ENOUGH") {
						setOpenModalNotCredit(true);
					} else {
						setOpenModalFail(true);
					}
				}
			},
			onError: () => {
				setOpenModalFail(true);
				setIsLoadingProcess(false);
				getProfile();
				setProcessing({
					...processing,
					status: EnumStatusOutfit.FAILURE,
				});
			},
		});
	}, [user, formData]);

	const handleActionStatus = useCallback(() => {
		setIsLoadingProcess(false);
		getProfile();
		resetFormData();
		cb?.();
	}, []);

	useEffect(() => {
		if (socket) {
			socket.on(`eventBodyShape-${user?.id}`, (data) => {
				setIsLoadingProcess(true);
				setIsTracking(true);
				setProcessing(data);
				if (data?.status === EnumStatusOutfit.SUCCESS) {
					handleActionStatus();
					setIsTracking(false);
				}
				if (data?.status === EnumStatusOutfit.FAILURE) {
					setIsLoadingProcess(false);
					setOpenModalFail(true);
					setIsTracking(false);
					getProfile();
				}
			});
			return () => {
				socket.off(`eventBodyShape-${user?.id}`);
			};
		}
	}, [socket, user]);

	return {
		handelGenerate,
		setOpenModalFail,
		setOpenModalNotCredit,
		isLoadingProcess,
		openModalFail,
		openModalNotCredit,
		dataTracking,
		processing,
	};
};
