import { Box, useTheme } from "@mui/material";
import { useUser } from "hooks";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fontSize, mainColor } from "theme";
import { ThemeOption } from "types";

interface ITabCus {
	setValueTab: (v: string) => void;
	arrTab: { title: string; value: string }[];
	indexActive?: number;
}

const TabCus = ({ setValueTab, arrTab, indexActive }: ITabCus) => {
	const [idx, setIdx] = useState(indexActive ?? 0);
	const { t } = useTranslation();
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<Box
			display={"flex"}
			position={"relative"}
			alignItems={"center"}
			borderBottom={`4px solid ${mode === "dark" ? "#666666" : "#EEEEEE"}`}
			justifyContent={"space-between"}>
			{arrTab?.map((item, index) => {
				return (
					<Box
						key={item.value + index}
						height={"35px"}
						sx={{ cursor: "pointer", transition: "all 0.25s ease-in-out" }}
						flex={1}
						className={index === idx ? "text_linear" : ""}
						component={"div"}
						onClick={() => {
							setValueTab(item.value);
							setIdx(index);
						}}
						fontSize={fontSize.xs}
						fontWeight={"400"}
						color={theme.colorSchemes[mode]?.textMain}
						textAlign={"center"}>
						{t(item.title)}
					</Box>
				);
			})}

			<Box
				left={`calc(100% / (${arrTab.length} / ${idx}))`}
				bottom={"-4px"}
				width={`calc(100% / ${arrTab.length})`}
				position={"absolute"}
				height={"4px"}
				sx={{
					background: mainColor.bgLinear,
					transition: "all 0.25s ease-in-out",
				}}
			/>
		</Box>
	);
};

export default memo(
	TabCus,
	(prev, next) =>
		prev.arrTab === next.arrTab && prev?.indexActive === next?.indexActive,
);
