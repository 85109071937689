import styled from "@emotion/styled";
import { Box, Slider } from "@mui/material";
import { TextCus } from "components/TextCus";
import React from "react";

const CustomSlider = styled(Slider)({
	color: "transparent",
	marginBottom: "0px",
	"& .MuiSlider-track": {
		background: "#9577D9",
		height: 8,
		borderRadius: 4,
	},
	"& .MuiSlider-rail": {
		backgroundColor: "#E8E7FE",
		height: 8,
		opacity: 1,
		borderRadius: 4,
	},
	"& .MuiSlider-thumb": {
		height: 16,
		width: 16,
		backgroundColor: "#FFFFFF",
		border: "1px solid #5B3E9B",
		boxShadow: "unset !important",
		"&:hover": {
			boxShadow: "unset !important",
		},
		"&:active": {
			boxShadow: "unset !important",
		},
	},
	"& .MuiSlider-mark": {
		width: "8px",
		height: "8px",
		borderRadius: "100px",
		backgroundColor: "rgba(20, 4, 56, 0.15)",
		transform: "translate(-8px, -50%)",
	},
	"& .MuiSlider-markActive": {
		backgroundColor: "transparent",
	},
	"& .MuiSlider-markLabel": {
		top: "-20px",
		transform: "translateX(-65%)",
	},
});

interface ISliderRange {
	setVSlider: (v: number) => void;
	valueDefault: number;
	maxValue: number;
	minValue: number;
	label: string;
	value: number;
	color: string;
}

const SliderRange = ({
	setVSlider,
	valueDefault,
	maxValue,
	minValue,
	label,
	value,
	color,
}: ISliderRange) => {
	const handleChange = (_: Event, newValue: number | number[]) => {
		if (typeof newValue === "number") {
			setVSlider(newValue);
		}
	};

	return (
		<Box>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}>
				<TextCus fontSize={"14px"} fontWeight={"400"} color={color} mb={"5px"}>
					{label}
				</TextCus>
				<TextCus fontSize={"14px"} fontWeight={"400"} color={color} mb={"5px"}>
					{value + ""}
				</TextCus>
			</Box>
			<CustomSlider
				defaultValue={valueDefault}
				value={value}
				min={minValue}
				onChange={handleChange}
				max={maxValue}
			/>
		</Box>
	);
};

export default SliderRange;
