import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { hairService } from "services";
import { TParams } from "types";
import { IModelParams } from "types/outfit";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useStyleHair = (inView?: boolean) => {
	const useGetHairColor = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.GET_CATEGORIES, params.page, params.type],
			queryFn: ({ pageParam = params.page || 1 }) =>
				hairService.getCategories({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const categories = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			categories,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetYourHair = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.YOUR_HAIR,
				params.typeModel,
				params.limit,
				params.type,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				hairService.getYourHairs({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
			enabled: inView,
		});

		const hairs = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			hairs,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const deleteHairMutation = useMutation({
		mutationFn: (hairStyleId: string) =>
			hairService.deleteYourHair(hairStyleId),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				return;
			}
			SnackbarUtils.warning(data?.message || "Delete failed");
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed!");
		},
	});

	const { mutate: generateHair, isPending: isPendingCreate } = useMutation({
		mutationFn: (form: IModelParams) => hairService.createYourHair(form),
	});

	return {
		useGetHairColor,
		deleteHairMutation,
		useGetYourHair,
		generateHair,
		isPendingCreate,
	};
};
