/** @format */
import React from "react";
import { Box, Skeleton, useTheme } from "@mui/material";
import { TextCus } from "components";
import { fontSize } from "theme";
import ItemMenu from "./ItemMenu";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "utils";
import { merchantService } from "services";
import { useSearchParams } from "react-router-dom";
import { ThemeOption } from "types";
import { useUser } from "hooks";

const Sidebar = () => {
	const [searchParams] = useSearchParams();
	const merchantId = searchParams.get("merchantId");
	const typeModel = searchParams.get("typeModel");

	const { data: dataCategory, isLoading } = useQuery({
		queryKey: [QueryKeys.GET_LIST_CATEGORY],
		queryFn: () => merchantService.getCategories(merchantId + ""),
	});

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				justifyContent={"center"}
				height={"100px"}>
				<TextCus
					paddingTop={"20px"}
					fontSize={fontSize.sm}
					color={theme.colorSchemes[mode]?.textTitle}
					fontWeight={"600"}>
					CATEGORY
				</TextCus>
			</Box>
			<Box
				display={"flex"}
				gap={"20px"}
				paddingX={"15px"}
				flexDirection={"column"}
				height={"calc(100vh - 200px)"}>
				{!dataCategory?.data?.length || isLoading ? (
					<>
						<Skeleton
							height={"40px"}
							sx={{ borderRadius: "10px", transform: "unset" }}
						/>
						<Skeleton
							height={"40px"}
							sx={{ borderRadius: "10px", transform: "unset" }}
						/>
						<Skeleton
							height={"40px"}
							sx={{ borderRadius: "10px", transform: "unset" }}
						/>
					</>
				) : (
					<Box sx={{ overflowY: "auto" }}>
						{dataCategory?.data?.map((item) => {
							const isActive = typeModel === item.name;
							return (
								<ItemMenu
									isActive={isActive}
									category={item}
									key={item?.name}
									mode={mode}
								/>
							);
						})}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Sidebar;
