import { Box, useTheme } from "@mui/material";
import {
	AnimateEn,
	AnimateKo,
	AnimateVi,
	GenMeEn,
	GenMeKo,
	GenMeVi,
	Icons,
	StyleFitEn,
	StyleFitKo,
	StyleFitVi,
} from "assets";
import { DialogVideo, TextCus } from "components";
import { useUser } from "hooks";
import { Banner } from "pages/models/components";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { fontSize } from "theme";
import { ThemeOption } from "types";

const HelpCenter = () => {
	const { mode, languages } = useUser();
	const theme = useTheme() as ThemeOption;

	const VIDEOS = useMemo(
		() => [
			{
				urlVideo:
					languages.value === "vi"
						? StyleFitVi
						: languages.value === "en"
						? StyleFitEn
						: StyleFitKo,
				title: "STYLE FIT",
				desc: "Your AI Virtual Outfit Try On",
			},
			{
				urlVideo:
					languages.value === "vi"
						? GenMeVi
						: languages.value === "en"
						? GenMeEn
						: GenMeKo,
				title: "GEN ME",
				desc: "Your Miniature AI Version",
			},
			{
				urlVideo:
					languages.value === "vi"
						? AnimateVi
						: languages.value === "en"
						? AnimateEn
						: AnimateKo,
				title: "ANIMATE ME",
				desc: "Make You In Motion",
			},
		],
		[languages.value],
	);

	return (
		<Box>
			<Banner title="Help center" desc="Let’s explore our features together!" />
			<Box maxWidth={"1058px"} padding={"0 15px"} margin={"0 auto"} mt={"90px"}>
				<TextCus
					mb={"20px"}
					color={theme.colorSchemes[mode]?.textMain}
					fontSize={fontSize.md}
					fontWeight={"700"}>
					Our features
				</TextCus>
				<Box
					alignItems={"center"}
					justifyContent={"center"}
					flexWrap={"wrap"}
					gap={"24px"}
					flexDirection={"row"}
					rowGap={"40px"}
					display={"flex"}>
					{VIDEOS.map((item) => {
						return (
							<VideoFeature
								theme={theme}
								mode={mode}
								item={item}
								key={item.title}
							/>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
};

export default HelpCenter;

const VideoFeature = ({
	item,
	theme,
	mode,
}: {
	item: {
		urlVideo: string;
		title: string;
		desc: string;
	};
	theme: any;
	mode: any;
}) => {
	const refVideo = useRef<HTMLVideoElement>();
	const [isOpen, setOpen] = useState(false);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);
	const handleOpenVideo = useCallback(() => {
		setOpen(true);
	}, []);

	return (
		<Box width={"calc((100% - 48px) / 3)"}>
			<Box position={"relative"}>
				<Box
					ref={refVideo}
					borderRadius={"12px"}
					sx={{ objectFit: "cover" }}
					width={"100%"}
					height={"auto"}
					component={"video"}
					autoPlay={false}
					controls={false}
					loop
					muted>
					<source src={item.urlVideo} type="video/mp4" />
				</Box>
				<Box
					position={"absolute"}
					top={0}
					onClick={handleOpenVideo}
					sx={{ cursor: "pointer" }}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					bgcolor={"rgba(0, 0, 0, 0.15)"}
					width={"100%"}
					borderRadius={"12px"}
					height={"100%"}>
					{Icons.Play()}
				</Box>
			</Box>
			<Box
				mt={"5px"}
				display={"flex"}
				justifyContent={"start"}
				gap={"5px"}
				flexDirection={"column"}>
				<TextCus
					fontWeight={"600"}
					fontSize={fontSize.xs}
					color={theme.colorSchemes[mode]?.textTitle}>
					{item.title}
				</TextCus>
				<TextCus
					fontWeight={"500"}
					sx={{
						fontSize: {
							xs: "11px",
							lg: "12px",
						},
					}}
					color={theme.colorSchemes[mode]?.textGray}>
					{item.desc}
				</TextCus>
			</Box>
			{isOpen && (
				<DialogVideo
					maxWidth="70vw"
					urlVideo={item?.urlVideo}
					title={item?.title}
					open={isOpen}
					handleClose={handleClose}
				/>
			)}
		</Box>
	);
};
