import React, { useCallback, useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, IconButton, useTheme } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { TextCus } from "components";
import { fontSize } from "theme";
import { IDataColorHair } from "types/styleHair";
import { Icons } from "assets";
import { useUser } from "hooks";
import { ThemeOption } from "types";

const HairColor = ({
	colors,
	colorHairChoose,
	setColor,
}: {
	colors: IDataColorHair[];
	colorHairChoose: IDataColorHair | null;
	setColor: (v: IDataColorHair | null) => void;
}) => {
	const ref = useRef<HTMLDivElement>();
	const [isNextHidden, setIsNextHidden] = useState(false);
	const [isPrevHidden, setIsPrevHidden] = useState(true);

	const scrollRight = useCallback((scrollOffset) => {
		ref?.current?.scrollBy({
			left: scrollOffset,
			behavior: "smooth",
		});
	}, []);

	const scrollLeft = useCallback((scrollOffset) => {
		ref?.current?.scrollBy({
			left: -scrollOffset,
			behavior: "smooth",
		});
	}, []);

	const checkScrollEnd = useCallback(() => {
		if (ref.current) {
			const { scrollLeft, clientWidth, scrollWidth } = ref.current;
			if (scrollLeft <= 5) {
				setIsPrevHidden(true);
			} else {
				setIsPrevHidden(false);
			}

			if (scrollLeft + clientWidth >= scrollWidth - 5) {
				setIsNextHidden(true);
			} else {
				setIsNextHidden(false);
			}
		}
	}, []);

	useEffect(() => {
		const scrollContainer = ref.current;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", checkScrollEnd);
		}
		return () => {
			if (scrollContainer) {
				scrollContainer.removeEventListener("scroll", checkScrollEnd);
			}
		};
	}, []);

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			gap={"5px"}
			width={"100%"}
			mb={"10px"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"3px"}
				justifyContent={"flex-start"}>
				<TextCus
					fontSize={fontSize.xs}
					fontWeight={"500"}
					color={theme.colorSchemes[mode]?.textTitle}>
					Hair color
				</TextCus>
				<TextCus
					fontSize={fontSize.xs}
					fontWeight={"400"}
					color={theme.colorSchemes[mode]?.textGray}>
					(Obligatory)
				</TextCus>
				<TextCus fontSize={fontSize.xs} fontWeight={"400"} color={"red"}>
					*
				</TextCus>
			</Box>
			<Box
				border={"1px solid #A3A3A3"}
				width={"100%"}
				height={"40px"}
				borderRadius={"12px"}
				padding={"0 15px"}
				color={theme.colorSchemes[mode]?.textMain}
				alignItems={"center"}
				display={"flex"}
				sx={{ outline: "none" }}>
				{colorHairChoose?.describe ?? (
					<TextCus color={theme.colorSchemes[mode]?.textGray}>
						Describe your hair color here (Optional)
					</TextCus>
				)}
			</Box>
			<Box
				position={"relative"}
				mt={"15px"}
				sx={{
					"::before": {
						content: isNextHidden ? "none" : '""',
						background: `linear-gradient(90deg, #fff0, ${
							mode === "dark" ? "#131313" : "#FFF"
						})`,
						position: "absolute",
						right: 0,
						top: 0,
						height: "100%",
						width: "70px",
						pointerEvents: "none",
						zIndex: 1,
					},
					"::after": {
						content: isPrevHidden ? "none" : '""',
						background: `linear-gradient(90deg, ${
							mode === "dark" ? "#131313" : "#FFF"
						}, #fff0)`,
						position: "absolute",
						left: 0,
						top: 0,
						height: "100%",
						width: "70px",
						pointerEvents: "none",
					},
				}}>
				<IconButton
					onClick={() => scrollLeft(200)}
					sx={{
						display: isPrevHidden ? "none" : "flex",
						position: "absolute",
						top: "50%",
						left: 0,
						transform: "translateY(-50%)",
						zIndex: 1,
					}}>
					<ArrowBackIos sx={{ color: theme.colorSchemes[mode]?.textMain }} />
				</IconButton>
				<Box
					ref={ref}
					component={"div"}
					gap={"5px"}
					className="box_color"
					display={"flex"}
					flexDirection={"column"}
					sx={{
						transition: "all 0.25s ease-in-out",
						overflowX: "auto",
					}}>
					<Box display={"flex"} gap={"10px"} paddingRight={"100px"}>
						{colors?.slice(0, 12).map((item) => {
							return (
								<BoxColor
									key={item?._id}
									item={item}
									colorHairChoose={colorHairChoose}
									setColor={setColor}
								/>
							);
						})}
					</Box>
					<Box display={"flex"} gap={"10px"} paddingRight={"100px"}>
						{colors?.slice(12, 24).map((item) => {
							return (
								<BoxColor
									key={item?._id}
									item={item}
									colorHairChoose={colorHairChoose}
									setColor={setColor}
								/>
							);
						})}
					</Box>
				</Box>
				<IconButton
					onClick={() => scrollRight(200)}
					sx={{
						display: isNextHidden ? "none" : "flex",
						position: "absolute",
						top: "50%",
						right: 0,
						transform: "translateY(-50%)",
						zIndex: 2,
					}}>
					<ArrowForwardIos sx={{ color: theme.colorSchemes[mode]?.textMain }} />
				</IconButton>
			</Box>
		</Box>
	);
};

export default HairColor;

const BoxColor = ({
	item,
	colorHairChoose,
	setColor,
}: {
	item: IDataColorHair;
	colorHairChoose: IDataColorHair | null;
	setColor: (v: IDataColorHair | null) => void;
}) => {
	return (
		<Box
			position={"relative"}
			minWidth={"50px"}
			height={"50px"}
			sx={{
				borderRadius: "32px",
				cursor: "pointer",
				border: `2px solid ${
					colorHairChoose?._id === item?._id ? "#3E227C" : "transparent"
				}`,
			}}
			onClick={() => setColor(item)}>
			<LazyLoadImage
				style={{ width: "100%", height: "100%", display: "block" }}
				src={item.image}
				alt="hairBlack"
				key={item._id}
			/>
			{colorHairChoose?._id === item?._id && (
				<IconButton
					onClick={(event) => {
						event.stopPropagation();
						setColor(null);
					}}
					sx={{
						position: "absolute",
						top: "-10px",
						right: "-10px",
					}}>
					<Icons.DeleteIcon />
				</IconButton>
			)}
		</Box>
	);
};
