/** @format */

import moment from "moment";
import tinycolor from "tinycolor2";
import simplify from "simplify-js";
import { IValueType } from "types";

/** @format */
import { alpha } from "@mui/material";

export const convertObjectToArr = (obj: any) =>
	Object.entries(obj).map((e) => {
		return {
			k: e[0],
			v: e[1],
		};
	});

export const sliceString = (string: string, pos = 20) => {
	if (string.length <= pos) return string;
	const stringArr = string.split("");
	stringArr.length = pos;
	return stringArr.join("") + "...";
};

export const convertObjectToObject = (obj: any, opts: any | null = null) => {
	const e = Object.entries(obj);
	return {
		k: opts?.key ? obj[opts?.key] : e[0][1],
		v: opts?.val ? obj[opts?.val] : e[1][1],
	};
};
export const checkIfDuplicateExists = (arr) => {
	return new Set(arr).size !== arr.length;
};

export const sortByKey = (data: any[], key: string) => {
	try {
		return data.sort((a, b) => (a?.[key] < b?.[key] ? -1 : 1));
	} catch (error) {
		return data;
	}
};

export const getIndexByClosedObj = (arr, obj) => {
	const index = arr.reduce(
		(acc, curr, currIndex) => {
			const keys = Object.keys(obj);
			const numMatches = keys.filter((key) => curr[key] === obj[key]).length;
			if (numMatches > acc.numMatches) {
				return { index: currIndex, numMatches };
			}
			return acc;
		},
		{ index: -1, numMatches: 0 },
	);
	return index.index;
};

export const removePrefixWithChar = (str: string, char: string) =>
	str.split(char)?.[1] ?? "";

export const convertObjectToDataset = (obj: any, opts: any | null = null) => {
	const e = Object.entries(obj);
	return {
		key: opts?.key ? obj[opts?.key] : e[0][0],
		title: opts?.val ? obj[opts?.val] : e[0][1],
		value: opts?.key ? obj[opts?.key] : e[0][1],
		useI18n: true,
	};
};

export const prepareFilter = (obj: any, operators?: any) => {
	try {
		return Object.entries(obj).map(([k, v]) => ({
			field: k,
			op: operators?.[k]
				? operators?.[k]
				: hasIncludeString(k, "date")
				? "eq"
				: "like",
			operator: operators?.[k]
				? operators?.[k]
				: hasIncludeString(k, "date")
				? "eq"
				: "like",
			property: k,
			type: hasIncludeString(k, "date") ? "date" : typeof v,
			value: hasIncludeString(k, "date") ? formatYMD(v as string) : v,
		}));
	} catch (error) {
		return [];
	}
};

export const convertMultiArrayToObj = (arr: any) =>
	arr.reduce((a, v) => ({ ...a, ...v }), {});

export const preventFPage = (p, input) => Math.min(p, input);
export const preventOPage = (p, input) => Math.max(p, input);
export const totalPage = (total, ipp) => Math.ceil(total / ipp);
export const calcPageSizeFit = (height) => Math.floor(height / 34) - 1;

export const getRelativePath = (fullPath: string, root: string): string =>
	fullPath.toLowerCase()?.replace(`/${root?.toLowerCase()}/`, "");

export const getCurrentModuleByLocation = (location: string) => {
	const pathArr = location?.split("/");
	return pathArr[pathArr?.length - 1];
};
export const splitPath = (path: string) => {
	const parsePath = path?.[0] === "/" ? path.substring(1) : path;
	return parsePath.split("/").filter((i) => i);
};

export const isAction = (act, key) => Number(act.search(key)) === 0;

export const removeItemNull = (arr: []) => arr.filter((i) => i);

export const getPathByIndex = (curIdx: number, fullPath: string) => {
	return fullPath
		.split("/")
		.filter((_, idx) => idx <= curIdx)
		.join("/");
};

export const getFileType = (fileName: string): string => {
	const filesplit = fileName.split(".");
	return filesplit[filesplit.length - 1];
};

export const areEqual = (prev, next) => {
	try {
		return JSON.stringify(prev) === JSON.stringify(next);
	} catch (error) {
		return false;
	}
};

export const getCurrentTS = () => {
	return moment().unix();
};

export const cbTime = (a, b) => {
	const rs = moment(a).isAfter(moment(b));
	return rs;
};

export const formatDMY = (str?: string) => {
	//if (!str) return moment().format('DD/MM/YYYY');
	if (!str) return "";
	return moment(str).format("DD/MM/YYYY");
};

export const formatDMYGrid = (str?: string) => {
	if (!str || typeof str != "string") return;
	return moment(str).format("DD/MM/YYYY");
};

export const formatYMD = (str?: string) => {
	// if (!str) return moment().format('YYYY-MM-DD');
	if (!str) return "";
	return moment(str).format("YYYY-MM-DD");
};

export const splitStrWithChar = (str: string, char: string, index: number) => {
	try {
		const val = str.split(char);
		return val[index] ?? str;
	} catch (error) {
		return str;
	}
};

export const checkExistByKey = (str: string) => {
	try {
		const regex = /\//g;
		return str.search(regex) > -1;
	} catch (error) {
		return true;
	}
};

export const regMatch = (str: string, regex: RegExp) => {
	if (!str) return null;

	return str.match(regex);
};

export const convertYMD = (str: string, char = "/") => {
	try {
		const arrStr = str.split(char);
		if (!arrStr[2]) {
			return formatYMD(str) ?? moment().format("YYYY-MM-DD");
		}
		return `${arrStr[2]}-${arrStr[1]}-${arrStr[0]}`;
	} catch (error) {
		return "";
	}
};

export function bgBlur(props) {
	const color = props?.color || "#000000";
	const blur = props?.blur || 6;
	const opacity = props?.opacity || 0.8;
	const imgUrl = props?.imgUrl;

	if (imgUrl) {
		return {
			position: "relative",
			backgroundImage: `url(${imgUrl})`,
			"&:before": {
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 9,
				content: '""',
				width: "100%",
				height: "100%",
				backdropFilter: `blur(${blur}px)`,
				WebkitBackdropFilter: `blur(${blur}px)`,
				backgroundColor: alpha(color, opacity),
			},
		};
	}

	return {
		backdropFilter: `blur(${blur}px)`,
		WebkitBackdropFilter: `blur(${blur}px)`,
		backgroundColor: alpha(color, opacity),
	};
}

export const convertColorLighten = (
	color?: tinycolor.ColorInput,
	amount?: number,
): string => {
	return tinycolor(color).lighten(amount).toHexString();
};

export const convertColorDarken = (
	color?: tinycolor.ColorInput,
	amount?: number,
): string => {
	return tinycolor(color).darken(amount).toHexString();
};

export const convertToBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
export const insertInArray = (
	array: Array<any>,
	index: number,
	obj: Array<any>,
) => {
	if (obj) {
		obj.map((elem, i) => {
			array.splice(index + i, 0, elem);
		});
	} else {
		array.splice(index, 0, obj);
	}
};

export const hadItemInArray = (array: Array<any>, item: any) =>
	array?.includes(item);

export const getItemFindIndex = (array: Array<any>, key: any) => {
	const index = array?.findIndex((el) => el.key === key);
	return array[index];
};

export const getItemsByKey = (array: Array<any>, keyCompare: any, key: any) =>
	Array.from(array)?.filter((el) => el[key] === keyCompare);

export const groupArrayByKey = (array: Array<any>, keyGroup: any) => {
	const result = array.reduce((acc, object) => {
		if (!acc[object[keyGroup]]) {
			acc[object[keyGroup]] = [];
		}
		acc[object[keyGroup]].push(object);
		return acc;
	}, {});
	return Object.keys(result).map((key) => {
		if (result[key].length > 1) {
			return {
				groupName: key.concat("(" + result[key].length + ")"),
				children: result[key],
			};
		} else {
			return { ...result[key] };
		}
	});
};

export const getIndexFromArray = (array: Array<any>, keyCompare: any) =>
	Array.from(array)?.findIndex((el) => el === keyCompare);

export const getIndexByKey = (array: Array<any>, keyCompare: any, key: any) => {
	try {
		return Array.from(array)?.findIndex((el) => el?.[key] === keyCompare);
	} catch (error) {
		return -1;
	}
};

export const filterOtherKey = (array: Array<any>, keyCompare: any, key: any) =>
	Array.from(array)?.filter((el) => el[key] !== keyCompare);

export const filterFileTmpByKey = (
	array: Array<any>,
	keyCompare: any,
	key: any,
) => Array.from(array)?.filter((el) => el[key] !== keyCompare).length;

export const getValueInArrayByIndex = (array: Array<any>, index: number) =>
	array?.[index];

export const getDataType = (value: any): IValueType =>
	Object.prototype.toString.call(value).slice(8, -1) as IValueType;

export const convertToJSON = (data: any) => {
	try {
		return JSON.stringify(data);
	} catch (error) {
		if (process.env.NODE_ENV !== "production") console.log(error);
	}
};

export const convertJSONToData = (data: any) => {
	try {
		return data && JSON.parse(data);
	} catch (error) {
		if (process.env.NODE_ENV !== "production") console.log(error);
	}
};

export const parseJSON = (data: string, out: any = null) => {
	try {
		const value = JSON.parse(data);
		return value;
	} catch (error) {
		return out;
	}
};

export const isFile = (str: string) => {
	try {
		const arr = str.split(".");
		if (arr.length > 1) return true;
		return false;
	} catch (error) {
		return false;
	}
};

export const toCapitalize = (str: string) =>
	`${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const sleep = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
export const base64ToUint8Array = (base64) => {
	const raw = atob(base64); //This is a native function that decodes a base64-encoded string.
	const uint8Array = new Uint8Array(new ArrayBuffer(raw.length));
	for (let i = 0; i < raw.length; i++) {
		uint8Array[i] = raw.charCodeAt(i);
	}
	return uint8Array;
};
export const convertBase64ToFile = (base64String, fileName) => {
	const bstr = atob(base64String);
	let n = bstr.length;
	const uint8Array = new Uint8Array(n);
	while (n--) {
		uint8Array[n] = bstr.charCodeAt(n);
	}
	const file = new File([uint8Array], fileName, { type: base64String });
	return file;
};

export const checkNumberInput = (event) => {
	if (!/[0-9]/.test(event.key)) {
		return event.preventDefault();
	}
};

/* flexible item width by 'its brothers' */
export const calFlexibleItemWidthByCount = (
	totalItem: number,
	spacingX: number,
	itemWidth = 306, //px
	itemSpacing = 30, //px
): number => {
	const countItem = Math.floor(
		(window.innerWidth - spacingX * 2 - itemSpacing) /
			(itemWidth + itemSpacing),
	);

	/* Minus spacing of the last item */
	const flexible = countItem * (itemWidth + itemSpacing) - itemSpacing;
	const maxByItems = (itemWidth + itemSpacing) * totalItem - itemSpacing;

	return flexible <= maxByItems ? flexible : maxByItems;
};

export const formatDBYMDHMS = (str: string) =>
	moment(str).format("YYYY-MM-DD HH:mm:ss");
export const cleanEmptyPrp = (obj: any) => {
	for (const propName in obj) {
		if (obj[propName] === "") {
			delete obj[propName];
		}
	}
	return obj;
};

export const sliceTwoParts = (arr, n: number) => {
	let first = [];
	let second = [];
	if (arr?.length < n) {
		first = arr;
	} else {
		first = arr.slice(0, n);
		second = arr.slice(n);
	}
	return {
		first,
		second,
	};
};

export const hasIncludeString = (str: string, key: string) => {
	return str?.includes(key);
};
export const formatDDMMYY = (str?: string) => {
	if (typeof str !== "string" || typeof str === undefined) return str;
	return moment(str).format("DD/MM/YYYY");
};

export const filterByCondition = (array, filterObj) => {
	return array.filter((row) => {
		for (const field in filterObj) {
			const val = row[field];
			if (val?.includes(filterObj[field])) return true;
		}
		return false;
	});
};

export const checkIndexOfExists = (str: string, key: string) =>
	str.indexOf(key) !== -1;

export const checkExistsOfArray = (
	arr: any[],
	keyCompare: string,
	key: string,
) => getIndexByKey(arr, keyCompare, key) !== -1;

export const formatMoney = (amount: number) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(amount);
};

export const convertMaskToBinaryAndSubmit = async (
	base64: string,
	width: number,
	height: number,
) => {
	if (!base64) return;

	// Create an Image object
	const image = new Image();
	image.crossOrigin = "anonymous";
	image.src = base64;

	console.log("================>", width, height);

	return new Promise<File>((resolve, reject) => {
		image.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			if (!ctx) return reject("Canvas context could not be created");
			canvas.width = width;
			canvas.height = height;

			ctx.drawImage(image, 0, 0);

			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const { data } = imageData;

			for (let i = 0; i < data.length; i += 4) {
				const r = data[i];
				const g = data[i + 1];
				const b = data[i + 2];

				if (r === 255 && g === 0 && b === 0) {
					data[i] = 255;
					data[i + 1] = 255;
					data[i + 2] = 255;
				} else {
					data[i] = 0;
					data[i + 1] = 0;
					data[i + 2] = 0;
				}
			}

			ctx.putImageData(imageData, 0, 0);
			canvas.toBlob((blob) => {
				if (!blob) return reject("Blob creation failed");
				const file = new File([blob], `${Date.now()}.png`, {
					type: "image/png",
				});
				resolve(file);
			}, "image/png");
		};

		image.onerror = (err) => reject(err);
	});
};

export const simplifyPoints = (points) => {
	const pointObjects = [];
	for (let i = 0; i < points.length; i += 2) {
		pointObjects.push({ x: points[i], y: points[i + 1] });
	}
	const simplified = simplify(pointObjects, 1.0);
	return simplified.flatMap(({ x, y }) => [x, y]);
};

export const convertMaskToPoint = (maskUrl, scale = 0.9) => {
	if (!maskUrl) return;
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = "Anonymous";
		img.src = maskUrl;

		img.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = img.width;
			canvas.height = img.height;

			// Draw the image on the canvas
			ctx.drawImage(img, 0, 0);

			// Get pixel data
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const { data, width, height } = imageData;

			const points = [];
			const isRedPixel = (r, g, b, a) => r > 100 && g < 100 && b < 100 && a > 0;

			// Traverse pixel data to find boundary points
			for (let y = 1; y < height - 1; y++) {
				for (let x = 1; x < width - 1; x++) {
					const index = (y * width + x) * 4;

					const r = data[index];
					const g = data[index + 1];
					const b = data[index + 2];
					const a = data[index + 3];

					if (isRedPixel(r, g, b, a)) {
						// Check if the pixel is on the boundary
						const neighbors = [
							data[((y - 1) * width + x) * 4], // Top
							data[((y + 1) * width + x) * 4], // Bottom
							data[(y * width + (x - 1)) * 4], // Left
							data[(y * width + (x + 1)) * 4], // Right
						];

						if (
							neighbors.some((neighborR) => !isRedPixel(neighborR, 0, 0, 255))
						) {
							points.push([x, y]); // Store points as [x, y] pairs
						}
					}
				}
			}
			// Sort the points to form a continuous boundary
			const sortedPoints = sortPointsClockwise(points);
			console.log("sortedPoints", scale);
			resolve(simplifyPoints(sortedPoints.flatMap(([x, y]) => [x, y])));
		};

		img.onerror = (error) => {
			console.error("Error converting mask to boundary points:", error);
			reject(error);
		};
	});
};

function sortPointsClockwise(points) {
	const center = points.reduce(
		(acc, [x, y]) => [acc[0] + x / points.length, acc[1] + y / points.length],
		[0, 0],
	);
	return points.sort(([x1, y1], [x2, y2]) => {
		const angle1 = Math.atan2(y1 - center[1], x1 - center[0]);
		const angle2 = Math.atan2(y2 - center[1], x2 - center[0]);
		return angle1 - angle2;
	});
}

export function getImageDimensions(url) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			resolve({ width: img.width, height: img.height });
		};
		img.onerror = (err) => {
			console.log("err", err);
			reject(new Error("Failed to load image"));
		};
		img.src = url;
	});
}
