import { Box } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import React from "react";

interface ButtonPaintProps {
	isPainting: any;
	currentStep: number;
	fileBinary: File | string;
	setOpenModalPainting: (v: boolean) => void;
	color?: any;
}

const ButtonPaint = ({
	isPainting,
	// currentStep,
	fileBinary,
	setOpenModalPainting,
	color,
}: ButtonPaintProps) => {
	return (
		<Box
			bgcolor={
				isPainting ? "rgba(149, 119, 217, 0.2)" : "rgba(178, 178, 178, 0.5)"
			}
			display={"flex"}
			position={"relative"}
			sx={{
				cursor: "pointer",
				// pointerEvents: currentStep > 2 ? "none" : "auto",
			}}
			alignItems={"center"}
			height={"24px"}
			onClick={() => isPainting && setOpenModalPainting(true)}
			justifyContent={"center"}
			borderRadius={"12px"}
			padding={"5px 10px"}
			mt={"-12px"}
			gap={"10px"}>
			{Icons.PenArt(isPainting ? color?.colorIcon : color?.textMain)}
			<TextCus color={color?.textMain}>Retouch item</TextCus>
			{fileBinary && (
				<Box position={"absolute"} top={2} right={-23}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 24 24"
						fill="none">
						<path
							d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
							stroke="#34C759"
							strokeWidth="2"></path>
						<path
							d="M7.5 11.5L10.206 14.5926C10.6185 15.064 11.3579 15.0445 11.7449 14.5519L16.5 8.5"
							stroke="#34C759"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"></path>
					</svg>
				</Box>
			)}
		</Box>
	);
};

export default ButtonPaint;
