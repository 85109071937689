import React from "react";
import { LinearProgress, Box, styled } from "@mui/material";

const GradientProgressBar = styled(LinearProgress)(() => ({
	height: 10,
	borderRadius: 5,
	transition: "all 0.3s ease-in-out",
	backgroundColor: "rgba(59, 56, 153, 0.35)",
	"& .MuiLinearProgress-bar": {
		borderRadius: 5,
		background: "linear-gradient(45deg, #6ddbfe, #fe55f4)",
	},
}));

export default function LoadingBar({ value }: { value: number }) {
	return (
		<Box sx={{ width: "100%" }}>
			<GradientProgressBar variant="determinate" value={value} />
		</Box>
	);
}
