/** @format */

import React, { useState } from "react";

import { Icons } from "assets";
import { mainColor } from "theme/default";
import { Box, Card, Modal } from "@mui/material";
import { ButtonCus, TextCus } from "components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormUpdatePassword } from "types";
import { InputOutLine } from "components/InputCus";
import { updatePassSchema } from "utils";
import { useMutation } from "@tanstack/react-query";
import { userService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

interface IModalUpdatePassword {
	open: boolean;
	onClose: () => void;
}

const ModalUpdatePassword = ({ open, onClose }: IModalUpdatePassword) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<FormUpdatePassword>({
		resolver: yupResolver(updatePassSchema) as any,
		defaultValues: {
			oldPassword: "",
			newPassword: "",
		},
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showCpw, setShowCpw] = useState(false);

	const updatePassMutation = useMutation({
		mutationFn: (form: FormUpdatePassword) => userService.updatePassword(form),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Update success!");
				onClose();
				return;
			}
			SnackbarUtils.warning(data?.message || "Update failed");
		},
		onError: () => {
			SnackbarUtils.warning("Update failed!");
		},
	});

	const onSubmit: SubmitHandler<FormUpdatePassword> = (data) => {
		updatePassMutation.mutate(data);
		reset();
	};
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowPcw = () => setShowCpw(!showCpw);

	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Card
				sx={{
					width: 500,
					height: 400,
					margin: "0 auto",
					my: 5,
					border: `1px solid ${mainColor.secondary}`,
					backgroundColor: "#ffffff",
					borderRadius: "10px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Box
					flex={1}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"100px"}
					flexDirection={"column"}
					width={"100%"}>
					<Box
						flex={0.5}
						width={"90%"}
						px={"15px"}
						component="form"
						onSubmit={handleSubmit(onSubmit)}>
						<Box>
							<TextCus
								fontSize={"20px"}
								mb={4}
								fontWeight={"700"}
								color={"#333333"}>
								Create a secure new password.
							</TextCus>
							<Controller
								name="oldPassword"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Old password"
										type={showCpw ? "text" : "password"}
										value={field.value}
										setValue={field.onChange}
										error={!!errors.oldPassword}
										errorMessage={errors.oldPassword?.message}
										fullWidth
										sx={{
											mb: 2.5,
										}}
										icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
										onClickIcon={handleClickShowPcw}
									/>
								)}
							/>
						</Box>
						<Box mt={2}>
							<Controller
								name="newPassword"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="New password"
										{...field}
										value={field.value}
										setValue={field.onChange}
										fullWidth
										error={!!errors.newPassword}
										type={showPassword ? "text" : "password"}
										errorMessage={errors?.newPassword?.message}
										icon={
											<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>
										}
										onClickIcon={handleClickShowPassword}
									/>
								)}
							/>

							<Box
								mt={5}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<ButtonCus
									title="Save password"
									LabelProps={{ variant: "bodyBold" }}
									useI18n
									sx={{ width: "100%" }}
									variant={"text"}
									type="submit"
									disabled={!isValid || isSubmitting}
									isLoading={updatePassMutation.isPending}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Card>
		</Modal>
	);
};

export default ModalUpdatePassword;
