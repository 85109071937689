import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "utils";
import { merchantService } from "services";
import { TParams } from "types";

export const useMerchant = () => {
	const useGetClothByType = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.GET_CLOTHES_BY_TYPE,
				params.typeModel,
				params.limit,
				params.type,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				merchantService.getClothesByType({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});
		const clothes = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			clothes,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetClothByParams = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.GET_CLOTHES_BY_PARAMS,
				params.typeModel,
				params.limit,
				params.type,
				params.merchantId,
				params.categoryId,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				merchantService.getClothesByParams({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});
		const clothes = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			clothes,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	return {
		useGetClothByType,
		useGetClothByParams,
	};
};
