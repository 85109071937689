/** @format */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { BoxPhoto, BoxResult } from "./components";
import {
	ModalNotBalance,
	ModalProcessFail,
	ModalWarningProcess,
	StepperCus,
} from "components";
import { EDropType, EnumStatusOutfit, IFinalOutfitResult } from "types/outfit";
import {
	useAITools,
	useLayout,
	useModel,
	useOutfitStore,
	useProfile,
	useAnimateStore,
	useUser,
	useStyleHairStore,
	useTrackingProcess,
	useGenMeStore,
} from "hooks";
import { animate, Icons } from "assets";
import { useSocket } from "contexts";
import { IUser, StatusRequest, ThemeOption, TypeServiceName } from "types";
import { IAnimateParams } from "types/animate";
import { ButtonAction, ButtonGenerate } from "../stylefit/components";
import SnackbarUtils from "utils/SnackbarUtils";

const Animate = () => {
	const {
		modelImage,
		videoAnimate,
		currentStep,
		selectedFileVideo,
		selectedFileModel,
		selectedFileGallery,
		setCurrentStep,
		name,
		setIsLoadingProcess,
		isLoadingProcess,
		reset,
	} = useAnimateStore();
	const { setIsConfirmTerm } = useLayout();
	const { setUser, user, mode } = useUser();
	const theme = useTheme() as ThemeOption;
	const { getProfile } = useProfile();
	const { generateAnimate } = useAITools();
	const { socket } = useSocket();
	const [openModalFail, setOpenModalFail] = useState(false);
	const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
	const [openModalWarningProcess, setOpenModalWarningProcess] = useState(false);
	const [processing, setProcessing] = useState<IFinalOutfitResult | null>(null);
	const { useGetGalleryList } = useModel();
	const { setIsTracking, dataTracking } = useTrackingProcess(
		processing?.galleryId as string,
	);
	const { refetch } = useGetGalleryList({
		page: 1,
		limit: 3,
		type: TypeServiceName.GEN_ME,
	});

	const arrButton = useMemo(
		() => [
			{
				icon: Icons.Reset(theme.colorSchemes[mode]?.textMain),
				title: "Reset",
				action: () => {
					reset();
					setProcessing(null);
				},
			},
			{
				icon: Icons.Undo(theme.colorSchemes[mode]?.textMain),
				title: "Prev",
				action: () => currentStep > 0 && setCurrentStep(currentStep - 1),
			},
			{
				icon: Icons.Redo(theme.colorSchemes[mode]?.textMain),
				title: "Next",
				action: () => currentStep < 2 && setCurrentStep(currentStep + 1),
			},
		],
		[currentStep],
	);

	const { isLoadingProcess: isLoadingProcessHair } = useStyleHairStore();
	const { isLoadingProcess: isLoadingProcessFit } = useOutfitStore();
	const { isLoadingProcess: isLoadingProcessGenMe } = useGenMeStore();

	const handelGenerate = useCallback(async () => {
		return SnackbarUtils.warning("Feature under maintenance.");
		if (!user?.isAgree) return setIsConfirmTerm(true);
		if (user?.credits <= 4) return setOpenModalNotCredit(true);
		if (isLoadingProcessHair || isLoadingProcessFit || isLoadingProcessGenMe)
			return setOpenModalWarningProcess(true);
		setUser({ ...user, credits: (user?.credits || 0) - 5 } as IUser);
		setCurrentStep(2);
		setProcessing({
			...processing,
			currentStep: 0,
			totalStep: 0,
			status: EnumStatusOutfit.STARTED,
		});
		setIsLoadingProcess(true);

		const formData = new FormData();
		formData.append("name", name + "");
		if (typeof selectedFileVideo === "string") {
			formData.append("videoId", selectedFileVideo);
		}
		if (typeof selectedFileModel === "string") {
			formData.append("modelBodyId", selectedFileModel);
		} else if (typeof selectedFileGallery === "string") {
			formData.append("galleryId", selectedFileGallery);
		} else {
			formData.append("fileModel", selectedFileModel as File);
		}

		generateAnimate(formData as IAnimateParams, {
			onSuccess: async (res) => {
				if (res?.data?.statusCode === 400) {
					setIsLoadingProcess(false);
					setProcessing({
						...processing,
						status: EnumStatusOutfit.FAILURE,
					});
					if (res.data?.message === "BALANCE_NOT_ENOUGH") {
						setOpenModalNotCredit(true);
					} else {
						setOpenModalFail(true);
					}
				}
			},
			onError: () => {
				setOpenModalFail(true);
				setIsLoadingProcess(false);
				getProfile();
				setProcessing({
					...processing,
					status: EnumStatusOutfit.FAILURE,
				});
			},
		});
	}, [
		selectedFileModel,
		selectedFileVideo,
		selectedFileGallery,
		name,
		user?.credits,
		user?.isAgree,
	]);

	const handleActionStatus = useCallback(() => {
		setIsLoadingProcess(false);
		setCurrentStep(3);
		getProfile();
		refetch();
	}, []);

	useEffect(() => {
		if (socket) {
			socket.on(`eventAnimation-${user?.id}`, (data) => {
				setIsLoadingProcess(true);
				setIsTracking(true);
				setProcessing(data);
				if (data?.status === EnumStatusOutfit.SUCCESS) {
					handleActionStatus();
					setIsTracking(false);
				}
				if (data?.status === EnumStatusOutfit.FAILURE) {
					setIsLoadingProcess(false);
					setOpenModalFail(true);
					setIsTracking(false);
					getProfile();
				}
			});
			return () => {
				socket.off(`eventAnimation-${user?.id}`);
			};
		}
	}, [socket, user?.id]);

	const renderBtnAction = useCallback(() => {
		return arrButton.map((item) => (
			<ButtonAction
				icon={item.icon}
				text={item.title}
				action={item.action}
				key={item.title}
				theme={theme.colorSchemes[mode]}
			/>
		));
	}, [arrButton]);

	return (
		<Box height={"100%"} bgcolor={theme.colorSchemes[mode]?.bgProcess}>
			<Box
				height={"100px"}
				sx={{
					height: {
						xs: "120px",
						xl: "150px",
					},
				}}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}>
				<StepperCus
					currentStep={currentStep}
					steps={[
						"Upload Your Photo",
						"Choose Pose",
						"Generate",
						"See Your Result",
					]}
				/>
			</Box>
			<Box
				width={"90%"}
				margin={"auto"}
				sx={{
					height: { xs: "calc(100vh - 198px)", xl: "calc(100vh - 228px)" },
				}}>
				<Grid
					container
					sx={{
						maxWidth: {
							xs: "823px",
							xl: "1200px",
						},
						margin: "auto",
					}}
					spacing={2}
					height={"100%"}>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
							height: {
								xs: "170px",
								md: "100%",
							},
						}}>
						<Box
							display={"flex"}
							gap={"20px"}
							sx={{
								flexDirection: { xs: "row", md: "column" },
								height: {
									xs: "unset",
									md: "95%",
								},
							}}
							justifyContent={"center"}
							alignItems={"center"}>
							<BoxPhoto
								dropType={EDropType.BODY}
								title="Your Photo"
								description="Human photo will be shown here"
								initialImage={modelImage as string}
								active={currentStep === 0}
								currentStep={1}
								action={() => setCurrentStep(0)}
							/>
							<BoxPhoto
								dropType={EDropType.OUTFIT}
								title="Chosen Pose"
								description="Chosen pose will be shown here"
								active={currentStep === 1}
								initialImage={videoAnimate as string}
								currentStep={2}
								action={() => setCurrentStep(1)}
								imgDf={animate}
							/>
							<Box
								display={"flex"}
								flexDirection={"column"}
								gap={"20px"}
								alignItems={"center"}
								width={"100%"}
								justifyContent={"center"}>
								<ButtonGenerate
									handelGenerate={handelGenerate}
									disabled={
										(!selectedFileGallery && !selectedFileModel) ||
										!selectedFileVideo ||
										isLoadingProcess
									}
									currentStep={3}
									active={currentStep === 2}
								/>
								<Box
									display={"flex"}
									width={"100%"}
									alignItems={"center"}
									gap={"5px"}
									justifyContent={"space-between"}>
									{renderBtnAction()}
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							height: {
								xs: "calc(100% - 180px)",
								md: "100%",
							},
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
						}}>
						<BoxResult
							initialImage={
								processing?.message === "SUCCESS"
									? (processing.url as string)
									: dataTracking?.status === StatusRequest.SUCCESS
									? (dataTracking?.video as string)
									: ""
							}
							valueProcess={
								processing?.currentStep && processing?.totalStep
									? (processing?.currentStep / processing?.totalStep) * 100
									: dataTracking?.status === StatusRequest.SUCCESS
									? 100
									: 0
							}
							isLoading={isLoadingProcess}
							isStart={processing?.status === EnumStatusOutfit.STARTED}
							processing={processing}
							galleryId={processing?.galleryId}
							packageName={TypeServiceName.ANIMATE}
						/>
					</Grid>
				</Grid>
			</Box>
			{openModalFail && (
				<ModalProcessFail
					open={openModalFail}
					onClose={() => setOpenModalFail(false)}
				/>
			)}
			{openModalNotCredit && (
				<ModalNotBalance
					open={openModalNotCredit}
					onClose={() => setOpenModalNotCredit(false)}
				/>
			)}
			{openModalWarningProcess && (
				<ModalWarningProcess
					open={openModalWarningProcess}
					onClose={() => setOpenModalWarningProcess(false)}
				/>
			)}
		</Box>
	);
};

export default Animate;
