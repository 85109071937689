import { Box, useTheme } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import { useUser } from "hooks";
import React from "react";
import { fontSize } from "theme";
import { ThemeOption } from "types";

const Heading = ({ title }: { title: string }) => {
	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();
	return (
		<Box display={"flex"} alignItems={"center"} gap={"10px"}>
			{Icons.Gallery()}
			<TextCus
				fontSize={fontSize.xs}
				fontWeight={600}
				color={theme.colorSchemes[mode]?.textTitle}>
				{title}
			</TextCus>
		</Box>
	);
};

export default Heading;
