import { TParams, TResponse } from "types";
import axiosInstance from "./axios";
import { END_POINT } from "utils";
import { IDataColorHair, IDataHairStyle } from "types/styleHair";
import { IModelParams } from "types/outfit";

export const hairService = {
	getCategories: async (
		params: TParams,
	): Promise<TResponse<IDataColorHair[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();
		const url = `${END_POINT.HAIR.CATEGORIES}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data?.data;
	},

	getYourHairs: async (
		params: TParams,
		endpoint = END_POINT.HAIR.GET_YOUR_HAIR,
	): Promise<TResponse<IDataHairStyle[]>> => {
		const { page, limit } = params;
		const url = `${endpoint}?page=${page}&limit=${limit}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},

	createYourHair: async (params: IModelParams) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		return await axiosInstance.post(
			END_POINT.HAIR.CREATE_YOUR_HAIR,
			params,
			config,
		);
	},
	deleteYourHair: async (hairStyleId: string) => {
		return await axiosInstance.post(END_POINT.HAIR.DELETE_YOUR_HAIR, {
			hairStyleId: hairStyleId,
		});
	},

	maskHair: async (f: File) => {
		const formData = new FormData();
		formData.append("file", f);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		return await axiosInstance.post(
			END_POINT.HAIR.EMBEDDED_IMAGE,
			formData,
			config,
		);
	},
};
