/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Fade, IconButton, Modal, useTheme } from "@mui/material";
import { TextCus } from "components/TextCus";
import { ButtonCus } from "components/ButtonCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { height, Icons, up, weight } from "assets";
import { SliderRangeCus } from "components/SliderCus";
import useClickOutside from "hooks/useClickOutside";
import { useStoreBodyShape, useUser } from "hooks";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ModalRequirements from "./ModalRequirements";
import { arrNotShould, arrShould, KEY_CONTEXT } from "utils";
import { ThemeOption } from "types";
import SnackbarUtils from "utils/SnackbarUtils";
import { BoxSubtraction } from "components/BoxCredit";

interface IModalCreateBodyShape {
	open: boolean;
	onClose: () => void;
	onOpenResult: () => void;
	handleGenerate: () => void;
	isLoadingProcess: boolean;
}

const arrGender = [
	{ title: "Male", value: "MALE", icon: (color) => Icons.Male(color) },
	{ title: "Female", value: "FEMALE", icon: (color) => Icons.FeMale(color) },
];

export const feetToCm = (fit) => {
	const feetPerCm = 0.0328084;
	return Math.round(fit / feetPerCm);
};

const ModalCreateBodyShape = ({
	open,
	handleGenerate,
	onClose,
	isLoadingProcess,
}: IModalCreateBodyShape) => {
	const { t } = useTranslation();
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const [isOpen, setIsOpen] = useState(false);
	const [optionHeight, setOptionHeight] = useState<"cm" | "feet">("cm");
	const { setFormDataBodyShape, formData, resetFormData } = useStoreBodyShape();
	const [openModalRequire, setOpenModalRequire] = useState(false);
	const { mode, languages } = useUser();
	const theme = useTheme() as ThemeOption;

	useEffect(() => {
		if (optionHeight === "feet") {
			setFormDataBodyShape({ ...formData, height: 3.94 });
		} else {
			setFormDataBodyShape({ ...formData, height: 120 });
		}
	}, [optionHeight]);

	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleClear = useCallback(() => {
		setPreviewUrl(null);
		setFormDataBodyShape({ ...formData, fileGenMe: null });
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	}, []);

	const onReset = useCallback(() => {
		setFormDataBodyShape({
			...formData,
			height: 120,
			weight: 45,
			typeModel: "MALE",
		});
		handleClear();
		resetFormData();
	}, []);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			setFormDataBodyShape({ ...formData, fileGenMe: file });
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	useEffect(() => {
		if (formData.fileGenMe) {
			const url = URL.createObjectURL(formData.fileGenMe as File);
			setPreviewUrl(url);
		}
	}, [formData.fileGenMe]);

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={theme.colorSchemes[mode]?.bgModal}
					flexDirection={"column"}
					sx={{
						backdropFilter: "blur(15px)",
						maxWidth: "1045px",
						maxHeight: "582px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						bgcolor={mode === "dark" ? "rgba(112, 109, 218, 0.7)" : "#E8E7FE"}
						justifyContent={"space-between"}>
						<TextCus
							fontWeight={"700"}
							fontSize={fontSize.sm}
							color={theme.colorSchemes[mode]?.textMain}>
							Custom your body shape
						</TextCus>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined
								sx={{ color: theme.colorSchemes[mode]?.textMain }}
							/>
						</IconButton>
					</Box>
					<Box
						zIndex={1}
						flex={1}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						gap={"10px"}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							gap={"5px"}
							width={"100%"}>
							<TextCus
								color={theme.colorSchemes[mode]?.textMain}
								fontSize={fontSize.xs}
								textAlign={"center"}
								fontWeight={"600"}>
								Upload a clear photo of your face and enter your measurements so
								we can provide the most accurate result.
							</TextCus>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							padding={"25px"}
							gap={"40px"}
							width={"100%"}>
							{/* Box preview img */}
							<Box
								className="dash_linear"
								width={"255px"}
								height={"305px"}
								display={"flex"}
								flexDirection={"column"}
								gap={"25px"}
								justifyContent={"center"}
								position={"relative"}
								alignItems={"center"}
								border={`2px dashed ${theme.colorSchemes[mode]?.bgModal}`}>
								<input
									type="file"
									ref={fileInputRef}
									style={{ display: "none" }}
									accept=".png, .jpg, .jpeg, .webp"
									onChange={handleFileChange}
								/>
								{previewUrl ? (
									<img
										src={previewUrl}
										alt="Preview"
										style={{
											maxWidth: "90%",
											maxHeight: "70%",
											opacity: isPreviewVisible ? 1 : 0,
											transition: "opacity 0.5s ease-in-out",
											borderRadius: "10px",
											margin: "0 auto",
										}}
									/>
								) : (
									<>
										<LazyLoadImage src={up} />
										<TextCus
											fontSize={fontSize.xs}
											fontWeight={"400"}
											color={theme.colorSchemes[mode].textGray}>
											Upload your photo here
										</TextCus>
									</>
								)}
								{previewUrl ? (
									<Box
										onClick={handleClear}
										component={"div"}
										sx={{ cursor: "pointer" }}
										display={"flex"}
										alignItems={"center"}
										position={"absolute"}
										bgcolor={"#ccc"}
										borderRadius={"10px"}
										bottom={"10px"}
										padding={"10px 8px"}
										right={"10px"}>
										{Icons.Delete()}
									</Box>
								) : (
									<ButtonCus
										sx={{ gap: "15px" }}
										onClick={() => {
											if (localStorage.getItem(KEY_CONTEXT.BODY_SHAPE)) {
												handleClick();
											} else {
												setOpenModalRequire(true);
											}
										}}>
										{Icons.Upload()}
										<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
											Upload
										</TextCus>
									</ButtonCus>
								)}
							</Box>
							{/* Box preview */}

							<Box
								display={"flex"}
								flexDirection={"column"}
								alignItems={"center"}
								justifyContent={"space-between"}
								gap={"20px"}
								width={"100%"}
								height={"90%"}
								flex={1}>
								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									width={"100%"}
									gap={"20px"}>
									{arrGender.map((item) => (
										<Box
											key={item.title}
											display={"flex"}
											alignItems={"center"}
											justifyContent={"center"}
											sx={{
												cursor: "pointer",
												transition: "all 0.25s ease-in-out",
											}}
											component={"div"}
											onClick={() =>
												setFormDataBodyShape({
													...formData,
													typeModel: item.value,
												})
											}
											height={"40px"}
											width={"50%"}
											borderRadius={"12px"}
											bgcolor={
												formData.typeModel === item.value
													? "#E8E7FE"
													: "#EEEEEE"
											}
											gap={"15px"}>
											{item.icon(
												formData.typeModel === item.value
													? "#3E227C"
													: "#666666",
											)}
											<TextCus
												color={
													formData.typeModel === item.value
														? "#3E227C"
														: "#666666"
												}
												fontWeight={"600"}
												fontSize={fontSize.xs}>
												{item.title}
											</TextCus>
										</Box>
									))}
								</Box>
								<TextCus
									fontSize={"12px"}
									fontWeight={"400"}
									textAlign={"left"}
									color={theme.colorSchemes[mode]?.textGray}>
									To calculate BMI, enter your height and weight. Your BMI is
									approximately related to body fat percentage.
								</TextCus>
								<Box
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
									width={"100%"}
									borderRadius={"12px"}
									bgcolor={mode === "dark" ? "#666666" : "#EEEEEE"}
									gap={"40px"}
									padding={"15px"}>
									<Box
										display={"flex"}
										alignItems={"center"}
										width={"100%"}
										gap={"20px"}
										borderRadius={"12px"}
										justifyContent={"space-between"}>
										<Box
											display={"flex"}
											gap={"10px"}
											alignItems={"center"}
											justifyContent={"center"}>
											<img src={height} alt="Height" />{" "}
											<TextCus
												width={languages.value === "vi" ? "75px" : "40px"}
												fontWeight={"600"}
												fontSize={fontSize.xs}
												color={theme.colorSchemes[mode].textMain}>
												Height
											</TextCus>
										</Box>
										<SliderRangeCus
											setVSlider={(v) =>
												setFormDataBodyShape({
													...formData,
													height: v,
												})
											}
											valueDefault={formData.height}
											maxValue={optionHeight === "feet" ? 6.4 : 195}
											minValue={optionHeight === "feet" ? 3.94 : 120}
										/>
										<Box width={"154px"} height={"33px"} display={"flex"}>
											<Box
												width={"77px"}
												borderRadius={"12px 0 0 12px"}
												component={"input"}
												padding={"5px"}
												textAlign={"center"}
												max={195}
												min={0}
												fontWeight={"600"}
												type="number"
												value={formData.height + ""}
												onChange={(e) =>
													+e.target.value <= 195 &&
													setFormDataBodyShape({
														...formData,
														height: +e.target.value,
													})
												}
												border={"1px solid #B2B2B2"}
											/>
											<Box
												width={"77px"}
												ref={dropdownRef}
												display={"flex"}
												alignItems={"center"}
												gap={"5px"}
												sx={{ cursor: "pointer" }}
												justifyContent={"center"}
												border={"1px solid #B2B2B2"}
												borderLeft={"none"}
												borderRadius={`0 12px ${isOpen ? "0px" : "12px"} 0`}
												bgcolor={"white"}
												color={mainColor.textMain}
												fontSize={fontSize.xs}
												onClick={() => setIsOpen(!isOpen)}
												position={"relative"}
												fontWeight={"400"}>
												{optionHeight}
												<Icons.ArrowDown />
												{isOpen && (
													<Box
														position={"absolute"}
														bgcolor={"white"}
														bottom={"-30px"}
														right={"-1px"}
														component={"div"}
														onClick={() =>
															setOptionHeight((pre) =>
																pre === "cm" ? "feet" : "cm",
															)
														}
														width={"78px"}
														height={"30px"}
														display={"flex"}
														alignItems={"center"}
														border={"1px solid #B2B2B2"}
														justifyContent={"center"}
														borderRadius={"0 0 12px 0"}
														color={mainColor.textMain}
														fontSize={fontSize.xs}
														fontWeight={"400"}>
														{optionHeight === "cm" ? "feet" : "cm"}
													</Box>
												)}
											</Box>
										</Box>
									</Box>
									<Box
										display={"flex"}
										alignItems={"center"}
										width={"100%"}
										gap={"20px"}
										borderRadius={"12px"}
										justifyContent={"space-between"}>
										<Box
											display={"flex"}
											gap={"10px"}
											alignItems={"center"}
											justifyContent={"center"}>
											<img src={weight} alt="Height" />{" "}
											<TextCus
												width={languages.value === "vi" ? "75px" : "40px"}
												fontWeight={"600"}
												fontSize={fontSize.xs}
												color={theme.colorSchemes[mode].textMain}>
												Weight
											</TextCus>
										</Box>
										<SliderRangeCus
											setVSlider={(v) =>
												setFormDataBodyShape({
													...formData,
													weight: v,
												})
											}
											valueDefault={formData.weight}
											maxValue={125}
											minValue={45}
										/>
										<Box width={"154px"} height={"33px"} display={"flex"}>
											<Box
												width={"77px"}
												borderRadius={"12px 0 0 12px"}
												component={"input"}
												padding={"5px"}
												textAlign={"center"}
												fontWeight={"600"}
												max={125}
												min={0}
												value={formData.weight + ""}
												onChange={(e) =>
													+e.target.value <= 125 &&
													setFormDataBodyShape({
														...formData,
														weight: +e.target.value,
													})
												}
												type="number"
												border={"1px solid #B2B2B2"}
											/>
											<Box
												width="77px"
												display={"flex"}
												alignItems={"center"}
												justifyContent={"center"}
												borderRadius={"0 12px 12px 0"}
												bgcolor={"white"}
												color={"#A3A3A3"}
												fontSize={fontSize.xs}
												fontWeight={"400"}>
												kg
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"15px"}>
							<ButtonCus
								onClick={onReset}
								sx={{ width: "190px", padding: "0px !important", flex: 1 }}>
								<Box
									bgcolor={"white"}
									width={"97%"}
									borderRadius={"10px"}
									height={"90%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}>
									<Box
										display={"flex"}
										alignItems={"center"}
										gap={"15px"}
										className="text_linear"
										fontSize={fontSize.xs}
										fontWeight={"600"}>
										{t("Reset")}
									</Box>
								</Box>
							</ButtonCus>
							<ButtonCus
								disabled={!formData.fileGenMe}
								sx={{ width: "190px" }}
								isLoading={isLoadingProcess}
								onClick={() => {
									if (
										(optionHeight === "feet" && formData.height < 3.94) ||
										(optionHeight === "cm" && formData.height < 120)
									)
										return SnackbarUtils.warning(
											"Please check height > 120cm and weight > 45kg",
										);
									handleGenerate();
								}}>
								<TextCus
									fontWeight={"600"}
									color={mainColor.white}
									fontSize={fontSize.xs}>
									Generate
								</TextCus>
								<BoxSubtraction />
							</ButtonCus>
						</Box>
					</Box>
					{openModalRequire && (
						<ModalRequirements
							currentStep={3}
							isCreateBodyShape={true}
							open={openModalRequire}
							onClose={() => setOpenModalRequire(false)}
							handleClick={() => {
								setOpenModalRequire(false);
								handleClick();
							}}
							arrContentShould={arrShould}
							arrContentNotShould={arrNotShould}
						/>
					)}
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalCreateBodyShape;
