/** @format */

import React, {
	// memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconMore, Icons } from "assets";
import { Box, IconButton, Popover, useTheme } from "@mui/material";
import { InputUpdate, TextCus } from "components";
import { mainColor } from "theme";
import { IDataRename, IGallery } from "types/outfit";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { ThemeOption, TypeServiceName } from "types";
import { useLoadImage, useUser } from "hooks";

const BoxTemplate = ({
	item,
	onDelete,
	handleRename,
	numberItem,
	typeTab,
}: {
	item?: IGallery;
	onDelete: (id: string) => void;
	handleRename: (data: IDataRename) => void;
	numberItem?: number;
	typeTab: string | number | undefined;
}) => {
	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const navigate = useNavigate();
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			setAnchorEl(event.currentTarget);
		},
		[],
	);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const refInput = useRef<HTMLInputElement>(null);
	const [galleryTitle, setGalleryTitle] = useState("");
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const arrOption = useMemo(
		() => [
			{
				icon: Icons.Edit(),
				text: "Edit",
				action: () => {
					navigate(`${APP_ROOT.HISTORY}?id=${item?.id}`);
					handleClose();
				},
			},
			{
				icon: Icons.Delete(),
				text: "Delete",
				action: () => {
					onDelete(item?.id + "");
					handleClose();
				},
			},
		],
		[],
	);

	const handleGalleryTitleChange = useCallback((e) => {
		setGalleryTitle(e.target.value);
	}, []);

	const handleGalleryTitleBlur = useCallback(() => {
		galleryTitle?.length > 0 &&
			galleryTitle !== item?.name &&
			handleRename({ galleryId: item?.id + "", name: galleryTitle });
	}, [item?.id, galleryTitle]);

	const selectAllInlineText = useCallback((e) => {
		e.target.focus();
		e.target.select();
	}, []);

	useEffect(() => {
		setGalleryTitle((item?.name?.length ? item?.name : item?.id) + "");
	}, [item?.name]);

	const refVideo = useRef<HTMLVideoElement>();
	const [isPlaying, setIsPlaying] = useState(false);

	const handleMouseEnter = () => {
		if (refVideo.current && !isPlaying) {
			setIsPlaying(true);
			refVideo.current.play().catch((error) => {
				console.error("Error playing video:", error);
			});
		}
	};

	const handleMouseLeave = () => {
		if (refVideo.current && isPlaying) {
			setIsPlaying(false);
			refVideo.current.pause();
		}
	};

	return (
		<Box
			component={"div"}
			position={"relative"}
			overflow={"hidden"}
			width={`calc((100% - 30px) / ${numberItem ?? 3})`}
			sx={{
				cursor: "pointer",
				":hover": {
					".icon_option": {
						opacity: 1,
					},
				},
			}}
			borderRadius={"12px"}
			display={"flex"}
			flexDirection={"column"}
			gap={"10px"}>
			<Box
				position={"relative"}
				maxHeight={"212px"}
				width={"100%"}
				height={"212px"}
				borderRadius={"20px"}
				overflow={"hidden"}
				display={"flex"}
				alignItems={"flex-start"}
				flexDirection={"column"}
				bgcolor={"#ccc"}
				sx={{
					":hover": {
						".img_thumb": {
							transform: "scale(1.1)",
						},
					},
				}}
				onClick={() => {
					if (typeTab === TypeServiceName.OUTFIT)
						return navigate(`${APP_ROOT.HISTORY}?id=${item?.id}`);
					if (typeTab === TypeServiceName.HAIR_SWAP)
						return navigate(`${APP_ROOT.HISTORY_STYLE_HAIR}?id=${item?.id}`);
					if (typeTab === TypeServiceName.GEN_ME)
						return navigate(`${APP_ROOT.HISTORY_GEN_ME}?id=${item?.id}`);
					if (typeTab === TypeServiceName.ANIMATE)
						return navigate(APP_ROOT.HISTORY_ANIMATE + `?id=${item?.id}`);
				}}
				justifyContent={"flex-start"}>
				{typeTab !== TypeServiceName.ANIMATE ? (
					<LazyLoadImage
						className="img_thumb"
						style={{
							transition: "all 0.35s ease-in-out",
							objectFit: "cover",
							display: "block",
							width: "100%",
						}}
						src={error ? img404 : loading ? imgLoad : `${item?.imgDownload}`}
						onLoad={handleLoad}
						onError={handleError}
						alt={error ? "Error" : "image project"}
					/>
				) : (
					<Box
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						ref={refVideo}
						borderRadius={"12px"}
						sx={{ objectFit: "contain" }}
						width={"100%"}
						height={"100%"}
						component={"video"}
						autoPlay={false}
						controls={false}
						loop
						muted>
						<source src={item?.video} type="video/mp4" />
					</Box>
				)}
				<Box
					className="icon_option transition_cus"
					position={"absolute"}
					sx={{ opacity: 0 }}
					zIndex={9}
					top={"5px"}
					right={"5px"}>
					<IconButton
						onClick={handleClick}
						sx={{ width: "25px", height: "25px" }}>
						<img src={iconMore} alt="more" width={"25px"} />
					</IconButton>
				</Box>
			</Box>

			<Box height={"50px"}>
				<Box
					position={"relative"}
					mb={"5px"}
					sx={{
						transition: "all 0.15s ease-in-out",
						padding: "5px 10px",
						borderRadius: "12px",
						"&:hover": {
							".btn_edit": {
								opacity: 1,
							},
							background: theme.colorSchemes[mode]?.bgHover,
						},
					}}>
					<InputUpdate
						refInput={refInput}
						valueTitle={galleryTitle}
						handleGalleryTitleBlur={handleGalleryTitleBlur}
						handleGalleryTitleChange={handleGalleryTitleChange}
						selectAllInlineText={selectAllInlineText}
					/>
					<IconButton
						className="btn_edit"
						sx={{
							position: "absolute",
							right: "5px",
							top: "3px",
							padding: "2px",
							opacity: 0,
						}}
						onClick={() => refInput.current?.click()}>
						<Icons.EditName />
					</IconButton>
				</Box>
				<TextCus
					paddingLeft={"10px"}
					fontSize={"12px"}
					fontWeight={"500"}
					color={theme.colorSchemes[mode]?.textGray}>
					{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm:ss") || ""}
				</TextCus>
			</Box>

			{/* ========== POPUP OPTION ============ */}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					top: "-25px",
					overflow: "hidden",
					borderRadius: "20px",
					left: "-120px",
				}}>
				<Box
					width={"120px"}
					padding={"15px"}
					display={"flex"}
					overflow={"hidden"}
					bgcolor={mainColor.primary}
					flexDirection={"column"}
					gap={"15px"}>
					{arrOption.map((item) => (
						<Box
							key={item.text}
							display={"flex"}
							alignItems={"center"}
							sx={{ cursor: "pointer" }}
							component={"div"}
							onClick={item.action}
							justifyContent={"start"}
							gap={"15px"}>
							{item.icon}
							<TextCus useI18n>{item.text}</TextCus>
						</Box>
					))}
				</Box>
			</Popover>
		</Box>
	);
};

export default BoxTemplate;
