import { Box, useTheme } from "@mui/material";
import { up } from "assets";
import { TextCus } from "components";
import { useUser } from "hooks";
import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize, mainColor } from "theme";
import { ThemeOption } from "types";

interface BoxPhotoProps {
	description?: string;
	title?: string;
	dropType: string;
	initialImage: string;
	active: boolean;
	currentStep: number;
	action?: () => void;
	imgDf?: string;
}

const BoxPhoto = ({
	dropType,
	title,
	description,
	initialImage,
	active,
	currentStep,
	action,
	imgDf,
}: BoxPhotoProps) => {
	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: dropType,
		drop: () => ({ name: "Dustbin" }),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	const isActive = canDrop && isOver;
	let backgroundColor = theme.colorSchemes[mode]?.bgPhoto;
	if (isActive) {
		backgroundColor = "#5adbfc";
	} else if (canDrop) {
		backgroundColor = "#5adbfc59";
	}

	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	useEffect(() => {
		setPreviewUrl(initialImage);
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}
		return () => clearTimeout(timer);
	}, [previewUrl]);

	return (
		<Box
			ref={drop}
			component={"div"}
			onClick={action}
			bgcolor={
				active ? theme.colorSchemes[mode]?.bgPhotoActive : backgroundColor
			}
			width={"100%"}
			sx={{
				cursor: "pointer",
				padding: { xs: "5px", xl: "15px" },
				transition: "all 0.3s ease-in-out",
				height: {
					xs: "164px",
					md: "calc((100% - 240px) / 2)",
					xl: `calc((100% - 285px) / 2)`,
				},
				border: active ? "1px solid #785BBA" : "0px solid #ffffff",
				boxShadow: active
					? "0 0 10px 0 rgba(33, 6, 93, 0.3)"
					: "0 0 0px 0 rgba(33, 6, 93, 0.3)",
				":hover": {
					boxShadow: "0 0 10px 0 rgba(33, 6, 93, 0.3)",
				},
			}}
			display={"flex"}
			flexDirection={"column"}
			position={"relative"}
			gap={"10px"}
			alignItems={"center"}
			justifyContent={"space-between"}
			borderRadius={"12px"}>
			<TextCus
				fontWeight={"600"}
				sx={{
					fontSize: {
						xs: "12px",
						xl: fontSize.xs,
					},
				}}
				color={theme.colorSchemes[mode]?.textTitle}>
				{title}
			</TextCus>
			{previewUrl ? (
				<Box
					position={"relative"}
					maxHeight={"calc(100% - 25px)"}
					maxWidth={"100%"}>
					<img
						src={previewUrl}
						alt="Preview"
						style={{
							maxWidth: "100%",
							maxHeight: "97%",
							opacity: isPreviewVisible ? 1 : 0,
							transition: "opacity 0.5s ease-in-out",
							borderRadius: "10px",
							objectFit: "contain",
						}}
					/>
				</Box>
			) : (
				<>
					<LazyLoadImage
						src={imgDf || up}
						style={{ display: "block", maxHeight: "30%" }}
						alt="Upload"
					/>
					<TextCus
						color={theme.colorSchemes[mode]?.textGray}
						textAlign={"center"}>
						{description}
					</TextCus>
				</>
			)}
			<Box
				bgcolor={"#5B3E9B"}
				width={"70px"}
				height={"24px"}
				alignItems={"center"}
				justifyContent={"center"}
				position={"absolute"}
				top={"20px"}
				sx={{
					display: { xs: "none", md: "flex" },
					opacity: active ? 1 : 0,
					transition: "all 0.25s ease-in-out",
				}}
				left={active ? "-39px" : "-70px"}
				fontSize={"12px"}
				fontWeight={"700"}
				color={mainColor.white}
				borderRadius={"12px"}>
				Step {currentStep}
			</Box>
		</Box>
	);
};

export default BoxPhoto;
