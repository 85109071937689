import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Fade, IconButton, Modal, Slider, useTheme } from "@mui/material";
import { Stage, Layer, Image, Line } from "react-konva";
import { fontSize } from "theme";
import { TextCus } from "components/TextCus";
import { Icons, IconsTool, PreComp } from "assets";
import { ButtonCus } from "components/ButtonCus";
import { CloseOutlined } from "@mui/icons-material";
import useImage from "use-image";
import { useBehaviorHandlers } from "hooks/useBehaviorHandlers";
import pointerSvg from "../../assets/images/pointer.svg";
import pointerClick from "../../assets/images/pointerClick.svg";
import { convertMaskToBinaryAndSubmit, KEY_CONTEXT } from "utils";
import { useOutfitStore, useStyleHairStore, useUser } from "hooks";
import { useLocation } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { ThemeOption } from "types";
import { aiService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

interface ModalPaintingProps {
	imageUrl: string;
	imageClothes?: string;
	open: boolean;
	onClose: () => void;
	fileModel?: string | File;
}

export type TLine = {
	valueSize: number;
	tool: string;
	points: number[];
};

export const transformPointsOriginToScale = (points, scaleX, scaleY) => {
	return points?.map(([x, y]) => [x * scaleX, y * scaleY]);
};

const ModalPainting: React.FC<ModalPaintingProps> = ({
	imageUrl,
	open,
	onClose,
	imageClothes,
	fileModel,
}) => {
	const location = useLocation();
	const isStyleHair = location.pathname === APP_ROOT.STYLE_HAIR;
	const isDrawing = useRef(false);
	const refHeight = useRef<HTMLElement | null>(null);
	const [tool, setTool] = useState("pen");
	const [valueSize, setValueSize] = useState(30);
	const [stageDimensions, setStageDimensions] = useState({
		width: 0,
		height: 0,
	});
	const { setFileBinary, setCurrentStep, linesRetouch, setLinesRetouch } =
		useOutfitStore();
	const {
		setBinaryMask,
		setModelImageMask,
		linesRetouchHair,
		setLinesRetouchHair,
	} = useStyleHairStore();
	const [lines, setLines] = useState<(TLine | undefined)[]>(
		isStyleHair ? linesRetouchHair : linesRetouch,
	);
	const [history, setHistory] = useState<(TLine | undefined)[]>([]);
	const [redoStack, setRedoStack] = useState<(TLine | undefined)[]>([]);
	const [isHand, setIsHand] = useState(false);
	const refCanvas = useRef<any>(null);
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;
	const [loadedImage, status] = useImage(imageUrl, "anonymous", "origin");
	const [imageUrlCanvas, setImageUrlCanvas] = useState(imageUrl);
	const [stateChange, setStateChange] = useState(0);
	const [isLoadingSam, setIsLoadingSam] = useState(false);
	const [isShowHd, setIsShowHd] = useState(false);
	const [sizeImage, setSizeImage] = useState({
		widthSize: 0,
		heightSize: 0,
	});

	useEffect(() => {
		if (tool === "click" && !localStorage.getItem(KEY_CONTEXT.HD_CLICK)) {
			setIsShowHd(true);
		} else {
			setIsShowHd(false);
		}
	}, [tool]);

	const handleSliderChange = (_: Event, newValue: number | number[]) => {
		setValueSize(newValue as number);
	};

	useEffect(() => {
		if (refHeight.current) {
			setStageDimensions({
				width: refHeight.current?.clientWidth,
				height: refHeight.current?.clientHeight,
			});
		}
	}, [refHeight.current, open]);

	const handleConvertToImage = () => {
		if (refCanvas?.current && status === "loaded") {
			const originalCanvas = refCanvas.current.toCanvas();
			const tempCanvas = document.createElement("canvas");
			const tempContext = tempCanvas.getContext("2d");
			tempCanvas.width = loadedImage?.width;
			tempCanvas.height = loadedImage?.height;

			tempContext.drawImage(
				originalCanvas,
				0,
				0,
				originalCanvas.width,
				originalCanvas.height,
				0,
				0,
				loadedImage?.width ?? 0,
				loadedImage?.height ?? 0,
			);

			const dataURL = tempCanvas.toDataURL("image/png");
			setImageUrlCanvas(dataURL);
		}
	};

	useEffect(() => {
		if (refCanvas?.current) {
			setLines(isStyleHair ? linesRetouchHair : linesRetouch);
			handleConvertToImage();
		}
	}, [
		linesRetouch,
		linesRetouchHair,
		stageDimensions.height,
		stageDimensions.width,
		isStyleHair,
	]);

	useEffect(() => {
		if (stateChange !== 1 && stateChange !== 0) {
			handleConvertToImage();
		}
	}, [stateChange]);

	const handleMouseDown = (e) => {
		if (tool === "click") return;
		isDrawing.current = true;
		const pos = e.target.getStage().getPointerPosition();
		setLines([...lines, { valueSize, tool, points: [pos.x, pos.y] }]);
		setHistory([...lines, { valueSize, tool, points: [pos.x, pos.y] }]);
	};

	const handleMouseMove = (e) => {
		if (!isDrawing.current || tool === "click") {
			return;
		}
		setStateChange(1);
		const stage = e.target.getStage();
		const point = stage.getPointerPosition();
		const lastLine = lines[lines.length - 1];
		if (!lastLine) return;
		lastLine.points = lastLine?.points?.concat([point.x, point.y]);
		lines.splice(lines.length - 1, 1, lastLine);
		setLines(lines.concat());
		setHistory(lines.concat());
	};

	const handleMouseUp = () => {
		isDrawing.current = false;
		setStateChange(2);
	};

	const handleReset = useCallback(() => {
		setLines([]);
		setHistory([]);
		setRedoStack([]);
		setStateChange((pre) => pre + 5);
	}, []);

	const handleUndo = () => {
		if (history.length > 1) {
			const newHistory = history.slice(0, -1);
			const lastLine = history[history.length - 1];
			setHistory(newHistory);
			setLines(newHistory);
			setRedoStack((prevRedoStack) => [...prevRedoStack, lastLine]);
		} else if (history.length === 1) {
			const lastSpline = history[0];
			setHistory([]);
			setLines([]);
			setRedoStack((prevRedoStack) => [...prevRedoStack, lastSpline]);
		}
		setStateChange(stateChange + 2);
	};

	const handleRedo = () => {
		if (redoStack.length > 0) {
			const newRedoStack = [...redoStack];
			const lastRedo = newRedoStack.pop();
			const newHistory = [...history, lastRedo];
			newHistory && setHistory(newHistory);
			newHistory && setLines(newHistory);
			setRedoStack(newRedoStack);
			setStateChange(stateChange + 3);
		}
	};

	useBehaviorHandlers({
		undo: handleUndo,
		redo: handleRedo,
	});

	const createCursor = (size) => {
		const idCanvas = document.getElementById("idCanvas") as HTMLElement;
		if (!idCanvas) return;
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		canvas.width = size;
		canvas.height = size;
		const img = document.createElement("img");
		img.src = tool === "click" ? pointerClick : pointerSvg;
		img.onload = () => {
			context?.drawImage(img, 0, 0, size, size);
			idCanvas.style.cursor = `url(${canvas.toDataURL()}) ${size / 2} ${
				size / 2
			}, auto`;
		};
	};
	const [urlImgBinary, setUrlImgBinary] = useState("");
	const handleConfirm = useCallback(async () => {
		if (status === "loaded") {
			const file = await convertMaskToBinaryAndSubmit(
				imageUrlCanvas,
				loadedImage?.width,
				loadedImage?.height,
			);
			if (file) {
				const url = URL.createObjectURL(file);
				setUrlImgBinary(url);
				setFileBinary(file);
				setCurrentStep(2);
				setLinesRetouch(lines);
				onClose();
			}
		}
	}, [imageUrlCanvas, status]);

	const handleConfirmStyleHair = async () => {
		if (imageUrlCanvas && refCanvas.current && status === "loaded") {
			const file = await convertMaskToBinaryAndSubmit(
				imageUrlCanvas,
				loadedImage?.width,
				loadedImage?.height,
			);
			setBinaryMask(file as File);
			setModelImageMask(imageUrlCanvas);
			setCurrentStep(1);
			setLinesRetouchHair(lines);
			onClose();
		}
	};

	const handleSam = async (formData) => {
		setIsLoadingSam(true);
		if (status !== "loaded") return;
		try {
			const { data } = await aiService.samTools(formData);
			setIsLoadingSam(false);
			data?.data?.contours.forEach((element) => {
				const dataFlat = transformPointsOriginToScale(
					element,
					sizeImage.widthSize / loadedImage?.width,
					sizeImage.heightSize / loadedImage?.height,
				)?.flat();
				setLines((prevLines) => {
					const newLines = [
						...prevLines,
						{ valueSize: 15, tool: "click", points: dataFlat },
					];
					return newLines;
				});
				setHistory((prevHistory) => {
					const newHistory = [
						...prevHistory,
						{ valueSize: 15, tool: "click", points: dataFlat },
					];
					return newHistory;
				});
			});
			setStateChange((prev) => prev + 3);
		} catch (error) {
			SnackbarUtils.warning("Sorry server error!");
			setIsLoadingSam(false);
		}
	};

	const handleClick = (event) => {
		if (tool !== "click" || isLoadingSam || status !== "loaded") return;
		const scaleX = loadedImage?.width / sizeImage.widthSize;
		const scaleY = loadedImage?.height / sizeImage.heightSize;
		const { x, y } = event.target.getStage().getPointerPosition();
		const originalX = Math.floor(x * scaleX);
		const originalY = Math.floor(y * scaleY);
		const coordinate = [[originalX, originalY]];
		const formData = new FormData();
		if (typeof fileModel === "object" && fileModel !== null) {
			formData.append("url", fileModel);
		} else {
			formData.append("link", imageUrl);
		}
		formData.append("clickpoints", `[[${coordinate}]]`);
		formData.append("clicklabels", `[${1}]`);
		handleSam(formData);
	};

	const [openModalInfo, setOpenModalInfo] = useState(false);
	const handleOpenModalInfo = useCallback(() => {
		setOpenModalInfo(true);
	}, []);

	useEffect(() => {
		if (!localStorage.getItem(KEY_CONTEXT.HD_SAM)) {
			setOpenModalInfo(true);
		} else {
			setOpenModalInfo(false);
		}
	}, [tool]);

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={() => {
				isStyleHair ? setLinesRetouchHair(lines) : setLinesRetouch(lines);
				onClose();
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					bgcolor={theme.colorSchemes[mode]?.bgModal}
					sx={{
						backdropFilter: "blur(15px)",
						maxWidth: "1064px",
						width: {
							xs: "90vw",
						},
					}}
					maxHeight={"90vh"}
					height={"965px"}
					overflow={"hidden"}
					position={"relative"}
					borderRadius={"12px"}>
					<IconButton
						sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 8 }}
						onClick={onClose}>
						<CloseOutlined sx={{ color: theme.colorSchemes[mode]?.textMain }} />
					</IconButton>

					{/* ======= SIDE BAR ======= */}
					<Box
						display={"flex"}
						flexDirection={"column"}
						width={"30%"}
						justifyContent={"space-between"}
						padding={"15px"}>
						<Box
							display={"flex"}
							height={"calc(100% - 90px)"}
							flexDirection={"column"}
							width={"100%"}
							gap={"15px"}>
							<Box>
								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}>
									<TextCus
										color={theme.colorSchemes[mode]?.textTitle}
										fontWeight={"700"}
										fontSize={fontSize.sm}>
										RETOUCH ITEM
									</TextCus>
									<IconButton onClick={handleOpenModalInfo}>
										<IconsTool.Info />
									</IconButton>
								</Box>
								<TextCus
									color={"#FF52F0"}
									fontSize={"12px"}
									fontWeight={"500"}
									mt={"5px"}>
									Choose “Brush” or “Eraser” to adjust the fitting or design of
									the item
								</TextCus>
							</Box>
							<Box>
								<Box
									display={"flex"}
									alignItems={"center"}
									gap={"15px"}
									justifyContent={"space-between"}>
									<Box
										onClick={() => setTool("pen")}
										bgcolor={
											tool === "pen"
												? "rgba(149, 119, 217, 0.5)"
												: "rgba(149, 119, 217, 0.2)"
										}
										display={"flex"}
										sx={{
											cursor: "pointer",
											border: tool === "pen" ? "1px solid #785BBA" : "none",
											transition: "all 0.25s ease-in-out",
										}}
										flex={1}
										borderRadius={"12px"}
										justifyContent={"center"}
										height={"36px"}
										gap={"10px"}
										alignItems={"center"}>
										{Icons.Brush(theme.colorSchemes[mode]?.textTitle)}
										<TextCus
											color={theme.colorSchemes[mode]?.textTitle}
											fontSize={"12px"}
											fontWeight={"500"}>
											Brush
										</TextCus>
									</Box>

									<Box
										onClick={() => setTool("click")}
										bgcolor={
											tool === "click"
												? "rgba(149, 119, 217, 0.5)"
												: "rgba(149, 119, 217, 0.2)"
										}
										display={"flex"}
										sx={{
											cursor: "pointer",
											border: tool === "pen" ? "1px solid #785BBA" : "none",
											transition: "all 0.25s ease-in-out",
										}}
										flex={1}
										borderRadius={"12px"}
										justifyContent={"center"}
										height={"36px"}
										gap={"10px"}
										alignItems={"center"}>
										{Icons.Click(theme.colorSchemes[mode]?.textTitle)}
										<TextCus
											color={theme.colorSchemes[mode]?.textTitle}
											fontSize={"12px"}
											fontWeight={"500"}>
											Click
										</TextCus>
									</Box>

									<Box
										onClick={() => {
											setTool("eraser");
										}}
										bgcolor={
											tool === "eraser"
												? "rgba(149, 119, 217, 0.5)"
												: "rgba(149, 119, 217, 0.2)"
										}
										display={"flex"}
										sx={{
											cursor: "pointer",
											border: tool === "eraser" ? "1px solid #785BBA" : "none",
											transition: "all 0.25s ease-in-out",
										}}
										flex={1}
										borderRadius={"12px"}
										justifyContent={"center"}
										gap={"10px"}
										height={"36px"}
										alignItems={"center"}>
										{Icons.Eraser(theme.colorSchemes[mode]?.textTitle)}
										<TextCus
											color={theme.colorSchemes[mode]?.textTitle}
											fontSize={"12px"}
											fontWeight={"500"}>
											Eraser
										</TextCus>
									</Box>
								</Box>

								<TextCus
									color={theme.colorSchemes[mode]?.textTitle}
									fontSize={"11px"}
									fontWeight={"400"}
									mt={"10px"}>
									Use the brush to expand the selection area of this outfit
								</TextCus>
							</Box>
							<Box>
								<TextCus
									color={theme.colorSchemes[mode]?.textTitle}
									fontWeight={"400"}
									fontSize={fontSize.xs}>
									Brush size
								</TextCus>
								<Box display={"flex"} alignItems={"center"} gap={"20px"}>
									<Slider
										sx={{
											"& .MuiSlider-track": {
												background: "linear-gradient(90deg, #00c6ff, #ff8ce1)",
												height: 8,
												borderRadius: 4,
												border: "none",
											},
											"& .MuiSlider-rail": {
												backgroundColor: "#D1CFFD",
												height: 8,
												opacity: 1,
												borderRadius: 4,
											},
											"& .MuiSlider-thumb": {
												height: 24,
												width: 24,
												backgroundColor: "#ff00ff",
												border: "3px solid white",
												boxShadow: "unset !important",
												"&:hover": {
													boxShadow: "unset !important",
												},
												"&:active": {
													boxShadow: "unset !important",
												},
											},
										}}
										value={typeof valueSize === "number" ? valueSize : 8}
										min={8}
										onChange={handleSliderChange}
										aria-labelledby="input-slider"
									/>
									<TextCus
										fontSize={"12px"}
										width={20}
										color={theme.colorSchemes[mode]?.textTitle}
										fontWeight={"400"}>
										{valueSize + ""}
									</TextCus>
								</Box>
							</Box>
							<Box
								gap={"10px"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								height={"calc(100% - 250px)"}
								flexDirection={"column"}>
								<TextCus
									width={"100%"}
									color={theme.colorSchemes[mode]?.textTitle}>
									Preview
								</TextCus>
								<Box
									height={"fit-content"}
									maxHeight={"calc(100% - 90px)"}
									width={"100%"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"flex-start"}>
									<img
										style={{
											width: "auto",
											height: "auto",
											borderRadius: "12px",
											maxWidth: "100%",
											maxHeight: "100%",
										}}
										src={imageUrlCanvas}
										alt="image"
									/>
								</Box>
								<ButtonCus
									disabled={
										lines.length === 0 ||
										!lines.some((v) => v.tool === "pen" || v.tool === "click")
									}
									sx={{ height: "40px !important", width: "100%" }}
									onClick={isStyleHair ? handleConfirmStyleHair : handleConfirm}
									title="Confirm"
								/>
							</Box>
						</Box>
						{!isStyleHair && (
							<Box display={"flex"} alignItems={"center"} gap={"10px"}>
								<img
									style={{
										minHeight: "72px",
										height: "72px",
										borderRadius: "8px",
									}}
									src={imageUrl}
									alt="image"
								/>
								<img
									style={{
										minHeight: "72px",
										height: "72px",
										borderRadius: "8px",
									}}
									src={imageClothes}
									alt="image"
								/>
								{urlImgBinary && (
									<img
										style={{
											minHeight: "72px",
											height: "72px",
											borderRadius: "8px",
										}}
										src={urlImgBinary}
										alt="image"
									/>
								)}
							</Box>
						)}
					</Box>
					{/* ======= END SIDE BAR ======= */}
					{/* ======= CANVAS ======= */}
					<Box
						flex={1}
						bgcolor={mode === "dark" ? "rgba(48, 48, 48, 0.9)" : "#F9F9F9"}
						display={"flex"}
						width={"70%"}
						alignItems={"center"}
						justifyContent={"center"}>
						<Box
							ref={refHeight}
							bgcolor={mode === "dark" ? "rgb(0 0 0 / 34%)" : "#999D9E"}
							borderRadius={"12px"}
							width={"90%"}
							overflow={"hidden"}
							position={"relative"}
							display={"flex"}
							justifyContent={"center"}
							height={"90%"}>
							<img
								src={imageUrl}
								onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
									setSizeImage({
										heightSize: e.currentTarget.clientHeight,
										widthSize: e.currentTarget.clientWidth,
									});
								}}
								alt="img"
								style={{
									height: "auto",
									width: "auto",
									maxHeight: "100%",
									maxWidth: "100%",
									objectFit: "contain",
									opacity: 0,
									visibility: "hidden",
									position: "absolute",
								}}
							/>
							<Box
								position={"absolute"}
								top={"7px"}
								left={"10px"}
								zIndex={99}
								display={"flex"}
								alignItems={"center"}
								gap={"10px"}>
								<IconButton
									onClick={() => setIsHand(false)}
									sx={{
										bgcolor: !isHand ? "#FFFFFF" : "rgba(255, 255, 255, 0.3)",
										borderRadius: "8px",
										backdropFilter: "blur(4px)",
									}}>
									<IconsTool.Brush />
								</IconButton>
								<IconButton
									disabled
									onClick={() => {
										setIsHand(true);
									}}
									sx={{
										bgcolor: isHand ? "#ffffff" : "rgba(255, 255, 255, 0.3)",
										borderRadius: "8px",
										backdropFilter: "blur(4px)",
									}}>
									<IconsTool.Hand />
								</IconButton>
							</Box>
							<Box
								position={"absolute"}
								top={"7px"}
								right={"10px"}
								zIndex={99}
								display={"flex"}
								alignItems={"center"}
								gap={"10px"}>
								<IconButton
									onClick={handleReset}
									sx={{
										bgcolor: "rgba(255, 255, 255, 0.3)",
										borderRadius: "8px",
										backdropFilter: "blur(4px)",
									}}>
									{IconsTool.Reset(theme.colorSchemes[mode]?.textMain)}
								</IconButton>
								<IconButton
									onClick={handleUndo}
									sx={{
										bgcolor: "rgba(255, 255, 255, 0.3)",
										borderRadius: "8px",
										backdropFilter: "blur(4px)",
									}}>
									{IconsTool.Undo(theme.colorSchemes[mode]?.textMain)}
								</IconButton>
								<IconButton
									onClick={handleRedo}
									sx={{
										bgcolor: "rgba(255, 255, 255, 0.3)",
										borderRadius: "8px",
										backdropFilter: "blur(4px)",
									}}>
									{IconsTool.Redo(theme.colorSchemes[mode]?.textMain)}
								</IconButton>
							</Box>
							{stageDimensions.width > 0 &&
								stageDimensions.height > 0 &&
								sizeImage.widthSize > 0 &&
								sizeImage.heightSize > 0 && (
									<>
										<Stage
											id="idCanvas"
											ref={refCanvas}
											style={{
												cursor: isLoadingSam
													? "wait"
													: !isHand
													? `${createCursor(tool === "click" ? 22 : valueSize)}`
													: "move",
												width: sizeImage.widthSize,
											}}
											width={sizeImage.widthSize}
											height={sizeImage.heightSize}
											onMouseDown={handleMouseDown}
											onMousemove={handleMouseMove}
											onClick={handleClick}
											onMouseup={handleMouseUp}>
											{status === "loaded" &&
												sizeImage.widthSize > 0 &&
												sizeImage.heightSize > 0 && (
													<Layer>
														<Image
															image={loadedImage}
															width={sizeImage.widthSize}
															height={sizeImage.heightSize}
														/>
													</Layer>
												)}
											<Layer>
												{lines?.length > 0 &&
													lines?.map((line, i) => (
														<Line
															key={i}
															points={line?.points}
															stroke={`rgba(255, 0, 0, 1)`}
															strokeWidth={line?.valueSize}
															tension={0.5}
															fill="red"
															closed={line?.tool === "click" ? true : false}
															lineCap="round"
															lineJoin="round"
															globalCompositeOperation={
																line?.tool === "eraser"
																	? "destination-out"
																	: "source-over"
															}
														/>
													))}
											</Layer>
										</Stage>
									</>
								)}

							<Box
								position={"absolute"}
								top={isShowHd ? 0 : 20}
								onClick={() => {
									localStorage.setItem(KEY_CONTEXT.HD_CLICK, "true");
									setIsShowHd(false);
								}}
								left={0}
								zIndex={9}
								width={"100%"}
								height={"100%"}
								sx={{
									opacity: isShowHd ? 1 : 0,
									pointerEvents: isShowHd ? "auto" : "none",
									transition: "all 0.35s ease-in-out",
								}}
								display={"flex"}
								flexDirection={"column"}
								alignItems={"center"}
								bgcolor={"rgba(0, 0, 0, 0.4)"}
								justifyContent={"center"}>
								<Box id="treeLeaves">{Icons.Click("#ffffff")}</Box>
								<TextCus fontSize={"14px"} color={"#ffffff"}>
									Click on the area you want to modify
								</TextCus>
							</Box>
						</Box>
					</Box>

					<Box
						position={"absolute"}
						top={openModalInfo ? 0 : "20px"}
						left={openModalInfo ? 0 : "26%"}
						bgcolor={
							mode === "dark" ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.7)"
						}
						zIndex={99}
						display={"flex"}
						sx={{
							transition: "all 0.5s ease-in-out",
							pointerEvents: `${openModalInfo ? "auto" : "none"}`,
							opacity: openModalInfo ? 1 : 0,
							borderRadius: "12px",
						}}
						alignItems={"center"}
						justifyContent={"center"}
						width={openModalInfo ? "100%" : "0px !important"}
						height={openModalInfo ? "100%" : "0px !important"}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							gap={"20px"}
							position={"relative"}
							bgcolor={mode === "dark" ? "rgba(0,0,0,0.7)" : "white"}
							paddingBottom={"16px"}
							sx={{
								transition: "all 0.45s ease-in-out",
								opacity: openModalInfo ? 1 : 0,
								overflow: "hidden",
								borderRadius: "20px",
								boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.3)",
							}}
							width={openModalInfo ? "80%" : "0px !important"}
							minHeight={openModalInfo ? "40vh" : "0px !important"}
							maxHeight={"70%"}
							justifyContent={"center"}>
							<IconButton
								onClick={() => {
									setOpenModalInfo(false);
									localStorage.setItem(KEY_CONTEXT.HD_SAM, "true");
								}}
								sx={{
									position: "absolute",
									top: "5px",
									right: "10px",
									zIndex: 99,
								}}>
								{Icons.Close()}
							</IconButton>
							<video
								src={PreComp}
								autoPlay
								loop={true}
								style={{
									objectFit: "contain",
									maxWidth: "100%",
								}}
							/>
							<ButtonCus
								sx={{ width: "250px", height: "41px !important" }}
								title="I got it"
								onClick={() => {
									setOpenModalInfo(false);
									localStorage.setItem(KEY_CONTEXT.HD_SAM, "true");
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalPainting;
