/** @format */

import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { TextCus } from "components";
import { Icons } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import {
	useAnimateStore,
	useGenMeStore,
	useOutfitStore,
	useStyleHairStore,
	useUser,
} from "hooks";
import { ThemeOption } from "types";

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { name: nameHair } = useStyleHairStore();
	const { name: nameGenMe } = useGenMeStore();
	const { name: nameAnimate } = useAnimateStore();
	const { name } = useOutfitStore();
	const theme = useTheme() as ThemeOption;
	const { mode } = useUser();

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"68px"}
			borderBottom={`1px solid ${theme.colorSchemes[mode]?.bgHover}`}
			bgcolor={theme.colorSchemes[mode]?.bgMain}>
			<Grid
				container
				sx={{
					height: "100%",
					paddingX: {
						xs: "15px",
						xl: "40px",
					},
				}}
				width={"100%"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					gap={"20px"}
					justifyContent={"start"}>
					<Box
						component={"div"}
						onClick={() => navigate(APP_ROOT.DASHBOARD)}
						sx={{
							cursor: "pointer",
							display: "flex",
							alignItems: "center",
							gap: "20px",
							textDecoration: "none",
						}}>
						<Box
							width={"36px"}
							height={"36px"}
							borderRadius={"12px"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							bgcolor={mainColor.bgButton}>
							{Icons.Back(theme.colorSchemes[mode]?.textTitle)}
						</Box>
						<TextCus
							color={theme.colorSchemes[mode]?.textTitle}
							sx={{
								fontSize: {
									xs: fontSize.sm,
									md: fontSize.sm,
								},
							}}
							fontWeight={"700"}>
							YOUR GENERATION
						</TextCus>
					</Box>
					<Box
						display={"flex"}
						alignContent={"center"}
						gap={"20px"}
						position={"relative"}
						justifyContent={"center"}>
						<Box height={"36px"} width={"2px"} bgcolor={"#A3A3A3"} />
						<Box
							width={"fit-content"}
							component={"input"}
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}
							disabled={true}
							sx={{
								outline: "none",
								fontWeight: "inherit",
								backgroundColor: theme.colorSchemes[mode]?.bgHover,
								border: "none",
								borderRadius: "12px",
								padding: "0 35px 0 15px",
								cursor: "pointer",
								color: theme.colorSchemes[mode]?.textMain,
								transition: "all 0.25s ease-in-out",
								height: "36px",
								fontFamily: "Montserrat",
							}}
							type="text"
							value={
								location.pathname === APP_ROOT.HISTORY
									? name
									: location.pathname === APP_ROOT.HISTORY_STYLE_HAIR
									? nameHair
									: location.pathname === APP_ROOT.HISTORY_ANIMATE
									? nameAnimate
									: nameGenMe
							}
							spellCheck="false"
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					alignItems={"center"}
					justifyContent={"flex-end"}></Grid>
			</Grid>
		</Box>
	);
};

export default Header;
