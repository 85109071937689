import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Skeleton } from "@mui/material";
import { useModel, useStoreBodyShape } from "hooks";
import TitleSection from "pages/admin/dashboard/components/TitleSection";
import React, { useCallback, useState } from "react";
import { EModalBodyShape, TParams } from "types";
import {
	ButtonCus,
	ButtonSeeMore,
	ModalDetailBMI,
	ModalUpload,
	TextCus,
} from "components";
import { IDataBodyShape } from "types/outfit";
import { Add } from "@mui/icons-material";
import BodyShapeItem from "./BodyShapeItem";
import { illustration } from "assets";
import { mainColor } from "theme";

const YourBodyShape = () => {
	const [open, setOpen] = useState(false);
	const { useGetYourModels, deleteModelMutation } = useModel();
	const { setDataResult, dataResult, setShowModelCreate } = useStoreBodyShape();
	const [openModalDetail, setOpenModalDetail] = useState(false);

	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 6,
		type: "BODY_SHAPE",
	});

	const {
		models: dataModel,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		refetch,
	} = useGetYourModels(paramsModels);

	const handleDelete = useCallback((id) => {
		deleteModelMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<Box
					width={"100%"}
					display={"flex"}
					gap={"10px"}
					mt={"20px"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}>
					{[...Array(3)].map((_, index) => {
						return (
							<Box
								display={"flex"}
								height={"159px"}
								width="calc((100% - 40px) / 3)"
								gap={"10px"}
								flexDirection={"column"}
								key={index}>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "12px",
										height: "200px",
										width: "100%",
									}}
								/>
							</Box>
						);
					})}
				</Box>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"200px"}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}
					flexDirection={"column"}>
					<LazyLoadImage src={illustration} alt="empty" />
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						flexDirection={"column"}>
						<TextCus color={mainColor.textMain}>
							Start by creating your own body shape
						</TextCus>
					</Box>
				</Box>
			);
		}
	}, [isLoading]);

	const renderItems = useCallback(() => {
		return dataModel.map((item) => (
			<Box width={"calc((100% - 40px) / 3)"} key={item.id}>
				<BodyShapeItem
					actionDelete={handleDelete}
					isLoading={deleteModelMutation.isPending}
					handleClick={() => {
						setDataResult(item as IDataBodyShape);
						setOpenModalDetail(true);
					}}
					item={item}
				/>
			</Box>
		));
	}, [dataModel]);

	return (
		<Box maxWidth={"1156px"} margin={"0 auto"} width={"100%"} mt={"50px"}>
			<Box display={"flex"} alignItems={"center"}>
				<TitleSection title="Your Body Shape" />
				<ButtonCus
					sx={{ width: "fit-content", gap: "5px" }}
					onClick={() => setShowModelCreate(EModalBodyShape.CREATE)}>
					<Add />
					<TextCus fontSize={"14px"} width={"50px"} fontWeight={"600"}>
						Create
					</TextCus>
				</ButtonCus>
			</Box>
			{!dataModel?.length || isLoading ? (
				renderDataLoading()
			) : (
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={""}
					flexWrap={"wrap"}
					gap={"20px"}
					mt={"20px"}>
					{renderItems()}
				</Box>
			)}
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
			{open && (
				<ModalUpload
					open={open}
					onClose={() => setOpen(false)}
					handleRefetch={refetch}
				/>
			)}
			{openModalDetail && (
				<ModalDetailBMI
					onClose={() => setOpenModalDetail(false)}
					dataResult={dataResult}
					open={openModalDetail}
				/>
			)}
		</Box>
	);
};

export default YourBodyShape;
