/** @format */

import { Box, Fade, IconButton, Modal } from "@mui/material";
import React from "react";
import styles from "./styles";
import { bgAuth } from "assets";
import { CloseOutlined } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useUser } from "hooks";

interface IAuthCus {
	children: React.ReactNode;
	onclose: () => void;
	isOpen: boolean;
}

export default function AuthCus({ children, onclose, isOpen }: IAuthCus) {
	const { mode } = useUser();

	return (
		<Modal
			open={isOpen}
			closeAfterTransition
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onclose}>
			<Fade in={isOpen} timeout={500}>
				<Box
					width={1110}
					maxWidth={"80%"}
					height={675}
					maxHeight={"90%"}
					position={"relative"}
					sx={{
						backgroundColor: mode === "dark" ? "#131313" : "#FFFFFF",
						...styles.boxContainer,
					}}>
					<IconButton
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							width: "30px",
							height: "30px",
						}}
						onClick={onclose}>
						<CloseOutlined />
					</IconButton>
					{children}
					<Box
						sx={{
							display: {
								xs: "none",
								md: "contents",
								lg: "block",
							},
						}}
						width={"50%"}>
						<LazyLoadImage src={bgAuth} alt="auth" />
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
}
