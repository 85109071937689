import { Box, useTheme } from "@mui/material";
import { ButtonDelete, TextCus } from "components";
import { useUser } from "hooks";
import React from "react";
import { fontSize } from "theme";
import { ThemeOption } from "types";
import { IModelType } from "types/outfit";
import { renderStatusBodyShape } from "utils/helpers/data_helpers";

interface IBodyShapeItem {
	handleClick?: () => void;
	item: IModelType;
	selectedImage?: string | undefined;
	actionDelete: (id: string) => void;
	isLoading: boolean;
}

const BodyShapeItem = ({
	handleClick,
	item,
	actionDelete,
	isLoading,
}: IBodyShapeItem) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<Box
			sx={{
				cursor: "pointer",
				":hover": {
					".btn_delete": {
						opacity: 1,
						pointerEvents: "unset",
					},
				},
			}}
			border={"1px solid #B2B2B2"}
			borderRadius={"12px"}
			overflow={"hidden"}
			component={"div"}
			onClick={handleClick}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"space-between"}
			width={"100%"}
			height={"159px"}>
			<Box
				display={"flex"}
				padding={"15px"}
				flex={1}
				gap={"15px"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}>
				<Box
					display={"flex"}
					width={"100%"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<Box
						bgcolor={"#E8E7FE"}
						fontSize={fontSize.xs}
						borderRadius={"12px"}
						width={"94px"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						height={"41px"}
						fontWeight={"600"}
						color={"#3B3899"}>
						BMI: {item?.bmi + ""}
					</Box>
					<TextCus
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"600"}
						fontSize={fontSize.xs}>
						{renderStatusBodyShape(item?.bmi || 0)?.title}
					</TextCus>
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					width={"100%"}
					justifyContent={"space-between"}>
					<TextCus
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"400"}
						fontSize={fontSize.xs}>
						Height
					</TextCus>
					<TextCus
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"600"}
						fontSize={fontSize.xs}>
						{item?.height + ""}
					</TextCus>
				</Box>
				<Box
					display={"flex"}
					width={"100%"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<TextCus
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"400"}
						fontSize={fontSize.xs}>
						Weight
					</TextCus>
					<TextCus
						color={theme.colorSchemes[mode]?.textMain}
						fontWeight={"600"}
						fontSize={fontSize.xs}>
						{item?.weight + ""}
					</TextCus>
				</Box>
			</Box>
			<Box width={"120px"} height={"100%"} position={"relative"}>
				<img
					style={{
						maxWidth: "100%",
						width: "100%",
						height: "100%",
						display: "block",
					}}
					src={item.image}
					alt="img"
				/>
				<Box
					className="btn_delete"
					sx={{
						opacity: 0,
						pointerEvents: "none",
						transition: "all 0.25s ease-in-out",
					}}>
					<ButtonDelete
						actionDelete={() => actionDelete(item?.id + "")}
						isLoading={isLoading}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(BodyShapeItem, (prevProps, nextProp) => {
	return (
		prevProps.item.id === nextProp.item.id &&
		prevProps.isLoading === nextProp.isLoading
	);
});
