import { Box, CircularProgress } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import React from "react";

const ButtonSeeMore = ({
	isFetchingNextPage,
	fetchNextPage,
}: {
	isFetchingNextPage: boolean;
	fetchNextPage: () => void;
}) => {
	const { mode } = useUser();

	return (
		<Box
			component={"div"}
			onClick={fetchNextPage}
			width={"fit-content"}
			sx={{ cursor: "pointer" }}
			margin={"15px auto"}
			padding={"4px 10px"}
			borderRadius={"12px"}
			bgcolor={mode === "dark" ? "#666666" : "rgba(149, 119, 217, 0.2)"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={"15px"}>
			<TextCus color={mode === "dark" ? "#B2B2B2" : "#3B3899"}>
				See more
			</TextCus>
			{isFetchingNextPage ? (
				<CircularProgress size={15} />
			) : (
				Icons.ArrowSelect(mode === "light" ? "#3B3899" : "#B2B2B2")
			)}
		</Box>
	);
};

export default ButtonSeeMore;
