/** @format */

import { IColorDefault, IFontSize } from "types/theme";

export const defaultColorTheme: IColorDefault = {
	primary: "#1076BB",
	secondary: "#21303f",
	warning: "#FFCC00",
	success: "#3CD4A0",
	info: "#9013FE",
	error: "#750606",
	grey: "#ECECEC",
	mainBlack: "#000000",
	textMain: "#808080",
	textPurple: "#21065D",
	textD8: "#D8D8D8",
	white: "#FFFFFF",
};

export const mainColor: IColorDefault = {
	primary: "#F2F2F7",
	secondary: "#3E227C",
	warning: "#FFCC00",
	success: "#3CD4A0",
	info: "#9013FE",
	error: "#750606",
	grey: "#F5F5F5",
	mainBlack: "#000000",
	white: "#FFFFFF",
	textMain: "#131313",
	textGray: "#666666",
	borderColor: "#ADADAD",
	bgButton: "rgba(149, 119, 217, 0.2)",
	textSecondary: "#5553BA",
	bgLinear: "linear-gradient(90deg, #6ddbfe, #fe55f4)",
	bgLinear_2:
		"linear-gradient(90deg, rgba(109, 219, 254, 0.7),rgba(254, 85, 244, 0.7))",
	bgLinear_3:
		"linear-gradient(90deg, rgba(109, 219, 254, 0.2),rgba(254, 85, 244, 0.2))",
};

export const fontSize: IFontSize = {
	xs: "14px",
	sm: "16px",
	md: "20px",
	xl: "25px",
};
