import { Box } from "@mui/material";
import React from "react";
import { fontSize } from "theme";

interface IInputSize {
	placeholder: string;
	value: string;
	onChange: (v: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputSize = ({ placeholder, value, onChange }: IInputSize) => {
	return (
		<Box
			width={"100%"}
			border={"1px solid #B2B2B2"}
			borderRadius={"12px"}
			height={"33px"}
			padding={"1px 0 1px 10px"}
			display={"flex"}>
			<Box
				flex={1}
				borderRadius={"12px 0 0 12px"}
				component={"input"}
				padding={"5px"}
				fontWeight={"600"}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				sx={{
					"::placeholder": {
						color: "#A3A3A3",
						fontSize: "12px",
						fontWeight: 500,
						fontFamily: "Montserrat",
					},
				}}
				max={125}
				min={0}
				type="number"
				border={"none"}
			/>
			<Box
				width="77px"
				borderLeft={"1px solid #B2B2B2"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				borderRadius={"0 12px 12px 0"}
				bgcolor={"white"}
				color={"#A3A3A3"}
				fontSize={fontSize.xs}
				fontWeight={"400"}>
				cm
			</Box>
		</Box>
	);
};

export default InputSize;
