import React, { useState } from "react";
import { Box } from "@mui/material";
import { useModel, useOutfitStore } from "hooks";
import { TParams } from "types";
import { EDropType, IModelType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { ButtonSeeMore, SelectCus } from "components";
import { arrGender } from "utils";

export const ModelBody: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		setCurrentStep,
		setLinesRetouch,
		setFileBinary,
	} = useOutfitStore();
	const { useGetModels } = useModel();

	// ======================== STATE ========================
	const [paramsModels, setParamsModels] = useState<TParams>({
		page: 1,
		limit: 3,
		typeModel: "FEMALE",
	});

	const {
		models: dataModel,
		isLoading: isLoadingFemale,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useGetModels(paramsModels);

	const handleChange = (event) => {
		setParamsModels({
			...paramsModels,
			typeModel: event.target.value,
		});
	};

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Preset Model" />
			<SelectCus
				width="120px"
				onChange={handleChange}
				options={arrGender}
				value={paramsModels?.typeModel + ""}
			/>
			<ListImage
				isLoading={isLoadingFemale}
				images={dataModel as IModelType[] | undefined}
				selectedImage={selectedFileModel as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(it.id as string);
					setModelImage(it.image as string);
					setSelectedFileGallery(null);
					setCurrentStep(1);
					setLinesRetouch([]);
					setFileBinary(null);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
