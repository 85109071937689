import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Paper } from "@mui/material";
import React, { useState } from "react";
import { IResRecommend } from "types/merchant";

const BoxSlider = ({ showResult }: { showResult: IResRecommend }) => {
	const [currentImage, setCurrentImage] = useState(0);

	const handleNext = () => {
		showResult?.imgProducts &&
			setCurrentImage((prev) => (prev + 1) % showResult?.imgProducts?.length);
	};

	const handlePrev = () => {
		showResult?.imgProducts &&
			setCurrentImage(
				(prev) =>
					(prev - 1 + showResult?.imgProducts?.length) %
					showResult?.imgProducts?.length,
			);
	};

	return (
		<Box
			maxHeight={"50vh"}
			width={"50%"}
			display={"flex"}
			alignItems={"start"}
			gap={"15px"}
			justifyContent={"flex-end"}
			height={"50vh"}>
			<Box position="relative" height={"100%"}>
				<Paper
					component="img"
					src={showResult?.imgProducts?.[currentImage]}
					alt="Current"
					sx={{
						width: "auto",
						height: "100%",
						borderRadius: "12px",
					}}
				/>
				<IconButton
					onClick={handlePrev}
					sx={{
						position: "absolute",
						right: 55,
						bottom: "10px",
						color: "white",
					}}>
					<ArrowBackIos />
				</IconButton>
				<IconButton
					onClick={handleNext}
					sx={{
						position: "absolute",
						right: 10,
						bottom: "10px",
						color: "white",
					}}>
					<ArrowForwardIos />
				</IconButton>
			</Box>

			<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
				{showResult?.imgProducts?.map((img, index) => (
					<Paper
						key={index}
						component="img"
						src={img}
						alt={`Thumbnail ${index + 1}`}
						onClick={() => setCurrentImage(index)}
						sx={{
							width: 80,
							cursor: "pointer",
							border: currentImage === index ? "2px solid #785BBA" : "none",
							borderRadius: "12px",
						}}
					/>
				))}
			</Box>
		</Box>
	);
};

export default BoxSlider;
