import { Box } from "@mui/material";
import { TextCus } from "components";
import React from "react";
import { fontSize, mainColor } from "theme";

interface IBoxBMI {
	title: string;
	desc: string;
	bgcolor: string;
	bgActive: string;
	isActive?: boolean;
}

const BoxBMI = ({ bgActive, bgcolor, desc, title }: IBoxBMI) => {
	return (
		<Box
			key={bgcolor}
			display={"flex"}
			flexDirection={"column"}
			flex={1}
			alignItems={"center"}>
			<Box
				height={"16px"}
				bgcolor={bgcolor}
				width={"100%"}
				borderRadius={"12px 12px 0 0"}
			/>
			<Box
				bgcolor={bgActive}
				sx={{ transition: "all 0.35s ease-in-out" }}
				borderRadius={"0 0 12px 12px"}
				paddingLeft={"25px"}
				width={"100%"}
				height={"100%"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}>
				<TextCus
					color={mainColor.textMain}
					fontSize={fontSize.xs}
					fontWeight={"600"}>
					{title}
				</TextCus>
				<TextCus
					color={mainColor.textMain}
					fontSize={"12px"}
					fontWeight={"400"}>
					{desc}
				</TextCus>
			</Box>
		</Box>
	);
};

export default BoxBMI;
