import { Box, CircularProgress, useTheme } from "@mui/material";
import { iconHair, iconMe, Icons } from "assets";
import { ButtonCus, DownloadDropdown, TextCus } from "components";
import React, { useEffect, useRef, useState } from "react";
import { fontSize, mainColor } from "theme";
import BoxLoading from "./BoxLoading";
import { IFinalOutfitResult } from "types/outfit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useClickOutside from "hooks/useClickOutside";
import { useDownloadImage, useOutfitStore, useUser } from "hooks";
import ImageToCanvas from "components/Common/ImageToCanvas";
import { ThemeOption } from "types";

interface ProcessResultProps {
	initialImage?: string;
	isLoading?: boolean;
	valueProcess?: number;
	isStart?: boolean;
	handleSwitchItem: () => void;
	processing?: IFinalOutfitResult | null | string;
	imgStyle?: string;
	isLoadingHistory?: boolean;
	handleNavigateStyleHair: () => void;
	handleNavigateGenMe: () => void;
	imgDownload?: string;
	galleryId: string;
	packageName: string;
}
const BoxResult = ({
	initialImage,
	isLoading,
	valueProcess,
	isStart,
	handleSwitchItem,
	imgStyle,
	isLoadingHistory,
	handleNavigateStyleHair,
	handleNavigateGenMe,
	imgDownload,
	galleryId,
	packageName,
}: ProcessResultProps) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const { user, mode } = useUser();
	const theme = useTheme() as ThemeOption;
	const { name } = useOutfitStore();
	const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();

	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	console.log("imgDownload====>", imgDownload);

	return (
		<Box
			bgcolor={theme.colorSchemes[mode]?.bgPhoto}
			width={"100%"}
			sx={{
				height: { xs: "100%", md: "95%" },
			}}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
			padding={"15px"}
			position={"relative"}
			borderRadius={"12px"}>
			<Box
				bgcolor={theme.colorSchemes[mode]?.bgPhoto}
				width={"100%"}
				zIndex={9999}
				height={"100%"}
				position={"absolute"}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				padding={"15px"}
				borderRadius={"12px"}>
				<TextCus
					fontWeight={"600"}
					fontSize={"14px"}
					marginBottom={"20px"}
					color={theme.colorSchemes[mode]?.textTitle}>
					Style Fit
				</TextCus>

				<Box
					height="calc(100% - 100px)"
					width={"100%"}
					flex={1}
					borderRadius={"12px"}
					bgcolor={theme.colorSchemes[mode]?.bgProcess}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					position={"relative"}
					gap={"30px"}>
					{previewUrl ? (
						<Box
							flex={1}
							display={"flex"}
							alignItems={"center"}
							flexDirection={"column"}
							justifyContent={"space-around"}
							maxWidth={"100%"}
							height={"100%"}>
							<Box
								margin={"0 auto"}
								width={"100%"}
								overflow={"hidden"}
								height={"calc(100% - 100px)"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								{/* <img
									src={previewUrl + ""}
									alt="Preview"
									style={{
										display: "block",
										margin: "0 auto",
										height: "100%",
										opacity: isPreviewVisible ? 1 : 0,
										transition: "opacity 0.5s ease-in-out",
										borderRadius: "10px",
										objectFit: "contain",
									}}
								/> */}
								<ImageToCanvas
									isPreviewVisible={isPreviewVisible}
									link={previewUrl}
								/>
							</Box>

							{isLoading && (
								<Box
									position={"absolute"}
									top={0}
									left={0}
									zIndex={9}
									gap={"15px"}
									borderRadius={"12px"}
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
									width={"100%"}
									height={"100%"}
									bgcolor={mode === "light" ? "#e9e0e085" : "#1c191985"}>
									<BoxLoading
										valueProcess={valueProcess || 0}
										isStart={isStart}
										isLoadingHistory={isLoadingHistory}
									/>
								</Box>
							)}
							<Box
								display={"flex"}
								alignItems={"center"}
								width={"100%"}
								gap={"15px"}
								padding={"0 15px"}
								justifyContent={"center"}>
								<ButtonCus
									onClick={handleNavigateGenMe}
									sx={{ padding: "0px !important", flex: 1 }}>
									<Box
										bgcolor={"white"}
										width={"98%"}
										borderRadius={"10px"}
										height={"90%"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											gap={"15px"}
											className="text_linear"
											fontSize={fontSize.xs}
											fontWeight={"600"}>
											<img src={iconMe} alt="me" />
											Gen Me
										</Box>
									</Box>
								</ButtonCus>
								<ButtonCus
									disabled
									onClick={handleNavigateStyleHair}
									sx={{ padding: "0px !important", flex: 1 }}>
									<Box
										bgcolor={"white"}
										width={"98%"}
										borderRadius={"10px"}
										height={"90%"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											gap={"15px"}
											className="text_linear"
											fontSize={fontSize.xs}
											fontWeight={"600"}>
											<img src={iconHair} alt="me" />
											Style Hair
										</Box>
									</Box>
								</ButtonCus>
								<ButtonCus
									sx={{
										padding: "0 5px !important",
										display: "flex !important",
										alignItems: "center !important",
										flex: 1,
										background:
											"linear-gradient(90deg, rgba(254, 85, 244, 0.8), rgba(109, 219, 254, 0.8)) !important",
										gap: "5px",
									}}
									onClick={handleSwitchItem}>
									{Icons.Res()}
									<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
										Switch Item
									</TextCus>
								</ButtonCus>
							</Box>

							{user?.plan !== "FREE" ? (
								<Box
									position={"absolute"}
									top={"10px"}
									right={"10px"}
									onClick={() =>
										handleDownLoadImg(
											imgDownload,
											`${name || "name"}.png`,
											galleryId,
											packageName,
										)
									}
									bgcolor={mainColor.white}
									height={"36px"}
									width={"36px"}
									borderRadius={"10px"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									sx={{
										cursor: "pointer",
									}}>
									{isLoadingDlImg ? (
										<CircularProgress
											sx={{
												height: "30px !important",
												width: "30px !important",
											}}
										/>
									) : (
										<Icons.Download />
									)}
								</Box>
							) : (
								<DownloadDropdown
									dropdownRef={dropdownRef}
									imgDownload={imgDownload + ""}
									isOpen={isOpen}
									name={`${name}.png`}
									setIsOpen={setIsOpen}
									galleryId={galleryId}
									packageName={packageName}
								/>
							)}
						</Box>
					) : (
						<Box
							width={"100%"}
							flex={1}
							borderRadius={"12px"}
							bgcolor={theme.colorSchemes[mode]?.bgProcess}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							gap={"30px"}>
							{!isLoading ? (
								<>
									{!imgStyle ? (
										Icons.Sparkers_2()
									) : (
										<LazyLoadImage
											src={imgStyle}
											style={{ display: "block" }}
											alt="Upload"
										/>
									)}
									<TextCus
										fontWeight={"600"}
										fontSize={"14px"}
										color={theme.colorSchemes[mode]?.textTitle}>
										Your result will be shown here
									</TextCus>
								</>
							) : (
								<BoxLoading
									valueProcess={valueProcess || 0}
									isLoadingHistory={isLoadingHistory}
									isStart={isStart}
								/>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default BoxResult;
