import React, { memo, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useClothes, useGenMeStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import { ButtonSeeMore } from "components";
import Heading from "pages/admin/stylefit/components/Heading";

const PresetFeatures: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileGenMe,
		setImageMe,
		selectedFileGenMe,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetClothes } = useClothes();

	// ======================== STATE ========================
	const [paramsOutfit, setParamsOutfit] = useState<TParams>({
		page: 1,
		limit: 3,
	});
	const {
		clothes: dataOutfit,
		isLoading,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useGetClothes(paramsOutfit);

	useEffect(() => {
		setParamsOutfit({
			...paramsOutfit,
		});
	}, []);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Preset Face" />
			<ListImage
				isLoading={isLoading}
				images={dataOutfit}
				selectedImage={selectedFileGenMe as string}
				setSelectedImage={(it) => {
					setSelectedFileGenMe(it.id as string);
					setImageMe(it.image as string);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};

export default memo(PresetFeatures);
