import { Box, Skeleton } from "@mui/material";
import { useGenMe, useGenMeStore } from "hooks";
import TitleSection from "pages/admin/dashboard/components/TitleSection";
import React, { useCallback, useState } from "react";
import { TParams } from "types";
import ImageItem from "./ImageItem";
import {
	ButtonCus,
	ButtonSeeMore,
	ModalRequirements,
	ModalUploadGenMe,
	TextCus,
} from "components";
import { IModelType } from "types/outfit";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { Upload } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { illustration } from "assets";
import { mainColor } from "theme";
import { useInView } from "react-intersection-observer";
import { arrNotShould, arrShould, KEY_CONTEXT } from "utils";

const YourGenMe = () => {
	const [open, setOpen] = useState(false);
	const [openModalRequire, setOpenModalRequire] = useState(false);
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	const { useGetGenMe, deleteGenMeMutation } = useGenMe(inView);
	const navigate = useNavigate();
	const { setSelectedFileGenMe, setImageMe, setCurrentStep } = useGenMeStore();

	const [paramsHairs] = useState<TParams>({
		page: 1,
		limit: 12,
	});
	const {
		genMeData,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		refetch,
	} = useGetGenMe(paramsHairs);

	const handleDelete = useCallback((id) => {
		deleteGenMeMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileGenMe(item.id as string);
		setImageMe(item.image as string);
		setCurrentStep(1);
		navigate(APP_ROOT.GEN_ME);
	}, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<Box
					width={"100%"}
					display={"flex"}
					gap={"10px"}
					mt={"20px"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}>
					{[...Array(6)].map((_, index) => {
						return (
							<Box
								display={"flex"}
								height={"198px"}
								width="calc((100% - 50px) / 6)"
								flexDirection={"column"}
								key={index}>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "12px",
										height: "198px",
										width: "100%",
									}}
								/>
							</Box>
						);
					})}
				</Box>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"200px"}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}
					flexDirection={"column"}>
					<LazyLoadImage src={illustration} alt="empty" />
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						flexDirection={"column"}>
						<TextCus color={mainColor.textMain}>
							Start by creating your own body shape
						</TextCus>
					</Box>
				</Box>
			);
		}
	}, [isLoading]);

	return (
		<Box
			ref={ref}
			maxWidth={"1156px"}
			margin={"0 auto"}
			width={"100%"}
			mt={"50px"}>
			<Box display={"flex"} alignItems={"center"}>
				<TitleSection title="Your Gen Me" />
				<ButtonCus
					sx={{ gap: "5px" }}
					onClick={() => {
						localStorage.getItem(
							KEY_CONTEXT.MODAL_REQUIRE + 3 + location.pathname,
						)
							? setOpen(true)
							: setOpenModalRequire(true);
					}}>
					<Upload />
					<TextCus fontSize={"14px"} width={"60px"} fontWeight={"600"}>
						Upload
					</TextCus>
				</ButtonCus>
			</Box>

			{!genMeData?.length || isLoading ? (
				renderDataLoading()
			) : (
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{ transition: "all 0.25s ease-in-out" }}
					flexWrap={"wrap"}
					rowGap={"30px"}
					mt={"20px"}>
					{genMeData.map((item) => (
						<Box width={"calc(100% / 6)"} key={item.id}>
							<ImageItem
								item={item}
								selectedImage=""
								actionDelete={handleDelete}
								handleClick={handleChooseModel}
								isLoading={deleteGenMeMutation.isPending}
							/>
						</Box>
					))}
				</Box>
			)}
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
			{open && (
				<ModalUploadGenMe
					open={open}
					onClose={() => setOpen(false)}
					handleRefetch={refetch}
				/>
			)}

			{openModalRequire && (
				<ModalRequirements
					currentStep={3}
					open={openModalRequire}
					onClose={() => setOpenModalRequire(false)}
					handleClick={() => {
						setOpen(true);
						setOpenModalRequire(false);
					}}
					arrContentShould={arrShould}
					arrContentNotShould={arrNotShould}
				/>
			)}
		</Box>
	);
};

export default YourGenMe;
