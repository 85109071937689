import { Box } from "@mui/material";
import { recommend } from "assets";
import React from "react";

interface IBoxSize {
	item?: string;
	size?: string;
}

const BoxSize = ({ item, size }: IBoxSize) => {
	return (
		<Box
			width={"40px"}
			height={"40px"}
			bgcolor={
				item === size ? "rgba(149, 119, 217, 0.5)" : "rgba(149, 119, 217, 0.2)"
			}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			borderRadius={"8px"}
			fontSize={"14px"}
			border={
				item === size
					? "1px solid #785BBA"
					: "1px solid rgba(149, 119, 217, 0.2)"
			}
			fontWeight={"600"}
			color={"#3B3899"}
			position={"relative"}>
			{item}
			{item === size && (
				<Box
					component={"img"}
					src={recommend}
					alt="Recommend"
					sx={{
						position: "absolute",
						top: "-35px",
						left: "50%",
						transform: "translateX(-50%)",
					}}
				/>
			)}
		</Box>
	);
};

export default BoxSize;
