import React, { useState } from "react";
import { Box } from "@mui/material";
import { useModel, useGenMeStore } from "hooks";
import { TParams, TypeYourBody } from "types";
import { EDropType, IModelType } from "types/outfit";
import ListImage from "./ListImage";
import { ButtonSeeMore, SelectCus } from "components";
import { arrTypeBody } from "utils";
import Heading from "pages/admin/stylefit/components/Heading";

export const YourModel: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetYourModels } = useModel();

	// ======================== STATE ========================
	const [paramsModels, setParamsModels] = useState<TParams>({
		page: 1,
		limit: 3,
		type: TypeYourBody.BODY_SHAPE,
	});

	const handleChange = (event) => {
		setParamsModels({
			...paramsModels,
			type: event.target.value,
		});
	};

	const {
		models: dataModel,
		isLoading: isLoadingFemale,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useGetYourModels(paramsModels);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Model" />
			<SelectCus
				width="130px"
				onChange={handleChange}
				options={arrTypeBody}
				value={paramsModels?.type + ""}
			/>
			<ListImage
				isLoading={isLoadingFemale}
				images={dataModel as IModelType[] | undefined}
				selectedImage={selectedFileModel as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(it.id as string);
					setModelImage(it.image as string);
					setSelectedFileGallery(null);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
