import { Box, Skeleton } from "@mui/material";
import React, { useCallback, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLoadImage, useMerchant, useOutfitStore } from "hooks";
import { TParams } from "types";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { TextCus } from "components";
import { ICloth } from "types/merchant";
import { Icons } from "assets";
import { mainColor } from "theme";
import { RuleFolder } from "@mui/icons-material";

const Collections = () => {
	const navigate = useNavigate();
	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();
	const { useGetClothByType } = useMerchant();
	const [paramsModels, setParamsModels] = useState<TParams>({
		page: 1,
		limit: 12,
		typeModel: "FEMALE",
	});
	const { clothes, isLoading } = useGetClothByType(paramsModels);

	const {
		setSelectedFileClothes,
		setImageClothes,
		setCurrentStep,
		setMerchantId,
		setTypeModel,
	} = useOutfitStore();

	const handleChooseItems = useCallback((item: ICloth) => {
		setSelectedFileClothes(item.id as string);
		setImageClothes(item.image as string);
		setMerchantId(item.merchantId as string);
		setTypeModel(item.typeModel as string);
		setCurrentStep(0);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<>
					<Box
						width={"100%"}
						gap={"20px"}
						rowGap={"30px"}
						display={"flex"}
						flexWrap={"wrap"}
						alignItems={"center"}>
						{[...Array(12)].map((_, index) => {
							return (
								<Skeleton
									key={index}
									variant="rectangular"
									sx={{
										transform: "unset",
										width: "calc((100% - 80px) / 6)",
										borderRadius: "12px",
										aspectRatio: "169 / 215",
										height: "auto",
									}}
								/>
							);
						})}
					</Box>
				</>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"190px"}
					component={"div"}
					textAlign={"center"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					color={"white"}>
					<RuleFolder
						style={{
							width: "60px",
							height: "60px",
							color: "#ccc",
						}}
					/>
					<TextCus color={mainColor.textGray} fontStyle={"italic"}>
						This item currently has no data.
					</TextCus>
				</Box>
			);
		}
	}, [isLoading]);

	return (
		<>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}>
				<Box>
					<TextCus color={"#5553BA"} fontSize={"14px"} fontWeight={"600"}>
						Try on more Zara Collections, you’ll love them!
					</TextCus>
					<TextCus color={"#666666"}>
						Click try on now to see your look in these items
					</TextCus>
				</Box>
				<Box
					component="div"
					sx={{ cursor: "pointer" }}
					onClick={() =>
						navigate(
							APP_ROOT.SHOP +
								`?typeModel=${paramsModels.typeModel}&merchantId=${clothes?.[0]?.merchantId}&categoryId=${clothes?.[0]?.categoryId}`,
						)
					}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}>
					<TextCus
						fontSize={"12px"}
						fontWeight={"500"}
						color={mainColor.textGray}>
						See all
					</TextCus>
					{Icons.Start("#666666")}
				</Box>
			</Box>
			<Box margin={"0 auto"} width={"100%"} mt={"30px"}>
				<Box
					display={"flex"}
					alignItems={"center"}
					gap={"10px"}
					mt={"15px"}
					mb={"20px"}>
					<Box
						padding={"5px 15px"}
						sx={{ cursor: "pointer", transition: "all 0.25s ease-in-out" }}
						bgcolor={
							paramsModels.typeModel === "FEMALE" ? "#B2B2B2" : "#EEEEEE"
						}
						borderRadius={"30px"}
						fontSize={"12px"}
						onClick={() =>
							setParamsModels({ ...paramsModels, typeModel: "FEMALE" })
						}
						fontWeight={"500"}
						color={paramsModels.typeModel === "FEMALE" ? "white" : "#303030"}>
						Woman
					</Box>
					<Box
						onClick={() =>
							setParamsModels({ ...paramsModels, typeModel: "MALE" })
						}
						padding={"5px 15px"}
						sx={{ cursor: "pointer", transition: "all 0.25s ease-in-out" }}
						bgcolor={paramsModels.typeModel === "MALE" ? "#B2B2B2" : "#EEEEEE"}
						borderRadius={"30px"}
						fontSize={"12px"}
						color={paramsModels.typeModel === "MALE" ? "white" : "#303030"}
						fontWeight={"500"}>
						Man
					</Box>
				</Box>
				<Box
					width={"100%"}
					gap={"20px"}
					rowGap={"30px"}
					display={"flex"}
					flexWrap={"wrap"}
					alignItems={"center"}>
					{!clothes?.length || isLoading ? (
						renderDataLoading()
					) : (
						<>
							{clothes.map((item, index) => {
								return (
									<Box
										component={"div"}
										width={"calc((100% - 100px) / 6)"}
										borderRadius={"12px"}
										onClick={() => handleChooseItems(item)}
										display={"flex"}
										alignItems={"start"}
										justifyContent={"center"}
										flexDirection={"column"}
										gap={"10px"}
										sx={{
											transition: "all 0.35s ease-in-out",
											cursor: "pointer",
											":hover": {
												".overlay_product": {
													top: 0,
													opacity: 1,
												},
											},
										}}
										key={`${item?.id || "" + index}`}>
										<Box
											width={"100%"}
											borderRadius={"12px"}
											position={"relative"}
											sx={{
												transition: "all 0.35s ease-in-out",
												overflow: "hidden",
												cursor: "pointer",
											}}>
											<LazyLoadImage
												className="img_model"
												style={{
													display: "block",
													objectFit: "cover",
													width: "100%",
													transition: "all 0.35s ease-in-out",
													borderRadius: "12px",
													aspectRatio: "155 / 198",
												}}
												src={
													error ? img404 : loading ? imgLoad : `${item?.image}`
												}
												onLoad={handleLoad}
												onError={handleError}
												alt={error ? "Error" : "model"}
											/>
											<Box
												className="overlay_product"
												display={"flex"}
												alignItems={"center"}
												justifyContent={"center"}
												position={"absolute"}
												sx={{ opacity: 0, transition: "all 0.25s ease-in-out" }}
												top={20}
												left={0}
												bgcolor={"rgba(0, 0, 0, 0.3)"}
												width={"100%"}
												height={"100%"}>
												<Box
													sx={{ backdropFilter: "blur(20px)" }}
													width={"50%"}
													height={"30px"}
													bgcolor={"rgba(255, 255, 255, 0.5)"}
													display={"flex"}
													alignItems={"center"}
													justifyContent={"center"}
													borderRadius={"12px"}>
													<TextCus
														color={"#ffffff"}
														fontSize={"12px"}
														fontWeight={"500"}>
														Try on
													</TextCus>
												</Box>
											</Box>
										</Box>
										<Box
											fontSize={"14px"}
											className="res_text"
											fontWeight={"400"}
											width={"100%"}>
											{item?.name}
										</Box>
									</Box>
								);
							})}
						</>
					)}
				</Box>
			</Box>
		</>
	);
};

export default Collections;
