/** @format */

import { EModalBodyShape } from "types";
import { TFormDataBody } from "types/animate";
import { IDataBodyShape } from "types/outfit";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	showModalCreate: EModalBodyShape;
	dataResult: IDataBodyShape;
	showModalSuggest: boolean;
	formData: TFormDataBody;
	isLoadingProcess: boolean;
};

type Actions = {
	setShowModelCreate: (v: EModalBodyShape) => void;
	setDataResult: (v: IDataBodyShape) => void;
	setShowModalSuggest: (v: boolean) => void;
	setFormDataBodyShape: (v: TFormDataBody) => void;
	setIsLoadingProcess: (v: boolean) => void;
	resetFormData: () => void;
};

const initialState: State = {
	showModalCreate: EModalBodyShape.DEFAULT,
	showModalSuggest: false,
	isLoadingProcess: false,
	dataResult: {
		bmi: 0,
		bodyShapeId: "",
		createdAt: "",
		height: 0,
		id: "",
		image: "",
		name: "",
		typeModel: "",
		uid: "",
		weight: 0,
		_id: "",
	},
	formData: {
		fileModel: null,
		fileGenMe: null,
		typeModel: "FEMALE",
		weight: 45,
		height: 120,
		headSize: 55,
		neckLength: 8,
		shoulders: 40,
		arms: 25,
		waistSize: 75,
		hipSize: 90,
		chestSize: 85,
		legLength: 40,
		legWidth: 90,
		bmi: 1,
	},
};

export const useStoreBodyShape = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setShowModelCreate: (v) => set({ showModalCreate: v }),
			setDataResult: (v) => set({ dataResult: v }),
			setShowModalSuggest: (v) => set({ showModalSuggest: v }),
			setFormDataBodyShape: (v) => set({ formData: v }),
			setIsLoadingProcess: (v) => set({ isLoadingProcess: v }),
			resetFormData: () => {
				set({ formData: initialState.formData });
			},
		}),
		{
			name: "bodyShapeStore",
		},
	),
);
