import { Box, useTheme } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import BoxLoading from "pages/admin/stylefit/components/BoxLoading";
import React from "react";
import { ThemeOption } from "types";

const ModalLoading = ({
	isLoading,
	valueProcess,
	isStart,
}: {
	isLoading: boolean;
	valueProcess: number;
	isStart: boolean;
}) => {
	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;
	return (
		<Box
			bgcolor={"rgba(0, 0, 0, 0.8)"}
			position={"absolute"}
			top={0}
			left={0}
			width={"100vw"}
			zIndex={99999}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			height={"100vh"}>
			<Box
				width={"448px"}
				height={"211px"}
				borderRadius={"12px"}
				bgcolor={theme.colorSchemes[mode]?.bgProcess}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
				gap={"30px"}>
				{!isLoading ? (
					<>
						{Icons.Sparkers_2()}
						<TextCus
							fontWeight={"600"}
							fontSize={"14px"}
							color={theme.colorSchemes[mode]?.textTitle}>
							Your result will be shown here
						</TextCus>
					</>
				) : (
					<BoxLoading valueProcess={valueProcess} isStart={isStart} />
				)}
			</Box>
		</Box>
	);
};

export default ModalLoading;
