/** @format */

import { Box, CircularProgress, Divider, useTheme } from "@mui/material";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import React, { useCallback, useState } from "react";
import { fontSize } from "theme";
import styles from "./styles";
import { InputOutLine } from "components/InputCus";
import { Icons, logo, logoDark } from "assets";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "utils";
import { EModelAuth, FormLogin, ThemeOption } from "types";
import { useAuthentication, useLayout, useUser } from "hooks";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const Login = () => {
	const { t } = useTranslation();
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormLogin>({
		resolver: yupResolver(loginSchema) as any,
		mode: "onChange",
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const { setOpenModalAuth } = useLayout();
	const [searchParams] = useSearchParams();
	const referralCode = searchParams.get("_referralCode");
	const refSrc = searchParams.get("refSrc");
	const { loginMutation, loginSocialMutation } = useAuthentication();

	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	const handleClick = (data: FormLogin) => {
		loginMutation.mutate(data);
	};

	const navigateRegister = useCallback(() => {
		setOpenModalAuth(EModelAuth.REGISTER);
	}, []);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleLoginGoogle = useGoogleLogin({
		onSuccess: ({ access_token }) => {
			try {
				referralCode && localStorage.setItem("referralCode", referralCode);
				refSrc && localStorage.setItem("refSrc", refSrc);
				loginSocialMutation.mutate({ idToken: access_token });
			} catch (error: unknown) {
				console.log("Login Social error:", error);
			}
		},
	});

	return (
		<Box sx={styles.boxMain} width={"100%"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				width={"90%"}
				px={"15px"}
				flexDirection={"column"}
				gap={"20px"}>
				<img src={mode === "dark" ? logoDark : logo} width={"100px"} />
			</Box>

			<Box mt={3} width={"90%"} px={"15px"}>
				<TextCus
					fontSize={"20px"}
					mb={2}
					fontWeight={"700"}
					color={theme.colorSchemes[mode]?.textMain}>
					Welcome back to StyleGen
				</TextCus>
				<ButtonCus
					LabelProps={{ variant: "bodyBold" }}
					useI18n
					sx={{
						width: "100%",
						background: "white !important",
						gap: "20px",
						border: "1px solid #333333 !important",
						height: "54px !important",
					}}
					variant={"text"}
					iconProps={{ variant: "google" }}
					onClick={() => handleLoginGoogle()}>
					<TextCus
						fontSize={fontSize.xs}
						fontWeight={"600"}
						color={"rgba(0, 0, 0, 0.54)"}>
						Sign in with Google
					</TextCus>
					{loginSocialMutation.isPending && (
						<CircularProgress size={"20px"} sx={{ ml: "10px" }} />
					)}
				</ButtonCus>
			</Box>

			<Box
				flex={0.5}
				width={"90%"}
				px={"15px"}
				component="form"
				onSubmit={handleSubmit(handleClick)}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					width={"100%"}>
					<Divider
						sx={{ flex: 1, bgcolor: theme.colorSchemes[mode]?.bgHover }}
					/>
					<TextCus
						color={theme.colorSchemes[mode]?.textGray}
						fontSize={fontSize.xs}
						fontWeight={"400"}
						padding={"20px"}>
						OR
					</TextCus>
					<Divider
						sx={{ flex: 1, bgcolor: theme.colorSchemes[mode]?.bgHover }}
					/>
				</Box>

				<Box>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<InputOutLine
								type="text"
								label="Email"
								{...field}
								value={field.value}
								setValue={field.onChange}
								fullWidth
								error={!!errors.email}
								errorMessage={errors?.email?.message}
							/>
						)}
					/>
				</Box>
				<Box mt={2}>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Password"
								type={showPassword ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.password}
								errorMessage={errors.password?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
					<Box display={"flex"} gap={"5px"} mt={1} justifyContent={"flex-end"}>
						<Box
							component={"div"}
							display={"flex"}
							onClick={() => setOpenModalAuth(EModelAuth.FORGOT)}
							sx={{ cursor: "pointer", textDecoration: "underline" }}>
							<TextCus color={"#FF75F3"} fontSize={"12px"} fontWeight={"400"}>
								Forgot password
							</TextCus>
							?
						</Box>
					</Box>

					<Box
						mt={5}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}>
						<ButtonCus
							title="Login"
							LabelProps={{ variant: "bodyBold" }}
							useI18n
							sx={{ width: "100%" }}
							variant={"text"}
							type="submit"
							isLoading={loginMutation.isPending}
						/>
					</Box>
					<Box
						display={"flex"}
						gap={"5px"}
						mt={"30px"}
						justifyContent={"center"}>
						<TextCus color={theme.colorSchemes[mode]?.textMain}>
							{"Don't have an account?"}
						</TextCus>{" "}
						<Box
							component={"div"}
							onClick={navigateRegister}
							sx={{ cursor: "pointer" }}
							className="text_linear"
							fontWeight={"bold"}>
							{t("Sign up")}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Login;
