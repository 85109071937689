import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useGenMe, useGenMeStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import { ButtonSeeMore } from "components";
import Heading from "pages/admin/stylefit/components/Heading";

export const YourFeatures: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileGenMe,
		setImageMe,
		selectedFileGenMe,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetGenMe } = useGenMe();

	// ======================== STATE ========================
	const [paramsGenMe, setParamsGenMe] = useState<TParams>({
		page: 1,
		limit: 3,
	});
	const {
		genMeData,
		isLoading,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetGenMe(paramsGenMe);

	useEffect(() => {
		setParamsGenMe({
			...paramsGenMe,
		});
	}, []);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Face" />
			<ListImage
				isLoading={isLoading}
				images={genMeData}
				selectedImage={selectedFileGenMe as string}
				setSelectedImage={(it) => {
					setSelectedFileGenMe(it.id as string);
					setImageMe(it.image as string);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			{hasNextPage && (
				<ButtonSeeMore
					fetchNextPage={fetchNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			)}
		</Box>
	);
};
